import { Grid } from "@mui/material";
import { HeadingTypography } from "../Styled";
import React, { FC } from "react";
import PropertyDetailItem from "../PropertyDetailItem";
import { ListItem, UnorderedList } from "./AmenitiesStyle";
import { Amenity } from "./types";

type AmenitiesProps = {
    heading: string;
    data: Amenity[];
};

const AmenitiesGrid: FC<AmenitiesProps> = ({ heading, data }) => (
    <Grid container spacing={1} mb={4}>
        <Grid item xs={12}>
            <HeadingTypography variant="h5" color="primary">
                {heading}
            </HeadingTypography>
        </Grid>

        <Grid item xs={12} md={6}>
            {data.map((amenity, index) => {
                return (
                    index % 2 === 0 && (
                        <Grid key={amenity.id} item xs={12} md={6}>
                            <PropertyDetailItem primaryText={amenity.title}>{amenity.icon}</PropertyDetailItem>
                            <UnorderedList>
                                {amenity.features.map((feature) => (
                                    <ListItem key={feature}>{feature}</ListItem>
                                ))}
                            </UnorderedList>
                        </Grid>
                    )
                );
            })}
        </Grid>

        <Grid item xs={12} md={6}>
            {data.map((amenity, index) => {
                return (
                    index % 2 === 1 && (
                        <Grid key={amenity.id} item xs={12} md={6}>
                            <PropertyDetailItem primaryText={amenity.title}>{amenity.icon}</PropertyDetailItem>
                            <UnorderedList>
                                {amenity.features.map((feature) => (
                                    <ListItem key={feature}>{feature}</ListItem>
                                ))}
                            </UnorderedList>
                        </Grid>
                    )
                );
            })}
        </Grid>
    </Grid>
);

export default AmenitiesGrid;
