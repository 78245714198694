import { Grid } from "@mui/material";
import React from "react";
import { HeadingText, InfoText } from "../../commoncomponents/CommonStyle";
import TbcCard from "../../commoncomponents/layout/card/TbcCard";

const BookingDetailsView = () => {
    return (
        <TbcCard title={"Your Booking Details"}>
            <HeadingText>Contact Details</HeadingText>
            <Grid container style={{ marginTop: 10 }}>
                <Grid item xs={2}>
                    <InfoText>Phone Number</InfoText>
                </Grid>
                <Grid item xs={2}>
                    <InfoText>1234123412</InfoText>
                </Grid>
            </Grid>
            <Grid container style={{ marginTop: 10 }}>
                <Grid item xs={2}>
                    <InfoText>Email</InfoText>
                </Grid>
                <Grid item xs={2}>
                    <InfoText>gaurav@gtxtechnologies.com</InfoText>
                </Grid>
            </Grid>
            <HeadingText style={{ marginTop: 10 }}>Passenger Details</HeadingText>
            <InfoText style={{ marginTop: 10 }}>Traveller and Adult(Lead)</InfoText>
        </TbcCard>
    );
};

export default BookingDetailsView;
