import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import React, { FC, ReactNode } from "react";
import { mainHeadingColor } from "../../../constants/ColorsConstants";
type PropertyDetailItem = {
    primaryText: string | ReactNode;
    secondaryText?: string | ReactNode;
};

const PropertyDetailItem: FC<PropertyDetailItem> = ({ children, primaryText, secondaryText }) => (
    <ListItem sx={{ py: 0 }}>
        <ListItemIcon sx={{ minWidth: "35px", color: mainHeadingColor }}>{children}</ListItemIcon>
        <ListItemText sx={{ color: mainHeadingColor }} primary={primaryText} secondary={secondaryText || ""} />
    </ListItem>
);

export default PropertyDetailItem;
