import React, { FC, ReactNode } from "react";
import { CardContainer } from "../../CommonStyle";
import { CardTitle } from "./styled";

type TbcCardProps = {
    title?: string | ReactNode;
};
const TbcCard: FC<TbcCardProps> = ({ title, children }) => (
    <CardContainer>
        {title && <CardTitle>{title}</CardTitle>}
        {children}
    </CardContainer>
);

export default TbcCard;
