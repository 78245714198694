import TbcCard from "../../../../commoncomponents/layout/card/TbcCard";
import React, { FC } from "react";
import { PropertyAmenities, RoomAmenities } from "./AmenitiesData";
import AmenitiesGrid from "./AmenitiesGrid";

const Amenities: FC = () => (
    <TbcCard>
        <AmenitiesGrid heading="Property amenities" data={PropertyAmenities} />
        <AmenitiesGrid heading="Room amenities" data={RoomAmenities} />
    </TbcCard>
);

export default Amenities;
