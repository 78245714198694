import { Grid } from "@mui/material";
import React, { FC } from "react";
import { InfoText } from "../../../commoncomponents/CommonStyle";
import { convertMinsToHrsMins } from "../../../util/SiteUtils";
import { FlightSearchResultsType } from "../results/Type";
import { getTotalTravelTimeInAmPm } from "../results/Utils";

type SelectedFlightDetailProps = {
    flightDetail: FlightSearchResultsType | null;
    flightName: string;
};
const SelectedFlightDetail: FC<SelectedFlightDetailProps> = ({ flightDetail, flightName }) => {
    if (!flightDetail) {
        return null;
    }

    const { duration, segments, stops } = flightDetail.itineraries[0];

    return (
        <Grid container spacing={1} style={{ width: "fit-content" }}>
            <Grid item xs={12}>
                <InfoText>Fri, Dec 10</InfoText>
            </Grid>
            <Grid item xs={12}>
                <InfoText style={{ fontSize: 20, fontWeight: "bold" }}>{getTotalTravelTimeInAmPm(segments)}</InfoText>
            </Grid>
            <Grid item xs={12} style={{ marginBottom: 10 }}>
                <InfoText>
                    {stops === 0 ? "Non stop" : `${stops} stop`} . {convertMinsToHrsMins(duration)} . {flightName}
                </InfoText>
            </Grid>
        </Grid>
    );
};

export default SelectedFlightDetail;
