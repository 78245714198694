import { Popover, Typography, Grid } from "@mui/material";
import React, { FC } from "react";
import { useBooleanState, useDataState } from "../../commoncomponents/CommonHooks";
import { PassengerAgeOptions } from "../../constants/SiteOptions";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import { OptionTextColor } from "../../commoncomponents/CommonStyle";
import { PassengersInfoType } from "./Type";
import { ADULTS } from "../../constants/SiteConstants";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

type SelectPassengerProps = {
    passengerInfo: PassengersInfoType;
    setPassenger?: (passengerType: string[]) => void;
    setFlightType: (flightType: string) => void;
};

const SelectPassenger: FC<SelectPassengerProps> = ({ passengerInfo, setPassenger }) => {
    const openPassengerPopover = useBooleanState(false);
    const anchorEl = useDataState<HTMLButtonElement | null>(null);

    const getPassengerCount = (fieldType: string) => {
        return passengerInfo.passengerType.filter((item) => item === fieldType).length;
    };

    const handlePassengerDecrementCount = (passengerType: string) => {
        if (passengerType === ADULTS && passengerInfo.passengerType.filter((item) => item === ADULTS).length > 1) {
            const withoutAdult = passengerInfo.passengerType.filter((item) => item !== ADULTS);
            const withAdult = passengerInfo.passengerType.filter((item) => item === ADULTS);
            withAdult.pop();
            setPassenger && setPassenger([...withoutAdult, ...withAdult]);
            return;
        }
        if (
            passengerType !== ADULTS &&
            passengerInfo.passengerType.filter((item) => item === passengerType).length > 0
        ) {
            const withoutSelectedItem = passengerInfo.passengerType.filter((item) => item !== passengerType);
            const withSelectedItem = passengerInfo.passengerType.filter((item) => item === passengerType);
            withSelectedItem.pop();
            setPassenger && setPassenger([...withoutSelectedItem, ...withSelectedItem]);
        }
    };

    const handlePassengerIncrementCount = (passengerType: string) => {
        setPassenger && setPassenger([...passengerInfo.passengerType, passengerType]);
    };

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        anchorEl.set(event.currentTarget);
        openPassengerPopover.toggle();
    };

    return (
        <div>
            <OptionTextColor onClick={handleClick}>
                {passengerInfo.passengerType.length} Travellers{" "}
                {!openPassengerPopover.value ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
            </OptionTextColor>
            <Popover
                open={openPassengerPopover.value}
                anchorEl={anchorEl.value}
                onClose={() => openPassengerPopover.setFalse()}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                PaperProps={{
                    style: {
                        padding: 20
                    }
                }}>
                {PassengerAgeOptions.map(({ title, value, age }) => (
                    <Grid style={{ marginBottom: 10 }} container key={value} justifyContent={"space-between"} gap={2}>
                        <Grid item>
                            <RemoveCircleOutlineOutlinedIcon
                                onClick={() => handlePassengerDecrementCount(value)}
                                style={{ color: "grey", fontSize: 25, cursor: "pointer" }}
                            />
                        </Grid>
                        <Grid item>
                            <Typography variant="body2">
                                {`${getPassengerCount(value)} ${title} (age ${age})`}{" "}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <AddCircleOutlineOutlinedIcon
                                onClick={() => handlePassengerIncrementCount(value)}
                                style={{ color: "grey", fontSize: 25, cursor: "pointer" }}
                            />
                        </Grid>
                    </Grid>
                ))}
            </Popover>
        </div>
    );
};

export default SelectPassenger;
