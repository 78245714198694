import { Grid } from "@mui/material";
import React, { FC, useCallback, useEffect, useState } from "react";
import { useBooleanState } from "../../../commoncomponents/CommonHooks";
import TbcCard from "../../../commoncomponents/layout/card/TbcCard";
import LoadingShimmerRows from "../../../commoncomponents/ShimmerRows";
import { revalidateFlight } from "../../flights/results/Utils";
import BookingPriceCard from "../bookingprice/BookingPriceCard";
import BookingFlightSummary from "../flightsummary/BookingFlightSummary";
// import CovidHealthDeclarationCard from "./CovidHealthDeclarationCard";
import { AllPassengerDetailsFormType, AllPassengerInfoType, PassengerCountType, PassengerDetailType } from "./Type";
import { bookTickets, getInitialPassengerDetail } from "./Util";
import AllPassengerForm from "./AllPassengerForm";
import ErrorDialog from "../../../commoncomponents/ErrorDialog";
import { useNavigate } from "react-router";

const PassengerInfoView: FC = () => {
    const navigate = useNavigate();
    const isLoading = useBooleanState(false);

    const [allPassengerDetailFormData, setAllPassengerDetailFormData] = useState<AllPassengerDetailsFormType[]>([]);
    const [flightDetails, setFlightDetails] = useState<AllPassengerInfoType[]>([]);
    const [totalNumberOfPassenger, setTotalNumberOfPassenger] = useState<number>(0);
    const [showErrorDialog, setShowErrorDialog] = useState({
        revalidateError: false,
        bookingError: false
    });
    const [totalBookingAmount, setTotalBookingAmount] = useState(0);

    const [passengerBookingAmountInfo, setPassengerBookingAmountInfo] = useState<PassengerCountType>(
        {} as PassengerCountType
    );
    const searchParams = new URLSearchParams(window.location.search);
    useEffect(() => {
        const outboundKey = searchParams.get("outboundKey");
        const inboundKey = searchParams.get("inboundKey");
        if (outboundKey === null) {
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            return () => {};
        }
        const flightKeys = [outboundKey];
        if (inboundKey !== null) {
            flightKeys.push(inboundKey);
        }
        isLoading.setTrue();
        revalidateFlight(flightKeys, !searchParams.get("singleTicket"), false)
            .then((res) => {
                setFlightDetails(res);
                const { passengerDetails, totalNumberOfPassenger, perPassengerAmountInfo, totalAmount } =
                    getInitialPassengerDetail(res);
                setPassengerBookingAmountInfo(perPassengerAmountInfo);
                setAllPassengerDetailFormData(passengerDetails);
                setTotalNumberOfPassenger(totalNumberOfPassenger);
                setTotalBookingAmount(totalAmount);
                isLoading.setFalse();
            })
            .catch(() => {
                isLoading.setFalse();
                setShowErrorDialog((prev) => ({
                    ...prev,
                    revalidateError: true
                }));
            });
    }, []);

    const bookTicket = useCallback(
        (details: PassengerDetailType[]) => {
            const searchParams = new URLSearchParams(window.location.search);
            const outboundKey = searchParams.get("outboundKey");
            const inboundKey = searchParams.get("inboundKey");
            if (outboundKey === null) {
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                return () => {};
            }
            const flightKeys = [outboundKey];
            if (inboundKey !== null) {
                flightKeys.push(inboundKey);
            }
            isLoading.setTrue();
            bookTickets(flightKeys, searchParams.get("singleTicket") === "true", details)
                .then(({ data }) => {
                    // eslint-disable-next-line no-console
                    console.log("data::", data);
                    isLoading.setFalse();
                    if (data.payment_link) {
                        window.location.href = data.payment_link;
                    }
                })
                .catch(() => {
                    isLoading.setFalse();
                    setShowErrorDialog((prev) => ({
                        ...prev,
                        bookingError: true
                    }));
                });
        },
        [location]
    );

    return (
        <>
            {/* <FlightStepper activeStep={2} /> */}
            <Grid container justifyContent="center">
                <Grid item xs={12} md={10}>
                    <Grid container>
                        <Grid item xs={12} md={8} alignItems="stretch">
                            {/* <Grid item style={{ margin: 10 }}>
                                    <CovidHealthDeclarationCard />
                                </Grid> */}
                            <LoadingShimmerRows isLoading={isLoading.value} noOfCards={4}>
                                {totalNumberOfPassenger !== 0 && (
                                    <AllPassengerForm
                                        initialFormValues={allPassengerDetailFormData}
                                        totalNumberOfForms={totalNumberOfPassenger}
                                        submitForm={(details) => bookTicket(details)}
                                    />
                                )}
                            </LoadingShimmerRows>
                        </Grid>
                        <Grid item xs={12} md={4} alignItems="stretch">
                            <Grid item style={{ margin: 10 }}>
                                <BookingPriceCard
                                    passengerCount={passengerBookingAmountInfo}
                                    passengerPrice={totalBookingAmount}
                                    key={totalBookingAmount.toString()}
                                />
                            </Grid>
                            {flightDetails.length > 0 && (
                                <Grid item style={{ margin: 10 }}>
                                    <TbcCard>
                                        <BookingFlightSummary
                                            itenariesCount={0}
                                            flightDetails={flightDetails[0]}
                                            title={"Departing"}
                                        />
                                    </TbcCard>
                                </Grid>
                            )}
                            {flightDetails.length > 0 && searchParams.get("oneway") === "false" && (
                                <Grid item style={{ margin: 10 }}>
                                    <TbcCard>
                                        <BookingFlightSummary
                                            itenariesCount={flightDetails.length > 1 ? 0 : 1}
                                            flightDetails={
                                                flightDetails.length > 1 ? flightDetails[1] : flightDetails[0]
                                            }
                                            title={"Returning"}
                                        />
                                    </TbcCard>
                                </Grid>
                            )}
                        </Grid>
                        {(showErrorDialog.bookingError || showErrorDialog.revalidateError) && (
                            <ErrorDialog
                                errorDetails={"Sorry, we are unable to process your request. Please try again later."}
                                handleClose={() => {
                                    showErrorDialog.revalidateError && navigate("/flights");
                                    setShowErrorDialog({
                                        revalidateError: false,
                                        bookingError: false
                                    });
                                }}
                                closeButtonText={showErrorDialog.revalidateError ? "Do another search" : "Close"}
                            />
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};
export default PassengerInfoView;
