import { Radio } from "@mui/material";
import React from "react";
import TbcCard from "../../commoncomponents/layout/card/TbcCard";
import { PaymentOptions } from "../../constants/SiteOptions";
import { IconButtons } from "./Style";

const PaymentMethod = () => (
    <TbcCard title={"Select your payment method"}>
        <div style={{ display: "flex", gap: 5 }}>
            {PaymentOptions.map((options) => (
                <IconButtons item key={options.id}>
                    <Radio checked={true} />
                    Coinbase commerce
                </IconButtons>
            ))}
        </div>
    </TbcCard>
);

export default PaymentMethod;
