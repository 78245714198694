import styled from "@emotion/styled";
import { Box } from "@mui/material";

export const CardImg = styled.img`
    max-width: 100%;
    height: auto;
    border-radius: 13px;
    min-height: 169px;
`;

export const MemberPrice = styled(Box)`
    background-color: #ffc94c;
    color: #141d38;
    border-radius: 30px;
    padding: 5px;
    width: 167px;
    font-size: 0.7rem;
    font-weight: bold;
    flex-wrap: wrap;
    align-items: center;
    display: flex;
`;
