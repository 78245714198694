import Styled from "@emotion/styled";
import { Tooltip, tooltipClasses, styled, TooltipProps } from "@mui/material";

export const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 400,
        borderLeft: "none",
        padding: 20,
        boxShadow: "1px 1px 10px grey",
        backgroundColor: "#ffff",
        borderRadius: "20px"
    }
}));

export const TooltipWrapper = Styled(HtmlTooltip)`
    & .MuiTooltip-arrow {
        color: #ffff !important;
    }
`;
