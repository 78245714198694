import { Drawer, IconButton } from "@mui/material";
import React, { useState } from "react";
import { MobileMenuContainer, MenuLinks } from "./HeaderStyle";
import MenuIcon from "@mui/icons-material/Menu";

const MobileHeader = (): JSX.Element => {
    const [drawer, setDrawer] = useState<boolean>(false);

    return (
        <div>
            <IconButton onClick={() => setDrawer(true)}>
                <MenuIcon />
            </IconButton>
            <Drawer anchor={"top"} open={drawer} onClose={() => setDrawer(false)}>
                <MobileMenuContainer>
                    {/* <MenuLinks>Stays</MenuLinks> */}
                    <MenuLinks>Flights</MenuLinks>
                </MobileMenuContainer>
            </Drawer>
        </div>
    );
};

export default MobileHeader;
