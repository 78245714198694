import { Typography, Button } from "@mui/material";
import React, { FC } from "react";
import { KeyboardArrowRight } from "@mui/icons-material";

type MapProps = {
    mapSrc: string;
    location?: string;
    mapWidth: string;
    height: string;
};
const Map: FC<MapProps> = ({ mapSrc, location, mapWidth, height }) => (
    <>
        <iframe src={mapSrc} width={mapWidth} height={height} style={{ border: 0 }} loading="lazy"></iframe>
        {location && (
            <Typography variant="subtitle1" component="div" gutterBottom>
                {location}
            </Typography>
        )}
        <Button endIcon={<KeyboardArrowRight />}>View Map </Button>
    </>
);

export default Map;
