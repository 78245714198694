import { Grid, Button } from "@mui/material";
import { useFormik } from "formik";
import React, { FC } from "react";
import { useNavigate } from "react-router";
import PaymentMethod from "../../payment/PaymentMethod";
import ReservationTerms from "../../payment/ReservationTerms";
import { allPassengerFormInitialValues, allPassengerFormValidationSchema } from "./Hooks";
import PassengerDetailForm from "./PassengerDetailForm";
import { PassengerDetailType } from "./Type";

type AllPassengerFormProps = {
    initialFormValues: any;
    totalNumberOfForms: number;
    submitForm: (formValues: PassengerDetailType[]) => void;
};

const AllPassengerForm: FC<AllPassengerFormProps> = ({ initialFormValues, totalNumberOfForms, submitForm }) => {
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: allPassengerFormInitialValues(initialFormValues),
        validationSchema: allPassengerFormValidationSchema(totalNumberOfForms),
        onSubmit: (values) => {
            const formDetailsArray: PassengerDetailType[] = [];
            Object.values(values).map((item) => formDetailsArray.push(item as PassengerDetailType));
            submitForm(formDetailsArray);
        }
    });

    return (
        <>
            <Grid item style={{ margin: 10 }}>
                {Array(totalNumberOfForms)
                    .fill(1)
                    .map((_, index) => (
                        <div key={index}>
                            <PassengerDetailForm
                                formik={formik}
                                isPrimary={index === 0}
                                formName={`form${index}`}
                                title={`Traveller ${index + 1}: ${initialFormValues[`form${index}`].typeText}${
                                    index === 0 ? "(Primary)" : ""
                                }`}
                            />
                        </div>
                    ))}
            </Grid>
            <Grid item style={{ margin: 10 }}>
                <PaymentMethod />
                <ReservationTerms />
                <Grid container justifyContent="center">
                    <Grid item style={{ margin: 10 }}>
                        <Button
                            onClick={() => navigate("/flight")}
                            disableRipple
                            variant={"outlined"}
                            color={"primary"}>
                            Back
                        </Button>{" "}
                        <Button
                            disableRipple
                            variant={"contained"}
                            color={"primary"}
                            onClick={() => formik.handleSubmit()}>
                            Complete reservation
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default AllPassengerForm;
