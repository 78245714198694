import { Grid, Typography } from "@mui/material";
import React, { FC } from "react";
import { disabledColor } from "../constants/ColorsConstants";
import { VerticaltrackerContainer, VerticalTracker, HeadingText } from "./CommonStyle";

type VerticalFlightTrackerProps = {
    departureTime: {
        time: string;
        date: string;
    };
    arrivalTime: {
        time: string;
        date: string;
    };
    departureStation: string;
    arrivalStation: string;
};

const VerticalFlightTracker: FC<VerticalFlightTrackerProps> = ({
    departureTime,
    arrivalStation,
    arrivalTime,
    departureStation
}) => {
    return (
        <Grid container style={{ marginTop: 20 }}>
            <VerticaltrackerContainer>
                <VerticalTracker />
            </VerticaltrackerContainer>
            <Grid item>
                <Grid
                    container
                    style={{ height: "100%" }}
                    direction={"column"}
                    gap={4}
                    justifyContent={"space-between"}
                >
                    <Grid item>
                        <HeadingText>
                            {departureTime.time}
                            <span style={{ fontWeight: 400 }}>{departureTime.date}</span>
                        </HeadingText>
                        <Typography variant={"caption"} style={{ color: disabledColor }}>
                            {departureStation}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <HeadingText>
                            {arrivalTime.time}
                            <span style={{ fontWeight: 400 }}>{arrivalTime.date}</span>
                        </HeadingText>
                        <Typography variant={"caption"} style={{ color: disabledColor }}>
                            {arrivalStation}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default VerticalFlightTracker;
