import { AutoAwesome, SocialDistance, Place, Check, Restaurant, DirectionsCarFilled } from "@mui/icons-material";
import { AreaData, AreaInfo } from "./types";

export const area: AreaData = {
    title: "Las Vegas",
    text: "Conrad Las Vegas at Resorts World is located in Las Vegas Strip, a neighbourhood in Las Vegas, and is in the city centre. Fashion Show Mall and Fremont Street Experience are worth exploring if shopping is on the agenda, while those wishing to experience the area's popular attractions can visit AREA15. Looking to enjoy an event or a match while in town? See what's going on at T-Mobile Arena. Spend some time exploring the area's activities, including outlet shopping.",
    locationUrl: "https://www.expedia.com.au/Las-Vegas.dx178276",
    locationUrlName: "Visit our Las Vegas travel guide",
    mapSrc: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3222.2569783751446!2d-115.16853718493796!3d36.1359557800907!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c8c40c4f536b0d%3A0x2e16fad366495333!2sW%20Resorts%20World%20Dr%2C%20Las%20Vegas%2C%20NV%2089109%2C%20USA!5e0!3m2!1sen!2sin!4v1637083688845!5m2!1sen!2sin"
};

export const areaInformation: AreaInfo[] = [
    {
        id: 0,
        title: "What's nearby",
        icon: <Place />,
        details: [
            "Fashion Show Mall - 14 min walk",
            "Las Vegas Convention Center - 14 min walk",
            "The Venetian Casino - 19 min walk",
            "High Roller - 3 min drive",
            "AREA15 - 4 min drive"
        ]
    },
    {
        id: 2,
        title: "Restaurants",
        icon: <Restaurant />,
        details: [
            "FUHU — 1 min walk",
            "VIVA — 1 min walk",
            "Peppermill Fireside Lounge - 3 min walk",
            "Sinatra - 4 min walk",
            "ANDREA'S - 7 min walk"
        ]
    },
    {
        id: 3,
        title: "Getting around",
        icon: <DirectionsCarFilled />,
        details: [
            "Las Vegas Convention Center Monorail Station - 15 min walk",
            "Las Vegas, NV (LAS-McCarran Intl.) - 7 min drive"
        ]
    }
];

export const AboutProperty: { title: string; text: string } = {
    title: "Conrad Las Vegas at Resorts World",
    text: "7 outdoor pools, a full-service spa and a casino are available at this hotel. Free WiFi in public areas and free self parking are also provided. Other amenities include a restaurant, a fitness centre and a bar/lounge. All 1400 rooms feature thoughtful touches such as bathrobes and slippers, plus in-room free WiFi and flat-screen TVs with cable channels. Guests will also find 24-hour room service, Netflix and minibars."
};

export const CleaningSafetyPractices: AreaInfo[] = [
    {
        id: 1,
        title: "Enhanced cleanliness measures",
        details: [
            "Disinfectant is used to clean the property",
            "High-touch surfaces are cleaned and disinfected",
            "Sheets and towels are washed at 60°C or hotter",
            "Guest accommodations are sealed after cleaning",
            "Follows the standard cleaning and disinfection practices of CleanStay (Hilton)",
            "Follows the industry cleaning and disinfection practices of GBAC STAR Facility Accreditation (global expert) and COVID-19 Guidelines (CDC)"
        ],
        icon: <AutoAwesome />
    },
    {
        id: 2,
        title: "Check-in",
        details: [
            "Contactless social distancing and check-out",
            "Cashless transactions are available for all charges at the property",
            "Protective shields in place at main contact areas",
            "Guest accommodations are accessible via mobile device",
            "Social distancing measures in place",
            "Contactless room service is available."
        ],
        icon: <SocialDistance />
    },
    {
        id: 3,
        title: "Safety measures",
        details: [
            "Gives staff personal protective equipment worn by staff",
            "Temperature checks given to staff",
            "Masks available",
            "Masks are required at the property",
            "Hand sanitiser provided",
            "Enhanced food service safety measures are in place",
            "Individually wrapped food options available for breakfast, lunch and dinner"
        ],
        icon: <Check />
    }
];
