import React, { FC } from "react";
import { Grid, Stack, Typography, LinearProgress, Divider, Button, IconButton } from "@mui/material";
import TbcCard from "../../../../commoncomponents/layout/card/TbcCard";
import { HeadingTypography } from "../../../../commoncomponents/CommonStyle";
import { overallRating, reviewsSummary, featuresReviews, reviews } from "./ReviewsData";
import { Box } from "@mui/system";
import InsertEmoticonIcon from "@mui/icons-material/InsertEmoticon";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";

const Reviews: FC = () => (
    <TbcCard>
        <Grid container>
            <Grid item xs={12} md={3}>
                <Box sx={{ display: "flex" }}>
                    <HeadingTypography mr={2} variant="h3">
                        {overallRating.value}
                    </HeadingTypography>
                    <div>
                        <Typography variant="subtitle1">{overallRating.description}</Typography>
                        <Typography
                            color="primary"
                            variant="subtitle2"
                        >{`${overallRating.reviewsCount} reviews`}</Typography>
                    </div>
                </Box>
                {reviewsSummary.map((review) => (
                    <Box key={review.id} my={1}>
                        <Stack direction="row" justifyContent="space-between">
                            <Typography variant="subtitle1">{review.title}</Typography>
                            <Typography variant="subtitle1">{review.value}</Typography>
                        </Stack>
                        <LinearProgress
                            variant="determinate"
                            value={Math.round((review.value / overallRating.reviewsCount) * 100)}
                        />
                    </Box>
                ))}
                <Grid container item py={2}>
                    {featuresReviews.map((feature) => (
                        <Grid key={feature.id} mb={1} xs={6} item>
                            <Typography variant="h6">{`${feature.value}/5`}</Typography>
                            <Typography variant="body2">{feature.title}</Typography>
                        </Grid>
                    ))}
                </Grid>
                <Button variant="outlined">Write a review</Button>
            </Grid>
            <Grid px={3} xs={12} md={9} item>
                {reviews.map((ele) => (
                    <Box my={2} key={ele.id}>
                        <HeadingTypography variant="h6">{ele.review}</HeadingTypography>
                        <Typography>{ele.user}</Typography>
                        <Typography variant="subtitle2">{ele.date}</Typography>
                        {ele.liked.length > 0 && (
                            <Stack direction="row" my={1}>
                                <InsertEmoticonIcon fontSize="small" />
                                <Typography ml={1} variant="caption">
                                    Liked:
                                </Typography>
                                {ele.liked.map((feature) => (
                                    <Typography key={feature} ml={1} variant="caption">
                                        {feature}
                                    </Typography>
                                ))}
                            </Stack>
                        )}

                        <HeadingTypography>{ele.title}</HeadingTypography>
                        <Typography variant="subtitle2">{ele.comment}</Typography>
                        <Typography variant="caption">{ele.duration}</Typography>
                        <Box>
                            <IconButton aria-label="likee">
                                <ThumbUpIcon color="primary" />
                            </IconButton>
                            <Typography variant="caption">{ele.likesCount}</Typography>
                        </Box>

                        <Divider />
                    </Box>
                ))}
            </Grid>
        </Grid>
    </TbcCard>
);

export default Reviews;
