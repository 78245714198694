import { Grid, FormControl, RadioGroup, FormControlLabel, Radio, MenuItem, Button } from "@mui/material";
import moment from "moment";
import { FC } from "react";
import SelectPassenger from "../../searchtabs/SelectPassenger";
import { SimpleTextDropdown } from "../../searchtabs/Style";
import { RETURN, ONE_WAY } from "../../../constants/SiteConstants";
import { FlightTypeOptions } from "../../../constants/SiteOptions";
import AirportDepartureArrivalView from "../../../commoncomponents/AirportDepartureArrivalView";
import CustomDateRangePicker from "../../../commoncomponents/CustomDateRangePicker";
import CustomSingleDatePicker from "../../../commoncomponents/CustomSingleDatePicker";
import { useFormik } from "formik";
import { flightSearchValidation } from "../../searchtabs/Util";
import { getFlightSearchParams, prepareFlightNavigationData } from "../results/Utils";
import { useNavigate } from "react-router";

type FlightSearchFormProps = {
    searchFlights?: () => void;
};
const FlightSearchForm: FC<FlightSearchFormProps> = ({ searchFlights }) => {
    const navigate = useNavigate();
    const { setFieldValue, values, handleSubmit } = useFormik({
        initialValues: getFlightSearchParams(),
        validationSchema: flightSearchValidation,
        onSubmit: () => {
            navigate(prepareFlightNavigationData(values));
            searchFlights && searchFlights();
        }
    });

    return (
        <form>
            <Grid container style={{ padding: 20 }}>
                <Grid item xs={12}>
                    <Grid container justifyContent={"space-between"}>
                        <Grid item>
                            <FormControl component="fieldset">
                                <RadioGroup
                                    row
                                    onChange={({ target }) => setFieldValue("searchType", target.value)}
                                    name={"searchType"}>
                                    <FormControlLabel
                                        value={RETURN}
                                        control={<Radio checked={values.searchType === RETURN} />}
                                        label="Round trip"
                                    />
                                    <FormControlLabel
                                        value={ONE_WAY}
                                        control={<Radio checked={values.searchType === ONE_WAY} />}
                                        label="One way"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <div style={{ display: "flex", gap: 10 }}>
                                <SelectPassenger
                                    passengerInfo={values.passengers}
                                    setFlightType={(flightType) => setFieldValue("passengers.flightType", flightType)}
                                    setPassenger={(passengerType) =>
                                        setFieldValue("passengers.passengerType", passengerType)
                                    }
                                />
                                <SimpleTextDropdown
                                    value={values.passengers.flightType}
                                    name={"flightType"}
                                    variant={"standard"}
                                    disableUnderline>
                                    {FlightTypeOptions.map((item) => (
                                        <MenuItem
                                            onClick={() => setFieldValue("passengers.flightType", item.value)}
                                            key={item.value}
                                            value={item.value}>
                                            {item.title}
                                        </MenuItem>
                                    ))}
                                </SimpleTextDropdown>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} style={{ marginTop: 15 }}>
                    <Grid container spacing={2} alignItems={"center"}>
                        <AirportDepartureArrivalView
                            fromPlace={values.from}
                            toPlace={values.to}
                            setFromPlace={(text, textCode) => {
                                setFieldValue("from", text);
                                setFieldValue("fromCode", textCode);
                            }}
                            setToPlace={(text, textCode) => {
                                setFieldValue("to", text);
                                setFieldValue("toCode", textCode);
                            }}
                        />
                        <Grid item xs={12} md={4}>
                            {values.searchType === "return" ? (
                                <CustomDateRangePicker
                                    startDate={values.departureDate || moment(new Date())}
                                    endDate={values.returnDate || moment().add(7, "days")}
                                    setFromDate={(date) => setFieldValue("departureDate", date)}
                                    setToDate={(date) => setFieldValue("returnDate", date)}
                                />
                            ) : (
                                <CustomSingleDatePicker
                                    date={values.departureDate}
                                    setDate={(date) => setFieldValue("departureDate", date)}
                                />
                            )}
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <Button
                                disableRipple
                                onClick={() => handleSubmit()}
                                variant={"contained"}
                                color={"primary"}
                                fullWidth>
                                Search
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
};

export default FlightSearchForm;
