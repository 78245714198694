import styled from "@emotion/styled";
import { Grid } from "@mui/material";

export const BaggageRow = styled(Grid)`
    margin: 0 10px;
    padding: 10px 0;
`;

export const BaggageCategory = styled(Grid)`
    border-right: 1px solid grey;
    padding-right: 10px;
    width: 80%;
    @media (max-width: 900px) {
        border-right: none;
        border-bottom: 1px solid grey;
        padding-bottom: 5px;
        width: fit-content;
    }
`;
