import {
    Wifi,
    BusinessCenter,
    LocalParking,
    FamilyRestroom,
    RestaurantMenu,
    Spa,
    FormatListBulleted,
    Accessible,
    LocalConvenienceStore,
    RoomService,
    Check,
    BeachAccess,
    Hotel,
    Bathroom
} from "@mui/icons-material";
import { Amenity } from "./types";

export const PropertyAmenities: Amenity[] = [
    {
        id: 1,
        title: "Internet",
        icon: <Wifi />,
        features: ["Available in all rooms: Free WiFi", "Available in some public areas: Free WiFi"]
    },
    {
        id: 2,
        title: "Business services",
        icon: <BusinessCenter />,
        features: ["24-hour business centre", "Conference centre (250000 square feet of space)", "Meeting rooms"]
    },
    {
        id: 3,
        title: "Parking and public transport",
        icon: <LocalParking />,
        features: [
            "Free covered self-parking on site",
            "Secured valet parking on site (USD 15 per day)",
            "Electric car charging station on site",
            "Wheelchair-accessible parking available"
        ]
    },
    {
        id: 4,
        title: "Outdoors",
        icon: <BeachAccess />,
        features: ["Outdoor entertainment area", "Outdoor furniture"]
    },
    {
        id: 5,
        title: "Food and drink",
        icon: <RestaurantMenu />,
        features: [
            "Continental breakfast available for a fee daily 4:00 AM–11:30 AM: USD 25 for adults and USD 15 for children",
            "1 restaurant and 3 coffee shops",
            "1 poolside bar and 1 bar/lounge",
            "24-hour room service"
        ]
    },
    {
        id: 6,
        title: "Spa",
        icon: <Spa />,
        features: [
            "23 treatment rooms",
            "Aromatherapy",
            "Body scrubs",
            "Body treatments",
            "Body wraps",
            "Couples treatment room(s)",
            "Detox wraps",
            "Facials",
            "Hydrotherapy",
            "Manicures and pedicures",
            "Massage - deep-tissue",
            "Massage - hot stone",
            "Massage - prenatal",
            "Massage - sports",
            "Massage - Swedish",
            "Massage - Thai",
            "Massage/treatment rooms",
            "Sauna",
            "Spa open daily",
            "Hot tub",
            "Steam room"
        ]
    },
    {
        id: 7,
        title: "Things to do",
        icon: <FormatListBulleted />,
        features: [
            "7 outdoor pools",
            "Children's pool",
            "Designer shops",
            "Fitness centre",
            "Full-service spa",
            "Sauna",
            "Shopping",
            "Steam room"
        ]
    },
    {
        id: 8,
        title: "Family friendly",
        icon: <FamilyRestroom />,
        features: [
            "7 outdoor pools",
            "Children's pool",
            "Free cots/infant beds",
            "Corner/local shop",
            "Laundry facilities",
            "Mini-fridge",
            "Snack bar/deli"
        ]
    },
    {
        id: 9,
        title: "Accessibility",
        icon: <Accessible />,
        features: [
            "If you have any requests for specific accessibility needs, please contact the property using the information on the reservation confirmation received after booking.",
            "Step-free path to entrance",
            "Well-lit path to entrance",
            "Wheelchair-accessible business centre",
            "Wheelchair-accessible fitness centre",
            "Wheelchair-accessible lounge",
            "Wheelchair-accessible parking",
            "Wheelchair-accessible path of travel",
            "Wheelchair-accessible path to lift",
            "Wheelchair-accessible public bathroom",
            "Wheelchair-accessible registration desk",
            "Wheelchair-accessible restaurant"
        ]
    },
    {
        id: 10,
        title: "Conveniences",
        icon: <LocalConvenienceStore />,
        features: [
            "ATM/banking services",
            "Lift",
            "Front-desk safe",
            "Gift shop/newsstand",
            "Corner/local shop",
            "Vending machine",
            "Water dispenser"
        ]
    },
    {
        id: 11,
        title: "Guest services",
        icon: <RoomService />,
        features: [
            "24-hour front desk",
            "Concierge services",
            "Daily housekeeping",
            "Dry-cleaning service",
            "Hair salon",
            "Laundry facilities",
            "Luggage storage",
            "Multilingual staff",
            "Porter",
            "Tour/ticket assistance",
            "Wedding services"
        ]
    },
    {
        id: 12,
        title: "More",
        icon: <Check />,
        features: [
            "100 casino gaming tables",
            "100 casino slot machines",
            "Casino (117000 square feet of space)",
            "Casino sportsbook",
            "Casino VIP room",
            "Designated smoking areas (fines apply)"
        ]
    },
    {
        id: 13,
        title: "Languages spoken",
        icon: <Check />,
        features: ["English", "Spanish"]
    }
];

export const RoomAmenities = [
    {
        id: 1,
        title: "Bedroom",
        icon: <Hotel />,
        features: [
            "Air conditioning",
            "Bed sheets",
            "Blackout drapes/curtains",
            "Free cots/infant beds",
            "Heating",
            "Premium bedding"
        ]
    },
    {
        id: 2,
        title: "Food and drink",
        icon: <RestaurantMenu />,
        features: ["Mini fridge", "Minibar"]
    },
    {
        id: 3,
        title: "Bathroom",
        icon: <Bathroom />,
        features: [
            "Bathrobes",
            "Free toiletries",
            "Hairdryer",
            "Shampoo",
            "Shower",
            "Soap",
            "Toilet paper",
            "Towels",
            "Toothbrush and toothpaste (on request)"
        ]
    },
    {
        id: 4,
        title: "More",
        icon: <Check />,
        features: [
            "Free local calls",
            "Free long-distance calls",
            "Iron/ironing board",
            "Laptop-friendly workspace",
            "Mobile key entry",
            "Phone",
            "Safe",
            "Slippers"
        ]
    },
    {
        id: 5,
        title: "Entertainment",
        icon: <Check />,
        features: ["55-inch flat-screen TV", "TV channels", "Hulu", "Netflix", "Streaming services"]
    }
];
