import { Checkbox, CircularProgress, FormGroup, Grid, useMediaQuery, useTheme } from "@mui/material";
import { capitalize } from "lodash";
import React, { FC } from "react";
import { FilterDivider, FormControlLabelWrapper } from "../../../commoncomponents/CommonStyle";
import { StopsOptions, TimesOption } from "../../../constants/SiteOptions";
import { FlightSearchFiltersType, SelectedFilterType } from "../results/Type";
import { FilterContainer, FilterTitle, SubFilterTitle } from "./Styles";

type FlightFiltersProps = {
    isLoading: boolean;
    filterList?: FlightSearchFiltersType;
    departurePlace: string;
    arrivalPlace: string;
    selectedFilters: SelectedFilterType;
    setSelectedFilters: (selectedFilter: SelectedFilterType) => void;
};

const FlightFilters: FC<FlightFiltersProps> = ({
    isLoading,
    filterList,
    arrivalPlace,
    departurePlace,
    setSelectedFilters,
    selectedFilters
}) => {
    //TODO condense these four methods into one
    const handleAirlineChange = (selectedValue: string, checked: boolean) => {
        let airlines = [];
        if (checked) {
            airlines = [...selectedFilters.airlines, selectedValue];
        } else {
            airlines = selectedFilters.airlines.filter((item) => item !== selectedValue);
        }
        setSelectedFilters({ ...selectedFilters, airlines });
    };

    const handleStopChange = (selectedValue: string, checked: boolean) => {
        let stops: string[] = [];
        if (checked) {
            stops = [...selectedFilters.stops, selectedValue];
        } else {
            stops = selectedFilters.stops.filter((item) => item !== selectedValue);
        }
        setSelectedFilters({ ...selectedFilters, stops });
    };

    const handleDepartureChange = (selectedValue: string, checked: boolean) => {
        let departureTime: string[] = [];
        if (checked) {
            departureTime = [...selectedFilters.departureTime, selectedValue];
        } else {
            departureTime = selectedFilters.departureTime.filter((item) => item !== selectedValue);
        }
        setSelectedFilters({ ...selectedFilters, departureTime });
    };

    const handleArrivalChange = (selectedValue: string, checked: boolean) => {
        let arrivalTime: string[] = [];
        if (checked) {
            arrivalTime = [...selectedFilters.arrivalTime, selectedValue];
        } else {
            arrivalTime = selectedFilters.arrivalTime.filter((item) => item !== selectedValue);
        }
        setSelectedFilters({ ...selectedFilters, arrivalTime });
    };

    if (isLoading) {
        return (
            <Grid style={{ marginTop: 60 }} container justifyContent={"center"} alignItems={"center"}>
                <CircularProgress />
            </Grid>
        );
    }
    const theme = useTheme();
    const mobileView = useMediaQuery(theme.breakpoints.down(900));

    return (
        <FilterContainer isMobile={mobileView ? 1 : 0}>
            <FilterTitle>Filter by:</FilterTitle>
            <Grid style={{ marginTop: 10 }}>
                <SubFilterTitle>Airlines</SubFilterTitle>
                <FilterDivider />
                <FormGroup>
                    {filterList?.airlines.map((item) => (
                        <FormControlLabelWrapper
                            key={`${item.iata}-${Math.random()}`}
                            control={
                                <Checkbox
                                    checked={selectedFilters?.airlines.includes(item.iata) || false}
                                    onChange={({ target }) => handleAirlineChange(item.iata, target.checked)}
                                />
                            }
                            label={item.name}
                        />
                    ))}
                </FormGroup>
            </Grid>
            <Grid item xs={12} style={{ marginTop: 10 }}>
                <Grid>
                    <SubFilterTitle>
                        Departure from <span>{capitalize(departurePlace)}</span>
                    </SubFilterTitle>
                    <FilterDivider />
                    <FormGroup>
                        {TimesOption.map((item) => (
                            <FormControlLabelWrapper
                                key={item.id}
                                control={
                                    <Checkbox
                                        checked={selectedFilters.departureTime.includes(item.id)}
                                        onChange={({ target }) => handleDepartureChange(item.id, target.checked)}
                                    />
                                }
                                label={item.title}
                            />
                        ))}
                    </FormGroup>
                </Grid>
                <Grid>
                    <SubFilterTitle>Arrival to {capitalize(arrivalPlace)}</SubFilterTitle>
                    <FilterDivider />
                    <FormGroup>
                        {TimesOption.map((item) => (
                            <FormControlLabelWrapper
                                key={item.id}
                                control={
                                    <Checkbox
                                        checked={selectedFilters.arrivalTime.includes(item.id)}
                                        onChange={({ target }) => handleArrivalChange(item.id, target.checked)}
                                    />
                                }
                                label={item.title}
                            />
                        ))}
                    </FormGroup>
                </Grid>
            </Grid>
            <Grid style={{ marginTop: 10 }}>
                <SubFilterTitle>Stops</SubFilterTitle>
                <FilterDivider />
                <FormGroup>
                    {StopsOptions.map((item) => (
                        <FormControlLabelWrapper
                            key={item.value}
                            control={
                                <Checkbox
                                    checked={selectedFilters?.stops.includes(item.value) || false}
                                    onChange={({ target }) => handleStopChange(item.value, target.checked)}
                                />
                            }
                            label={item.title}
                        />
                    ))}
                </FormGroup>
            </Grid>
        </FilterContainer>
    );
};

export default FlightFilters;
