import { Grid, Typography } from "@mui/material";
import moment from "moment";
import React, { FC } from "react";
import VerticalFlightTracker from "../../../commoncomponents/VerticalFlightTracker";
import { convertMinsToHrsMins, get24HourTime, getFlightLogo } from "../../../util/SiteUtils";
import { FlightConnectContainer } from "../../flights/results/Style";
import { AllPassengerInfoType } from "../passengerinfo/Type";

type BookingFlightAllDescription = {
    flightDetails?: AllPassengerInfoType;
    itenariesCount: number;
};
const BookingFlightAllDescription: FC<BookingFlightAllDescription> = ({ flightDetails, itenariesCount }) => {
    if (!flightDetails) {
        return <></>;
    }
    const layovers = flightDetails?.itineraries[itenariesCount].layovers;
    return (
        <Grid>
            <Grid container justifyContent={"space-between"}>
                <Grid item>
                    <Grid container spacing={1}>
                        <Grid item>
                            <img
                                src={getFlightLogo(flightDetails.family.validatingCarrier || "")}
                                alt="flight-icon"
                                height="30"
                                width="30"
                            />
                        </Grid>
                        <Grid item>
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <Typography variant={"body1"} style={{ fontWeight: 600 }}>
                                    {flightDetails.family.validatingCarrier}
                                </Typography>
                                <Typography variant={"caption"}>
                                    Opeartaed by {flightDetails.family.provider}
                                </Typography>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Typography variant={"body1"}>
                        {convertMinsToHrsMins(flightDetails?.itineraries[itenariesCount].duration || 0)}
                    </Typography>
                </Grid>
            </Grid>
            <Grid style={{ paddingLeft: 10 }}>
                {flightDetails.itineraries[itenariesCount].segments.map((segment, index) => (
                    <div style={{ marginTop: 20 }} key={`segment-${index}`}>
                        <VerticalFlightTracker
                            key={index}
                            arrivalTime={{
                                time: `${get24HourTime(segment.arrivalTime)}`,
                                date: `${moment(segment.arrivalTime).format("ddd Do MMM")}`
                            }}
                            departureTime={{
                                time: `${get24HourTime(segment.departureTime)}`,
                                date: `${moment(segment.departureTime).format("ddd Do MMM")}`
                            }}
                            arrivalStation={segment.departureCountry}
                            departureStation={segment.arrivalCountry}
                        />
                        {index < flightDetails.itineraries[itenariesCount].segments.length - 1 && (
                            <FlightConnectContainer container>
                                {/* <Grid item xs={2}>
                                    <Typography>{flightDetails.itineraries[itenariesCount]}</Typography>
                                </Grid>
                                <Grid item xs={10}>
                                    <Typography style={{ fontSize: "0.9rem", fontWeight: "bold" }}>
                                        Connect in airport
                                    </Typography>
                                </Grid> */}
                                {layovers && layovers[index] && (
                                    <Typography style={{ fontSize: "0.9rem", fontWeight: "bold" }}>
                                        {convertMinsToHrsMins(layovers[index].duration)} - Connect in airport
                                    </Typography>
                                )}
                            </FlightConnectContainer>
                        )}
                    </div>
                ))}
            </Grid>
        </Grid>
    );
};

export default BookingFlightAllDescription;
