import React from "react";
import { Route, Routes } from "react-router";
import { BrowserRouter, Navigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import SearchFlightView from "./component/flights/results/SearchFlightView";
import Header from "./component/header/Header";
import Footer from "./component/footer/Footer";
import PassengerInfoView from "./component/booking/passengerinfo/PassengerInfoView";
import FlightSearchTab from "./component/searchtabs/FlightSearchTab";
import BaggageSelectionView from "./component/booking/baggage/BaggageSelectionView";
import PropertyDetailView from "./component/property/details/PropertyDetailView";
import MainContainer from "./commoncomponents/layout/main/MainContainer";
import PaymentView from "./component/payment/PaymentView";
import BookingReviewView from "./component/booking/review/BookingReviewView";
import ReservationView from "./component/property/reservation/ReservationView";
import PropertySearchView from "./component/property/search/PropertySearchView";
import CircularStdBookWoff from "./fonts/CircularStd-Book.woff";
import CircularStdBookWoff2 from "./fonts/CircularStd-Book.woff2";
import CircularStdBoldWoff from "./fonts/CircularStd-Bold.woff";
import CircularStdBoldWoff2 from "./fonts/CircularStd-Bold.woff2";
import CircularStdBlackWoff from "./fonts/CircularStd-Black.woff";
import CircularStdBlackWoff2 from "./fonts/CircularStd-Black.woff2";
import ThankyouView from "./component/thankyou/ThankyouView";
import { PrivacyPolicyPage } from "./component/general/PrivacyPolicyPage";
import { TermsOfServicePage } from "./component/general/TermsOfServicePage";

const theme = createTheme({
    typography: {
        fontFamily: ["Circular", '"Helvetica Neue"', "Arial", "sans-serif"].join(",")
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: `
        @font-face {
          font-family: 'Circular';
          font-style: normal;
          font-weight: 400;
          src: 
            url(${CircularStdBookWoff}) format('woff');
            url(${CircularStdBookWoff2}) format('woff2');
        }
        @font-face {
            font-family: 'Circular';
            font-style: normal;
            font-weight: 700;
            src: 
              url(${CircularStdBoldWoff}) format('woff');
              url(${CircularStdBoldWoff2}) format('woff2');
          }
        @font-face {
        font-family: 'Circular';
        font-style: normal;
        font-weight: 900;
        src: 
            url(${CircularStdBlackWoff}) format('woff');
            url(${CircularStdBlackWoff2}) format('woff2');
        }
      `
        }
    }
});

const App = () => {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <BrowserRouter>
                <Header />
                <MainContainer>
                    <Routes>
                        <Route path="/" element={<Navigate to={"/flights"} />} />
                        <Route path="/stays/property/reservation" element={<ReservationView />} />
                        <Route path="/stays/property/details" element={<PropertyDetailView />} />
                        <Route path="/stays/search" element={<PropertySearchView />} />
                        <Route path="/flights" element={<FlightSearchTab />} />
                        <Route path="/flights/search" element={<SearchFlightView />} />
                        <Route path="/flights/payment" element={<PaymentView />} />
                        <Route path="/flights/passengerinfo" element={<PassengerInfoView />} />
                        <Route path="/flights/baggageselection" element={<BaggageSelectionView />} />
                        <Route path="/flights/bookingreview" element={<BookingReviewView />} />
                        <Route path="/thankyou" element={<ThankyouView />} />
                        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
                        <Route path="/terms-of-service" element={<TermsOfServicePage />} />
                    </Routes>
                </MainContainer>
                <Footer />
            </BrowserRouter>
        </ThemeProvider>
    );
};

export default App;
