import { Grid, Paper, useMediaQuery, useTheme } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { useBooleanState, useDataState } from "../../../commoncomponents/CommonHooks";
import FlightFilters from "../filters/FlightFilters";
import {
    emptyFlightFilters,
    filterFlightSearchResults,
    getFlightFilterListSection,
    getFlightSearchParams,
    revalidateFlight,
    reverseTransformFlightSearch
} from "./Utils";
import {
    FlightSearchFiltersType,
    FlightSearchInfoType,
    FlightSearchResultsType,
    SelectedFilterType,
    SelectedFlightType
} from "./Type";
import FlightSearchForm from "../searchform/FlightSearchForm";
import FlightList from "./FlightList";
import SelectedFlightStepper from "../resultstepper/SelectedFlightStepper";
import { ONE_WAY } from "../../../constants/SiteConstants";
import ErrorDialog from "../../../commoncomponents/ErrorDialog";
import MobileFilterSection from "./mobileview/MobileFilterSection";

const SearchFlightView = () => {
    const navigate = useNavigate();
    const filters = useDataState<FlightSearchFiltersType>();
    const outboundFlightResults = useDataState<FlightSearchResultsType[]>([]);
    const inboundFlightResults = useDataState<FlightSearchResultsType[]>([]);
    const isLoading = useBooleanState(false);
    const [selectedFilters, setSelectedFilters] = useState<SelectedFilterType>(emptyFlightFilters);
    const [selectedFlight, setSelectedFlight] = useState<SelectedFlightType>({
        outboundFlight: null,
        inboundFlight: null
    });
    const showReturnFlightView = useBooleanState(false);
    const theme = useTheme();
    const mobileView = useMediaQuery(theme.breakpoints.down(900));
    const [errorDetails, setErrorDetails] = useState<string[]>([]);

    const values = getFlightSearchParams();
    const searchParams = new URLSearchParams(window.location.search);
    const searchFlights = () => {
        isLoading.setTrue();
        showReturnFlightView.setFalse();
        setSelectedFlight({
            outboundFlight: null,
            inboundFlight: null
        });
        showReturnFlightView.setFalse();
        getFlightFilterListSection(reverseTransformFlightSearch(searchParams))
            .then((res: FlightSearchInfoType) => {
                filters.set(res.filters as FlightSearchFiltersType);
                outboundFlightResults.set(res.results.outbound);
                inboundFlightResults.set(res.results.inbound);
                setSelectedFlight({
                    outboundFlight: null,
                    inboundFlight: null
                });
            })
            .catch(() => {
                outboundFlightResults.set([]);
                inboundFlightResults.set([]);
            })
            .finally(() => isLoading.setFalse());
    };

    useEffect(() => {
        searchFlights();
    }, []);

    const filteredFlightRows = useMemo(() => {
        return filterFlightSearchResults(
            selectedFilters,
            selectedFlight.outboundFlight ? inboundFlightResults.value || [] : outboundFlightResults.value || []
        );
    }, [selectedFilters, selectedFlight, outboundFlightResults, inboundFlightResults]);

    const handleOutboundSelect = (outboundFlight: FlightSearchResultsType) => {
        if (values.searchType === ONE_WAY) {
            revalidateFlight([outboundFlight.key], true)
                .then(() => {
                    isLoading.setFalse();
                    navigate(
                        `/flights/passengerinfo?outboundKey=${outboundFlight?.key}&singleTicket=${true}&oneway=${
                            searchParams.get("searchtype") === ONE_WAY
                        }`
                    );
                })
                .catch((err) => {
                    setErrorDetails(err.response.data.errors);
                    isLoading.setFalse();
                });
            return;
        }
        showReturnFlightView.setTrue();
        setSelectedFlight({
            ...selectedFlight,
            outboundFlight
        });

        setSelectedFilters(emptyFlightFilters);
    };

    const handleInboundSelect = (inboundFlight: FlightSearchResultsType) => {
        setSelectedFlight({
            ...selectedFlight,
            inboundFlight
        });
        if (!selectedFlight.outboundFlight) {
            return;
        }
        const keys = [selectedFlight.outboundFlight.key];
        if (inboundFlight) {
            keys.push(inboundFlight.key);
        }
        const singleTicket = selectedFlight.outboundFlight.matchingInbounds?.includes(inboundFlight.uid) || false;

        isLoading.setTrue();
        revalidateFlight(keys, singleTicket)
            .then(() => {
                isLoading.setFalse();
                navigate(
                    `/flights/passengerinfo?outboundKey=${selectedFlight.outboundFlight?.key}&inboundKey=${
                        inboundFlight?.key
                    }&singleTicket=${singleTicket}&oneway=${searchParams.get("searchtype") === ONE_WAY}`
                );
            })
            .catch((err) => {
                setErrorDetails(err.response?.data.errors);
                isLoading.setFalse();
            });
    };

    const handleCloseErrorDialog = useCallback(() => {
        errorDetails.forEach((item) => {
            if (item.toLowerCase().includes("outbound")) {
                setSelectedFlight({
                    outboundFlight: null,
                    inboundFlight: null
                });
                showReturnFlightView.setFalse();
                return;
            }
            if (item.toLowerCase().includes("inbound")) {
                setSelectedFlight((prev) => ({
                    ...prev,
                    inboundFlight: null
                }));
                return;
            }
            if (!item.toLowerCase().includes("inbound") && item.toLowerCase().includes("outbound")) {
                setSelectedFlight({
                    outboundFlight: null,
                    inboundFlight: null
                });
                showReturnFlightView.setFalse();
                return;
            }
        });
        setErrorDetails([]);
    }, [errorDetails, selectedFlight, showReturnFlightView]);

    return (
        <div>
            <Paper style={{ display: "flex", justifyContent: "center" }}>
                <Grid container justifyContent={"center"}>
                    <Grid item xs={12} md={10}>
                        <FlightSearchForm searchFlights={searchFlights} />
                    </Grid>
                </Grid>
            </Paper>
            {/* <FlightStepper activeStep={0} /> */}
            <Grid container justifyContent="center">
                <Grid item xs={12} md={10}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={3}>
                            {mobileView ? (
                                <MobileFilterSection
                                    isLoading={isLoading.value}
                                    filterList={filters.value}
                                    arrivalPlace={values.to}
                                    departurePlace={values.from}
                                    selectedFilters={selectedFilters}
                                    setSelectedFilters={(filters: SelectedFilterType) => {
                                        setSelectedFilters(filters);
                                    }}
                                />
                            ) : (
                                <FlightFilters
                                    isLoading={isLoading.value}
                                    filterList={filters.value}
                                    arrivalPlace={values.to}
                                    departurePlace={values.from}
                                    selectedFilters={selectedFilters}
                                    setSelectedFilters={(filters: SelectedFilterType) => {
                                        setSelectedFilters(filters);
                                    }}
                                />
                            )}
                        </Grid>
                        <Grid item xs={12} md={9} style={{ paddingBottom: 20 }}>
                            {selectedFlight.outboundFlight && (
                                <SelectedFlightStepper
                                    selectedDepartureFlight={selectedFlight.outboundFlight}
                                    selectedArrivalFlight={selectedFlight.inboundFlight}
                                    fromAirport={values.from}
                                    airlineList={filters.value?.airlines}
                                    changeDepartureFlight={() => {
                                        setSelectedFlight({
                                            outboundFlight: null,
                                            inboundFlight: null
                                        });
                                        showReturnFlightView.setFalse();
                                        setSelectedFilters({
                                            airlines: [],
                                            departureTime: [],
                                            arrivalTime: [],
                                            stops: []
                                        });
                                    }}
                                />
                            )}

                            {showReturnFlightView.value ? (
                                <FlightList
                                    isLoading={isLoading.value}
                                    flightsList={filteredFlightRows || []}
                                    airlineList={filters.value?.airlines}
                                    airportList={filters.value?.airports}
                                    title={"Select return flight"}
                                    setSelectedFlight={handleInboundSelect}
                                    searchType={"Round Trip"}
                                    minFlightCost={selectedFlight.outboundFlight?.total || 0}
                                />
                            ) : (
                                <FlightList
                                    isLoading={isLoading.value}
                                    flightsList={filteredFlightRows || []}
                                    airlineList={filters.value?.airlines}
                                    airportList={filters.value?.airports}
                                    title={"Select departing flight"}
                                    setSelectedFlight={handleOutboundSelect}
                                    searchType={values.searchType === ONE_WAY ? "One Way" : "Round Trip"}
                                    minFlightCost={inboundFlightResults.value?.[0]?.total || 0}
                                />
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {errorDetails.length > 0 && (
                <ErrorDialog errorDetails={errorDetails} handleClose={handleCloseErrorDialog} />
            )}
        </div>
    );
};

export default SearchFlightView;
