import { Grid, Typography, Checkbox } from "@mui/material";
import React from "react";
import { InfoText, InfoTitleText } from "../../../commoncomponents/CommonStyle";
import TbcCard from "../../../commoncomponents/layout/card/TbcCard";
import { BaggageRow, BaggageCategory } from "./Styled";

const AddBaggageForm = () => (
    <TbcCard title="Add Baggage">
        <Typography color="primary">Passenger 1: Rob Miller (Adult)</Typography>
        <br />
        <Grid container>
            <BaggageRow container alignItems={"center"} direction="row">
                <Grid item xs={12} md={2}>
                    <BaggageCategory>Carry on</BaggageCategory>
                </Grid>
                <Grid item xs={12} md={9}>
                    <Grid container alignItems={"center"} sx={{ mt: "20px" }} justifyContent={"space-between"}>
                        <Grid item xs={10} md={6}>
                            <div style={{ display: "flex" }}>
                                <Checkbox />
                                <InfoText>1 x Personal item (48 x 23 x 34cm, 7kg)</InfoText>
                            </div>
                        </Grid>
                        <Grid item xs={2}>
                            <InfoTitleText>US $0</InfoTitleText>
                        </Grid>
                    </Grid>
                    <Grid container alignItems={"center"} sx={{ mt: "20px" }} justifyContent={"space-between"}>
                        <Grid item xs={10} md={6}>
                            <div style={{ display: "flex" }}>
                                <Checkbox />
                                <InfoText>
                                    1 x Personal item (48 x 23 x 34cm, 7kg)
                                    <br />1 x Cabin bag (56 x 23 x 36cm, 7kg)
                                </InfoText>
                            </div>
                        </Grid>
                        <Grid item xs={2}>
                            <InfoTitleText>US $26.87</InfoTitleText>
                        </Grid>
                    </Grid>
                </Grid>
            </BaggageRow>

            <BaggageRow container alignItems={"center"} direction="row">
                <Grid item xs={12} md={2}>
                    <BaggageCategory>Checked Baggage</BaggageCategory>
                </Grid>
                <Grid item xs={12} md={9}>
                    <Grid container alignItems={"center"} sx={{ mt: "10px" }} justifyContent={"space-between"}>
                        <Grid item xs={10} md={6}>
                            <div style={{ display: "flex" }}>
                                <Checkbox />
                                <InfoText>No checked baggage</InfoText>
                            </div>
                        </Grid>
                        <Grid item xs={2}>
                            <InfoTitleText>US $0</InfoTitleText>
                        </Grid>
                    </Grid>
                    <Grid container alignItems={"center"} sx={{ mt: "10px" }} justifyContent={"space-between"}>
                        <Grid item xs={10} md={9}>
                            <div style={{ display: "flex" }}>
                                <Checkbox />
                                <InfoText>1 x Checked baggage(70 x 38 x 50cm, 15kg)</InfoText>
                            </div>
                        </Grid>
                        <Grid item xs={2}>
                            <InfoTitleText>US $27.9</InfoTitleText>
                        </Grid>
                    </Grid>
                </Grid>
            </BaggageRow>
        </Grid>
    </TbcCard>
);

export default AddBaggageForm;
