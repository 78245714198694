import React, { FC, useMemo, useState } from "react";
import { Grid, Tabs, Tab } from "@mui/material";
import PropertyDetailOverview from "./overview/PropertyDetailOverview";
import Amenities from "./amenities/Amenities";
import Policies from "./policies/Policies";
import LocationGrid from "./location/LocationGrid";
import Reviews from "./reviews/Reviews";
import PropertyDetailCarousel from "./PropertyDetailCarousel";
import { carouselImages } from "./PropertyDetailsData";

const PropertyDetailView: FC = () => {
    const [selectedTab, setSelectedTab] = useState(0);
    const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
        setSelectedTab(newValue);
    };

    const renderSelectedTabView = useMemo(() => {
        switch (selectedTab) {
            case 0:
                return <PropertyDetailOverview />;
            case 1:
                return <>Rooms</>;
            case 2:
                return <LocationGrid />;
            case 3:
                return <Amenities />;
            case 4:
                return <Policies />;
            case 5:
                return <Reviews />;
        }
        return <>Yet to be built</>;
    }, [selectedTab]);

    return (
        <Grid container spacing={3} style={{ padding: 20 }}>
            <Grid item xs={12}>
                <PropertyDetailCarousel images={carouselImages} />
            </Grid>
            <Grid item xs={12}>
                <Tabs value={selectedTab} onChange={handleTabChange}>
                    <Tab label="Overview" />
                    <Tab label="Rooms" />
                    <Tab label="Locations" />
                    <Tab label="Amenities" />
                    <Tab label="Policies" />
                    <Tab label="Reviews" />
                </Tabs>
            </Grid>

            <Grid item xs={12}>
                {renderSelectedTabView}
            </Grid>
        </Grid>
    );
};

export default PropertyDetailView;
