import React from "react";
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material";
import { FormikProps } from "formik";
import { OptionsType } from "./Base";
import { get } from "lodash";
import { errorColor } from "../constants/ColorsConstants";

type TProps = Record<string, unknown>;

type SelectDropdownWrapperProps<T> = {
    options: OptionsType[];
    formik: FormikProps<T>;
    name: string;
    label: string;
};

const SelectDropdownFormikWrapper = <T extends TProps>(props: SelectDropdownWrapperProps<T>) => {
    return (
        <FormControl
            fullWidth
            size={"small"}
            error={!!get(props.formik.errors, props.name) && !!get(props.formik.touched, props.name)}>
            <InputLabel>{props.label}</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                value={props.name && get(props.formik.values, props.name)}
                name={props.name}
                label={props.label}
                onChange={props.formik.handleChange}
                onBlur={() => {
                    props.name && props.formik && props.formik.setFieldTouched(props.name);
                }}>
                {props.options.map((option: OptionsType) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.text}
                    </MenuItem>
                ))}
            </Select>
            {!!get(props.formik.errors, props.name) && !!get(props.formik.touched, props.name) && (
                <FormHelperText style={{ color: errorColor }}>{get(props.formik.errors, props.name)}</FormHelperText>
            )}
        </FormControl>
    );
};

export default SelectDropdownFormikWrapper;
