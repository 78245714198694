import React from "react";
import TbcCard from "../../commoncomponents/layout/card/TbcCard";
import BookingFlightSummary from "../booking/flightsummary/BookingFlightSummary";

const FlightSummary = () => {
    return (
        <TbcCard>
            <BookingFlightSummary itenariesCount={0} />
            <br />
            <hr />
            <br />
            <BookingFlightSummary itenariesCount={0} />
        </TbcCard>
    );
};

export default FlightSummary;
