import { TextFieldProps } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { AirportListType } from "../../component/searchtabs/Type";
import { TProps } from "../Base";
import { useBooleanState, useDataState, useOnClickOutside } from "../CommonHooks";
import TextFieldWrapper from "../TextFieldWrapper";
import SearchTextPopover from "./SearchTextPopover";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type SearchTextfieldWrapperProps<T> = {
    onInputChange: (text: string, textCode?: string) => void;
    options?: AirportListType[];
    onOptionSelect: (text: string, textCode?: string) => void;
    isloading?: boolean;
} & TextFieldProps;

const SearchTextfieldWrapper = <T extends TProps>(props: SearchTextfieldWrapperProps<T>) => {
    const textFieldPosition = useRef<HTMLDivElement>(null);
    const searchModalHeight = useDataState<number>(0);
    const showSearchModal = useBooleanState(false);

    useOnClickOutside(textFieldPosition, () => showSearchModal.setFalse());
    useEffect(() => {
        searchModalHeight.set(textFieldPosition.current?.offsetTop);
    }, [textFieldPosition.current]);

    return (
        <div ref={textFieldPosition}>
            <TextFieldWrapper
                size={"small"}
                onChange={({ target }) => props.onInputChange(target.value)}
                {...props}
                onFocus={() => showSearchModal.setTrue()}
            />
            {showSearchModal.value && (
                <SearchTextPopover
                    handleAirportSelect={(text, airportSelectCode) => {
                        props.onOptionSelect(text, airportSelectCode);
                        showSearchModal.setFalse();
                    }}
                    isLoading={props.isloading || false}
                    options={props.options || []}
                    searchFieldHeight={searchModalHeight.value || 0}
                />
            )}
        </div>
    );
};

export default SearchTextfieldWrapper;
