import React from "react";
import { CryptoIconsWrapper, CryptoIcon } from "./HeaderStyle";
import ethereumLogo from "../../images/ethereum.png";
import bitcoinLogo from "../../images/bitcoin.png";
import bitcoincashLogo from "../../images/bitcoincash.png";
import litecoinLogo from "../../images/litecoin.png";

const CryptoIcons = () => {
    return (
        <CryptoIconsWrapper>
            <CryptoIcon src={bitcoinLogo} />
            <CryptoIcon src={bitcoincashLogo} />
            <CryptoIcon src={ethereumLogo} />
            <CryptoIcon src={litecoinLogo} />
        </CryptoIconsWrapper>
    );
};

export default CryptoIcons;
