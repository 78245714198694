import { ListItem, ListItemButton, ListItemText } from "@mui/material";
import { useMemo } from "react";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import { OptionsListContainer } from "./Style";
import { AirportListType } from "../../component/searchtabs/Type";
import LocationCityIcon from "@mui/icons-material/LocationCity";

export const useRenderAirportOptions = (
    options: AirportListType,
    handleAirportSelect: (optionText: string, optionCode: string) => void,
    type: "sub" | "primary"
) =>
    useMemo(
        () => (
            <OptionsListContainer
                onClick={() => handleAirportSelect(options.name, options.iata)}
                key={options.id}
                variant={type}>
                <ListItem disablePadding>
                    <ListItemButton style={{ padding: 5 }}>
                        <span style={{ marginRight: 10, color: "grey" }}>
                            {options.type !== "city" ? (
                                <FlightTakeoffIcon style={{ fontSize: 20 }} />
                            ) : (
                                <LocationCityIcon style={{ fontSize: 20 }} />
                            )}
                        </span>
                        <ListItemText
                            primaryTypographyProps={{
                                style: {
                                    fontSize: 14,
                                    fontWeight: "bold"
                                }
                            }}
                            primary={`${options.name} (${options.iata})`}
                            secondary={`${options.city}, ${options.countryIata}`}
                        />
                    </ListItemButton>
                </ListItem>
            </OptionsListContainer>
        ),
        [options, handleAirportSelect, type]
    );
