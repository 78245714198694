import { Grid, Typography, Button, Container } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router";
import TbcCard from "../../commoncomponents/layout/card/TbcCard";

const ThankyouView = () => {
    const navigation = useNavigate();

    return (
        <div style={{ minHeight: "calc(100vh - 84px)" }}>
            <Container maxWidth={"lg"}>
                <TbcCard>
                    <Grid direction={"column"} gap={5} container justifyContent={"center"} alignItems={"center"}>
                        <Typography variant={"h4"}>Thank you for booking with us.</Typography>
                        <Button variant={"contained"} onClick={() => navigation("/")}>
                            Return to homepage
                        </Button>
                    </Grid>
                </TbcCard>
            </Container>
        </div>
    );
};

export default ThankyouView;
