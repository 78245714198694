import { Container, Paper, useMediaQuery, useTheme } from "@mui/material";
import { HomePageFlightSearchContainer, HomePageFlightSearchHero, HomePageTitle, HomePageSubText } from "./Style";
import FlightSearchForm from "../flights/searchform/FlightSearchForm";
// import Features from "./Features";
import Explore from "./Explore";
import Explainer from "./Explainer";

const FlightSearchTab = () => {
    const theme = useTheme();
    const mobileView = useMediaQuery(theme.breakpoints.down(800));

    return (
        <HomePageFlightSearchContainer>
            <HomePageFlightSearchHero>
                <Container maxWidth={mobileView ? "xs" : "lg"}>
                    <HomePageTitle>Book Flights With Crypto</HomePageTitle>
                    <HomePageSubText>Search thousands of destinations. Pay with your favorite cryptocurrency.</HomePageSubText>
                    <Paper style={{ marginTop: 10 }}>
                        <FlightSearchForm />
                    </Paper>
                </Container>
            </HomePageFlightSearchHero>
            {/* <Features /> */}
            <Explainer/>
            <Explore />
        </HomePageFlightSearchContainer>
    );
};

export default FlightSearchTab;
