import { Grid, Typography, TextField } from "@mui/material";
import React, { FC } from "react";
import TbcCard from "../../../commoncomponents/layout/card/TbcCard";

const PaymentCard: FC = () => {
    return (
        <TbcCard>
            <Grid container justifyContent="center" alignItems="stretch">
                <Grid item xs={12}>
                    <Typography variant="button" color="primary">
                        Payment
                    </Typography>
                </Grid>
                <br />
                <Grid item xs={12} style={{ display: "flex", alignItems: "center" }}>
                    <img src="/images/booking/qrsample.png" alt="QR code" width={70} />
                    <span style={{ marginLeft: 10 }}>
                        This payment1 will expire in 00:29:03. Send the indicated amount to the address below.
                    </span>
                </Grid>
            </Grid>
            <br />
            <TextField fullWidth disabled label="ETH Amount" defaultValue="0.374405" />
            <TextField
                margin="normal"
                fullWidth
                disabled
                label="Wallet Address"
                defaultValue="0xa544284923495u43fkjfj33r43f91ff84hr"
            />
        </TbcCard>
    );
};
export default PaymentCard;
