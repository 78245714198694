import styled from "@emotion/styled";

export const FlightSummaryPrimaryText = styled.div`
    font-size: 18px;
    font-weight: 700;
    color: #555;
`;
export const FlightSummarySecondaryText = styled.div`
    font-size: 14px;
    font-weight: 500;
    color: #888888;
`;
export const FlightSummaryTertiayText = styled.span`
    font-size: 14px;
    font-weight: 500;
    color: #555;
`;
