import { Grid, Typography } from "@mui/material";
import React, { FC } from "react";
import {
    CaptionSubText,
    CaptionText,
    HeadingText,
    InfoSubText,
    InfoText,
    InfoTitleText,
    VerticalTracker,
    VerticaltrackerContainer
} from "../../../commoncomponents/CommonStyle";
import { whiteColor } from "../../../constants/ColorsConstants";
import { convertMinsToHrsMins, convertTimeToAmPm, getFlightLogo } from "../../../util/SiteUtils";
import { FlightConnectContainer, FlightDetailsContainer } from "./Style";
import { AirportsDetailsType, LayoverType, SegmentsType } from "./Type";

type FlightDetailsProps = {
    departureDate: string;
    duration: string;
    flightName: string;
    marketingCarrier: string;
    flightNumber: number;
    flightCode: string;
    reachDate: string;
    segments?: SegmentsType[];
    layovers?: LayoverType[];
    airportsList?: AirportsDetailsType[];
};

const FlightDetails: FC<FlightDetailsProps> = ({
    departureDate,
    duration,
    flightName,
    marketingCarrier,
    flightNumber,
    segments,
    layovers,
    flightCode,
    reachDate,
    airportsList
}) => {
    const getAirportName = (airportCode: string) => {
        if (!airportsList) {
            return "";
        }
        const airportName = airportsList.find((item) => item.iata === airportCode);
        if (airportName) {
            return `${airportName.city.name}, ${airportName.city.country.iata}`;
        }
        return airportCode;
    };

    return (
        <FlightDetailsContainer>
            <hr />
            <HeadingText style={{ padding: 20 }}>
                Departure <CaptionSubText>{departureDate}</CaptionSubText>
            </HeadingText>
            <Grid style={{ padding: 20, borderRadius: 5, background: whiteColor }}>
                <Grid container spacing={2} alignItems={"center"}>
                    <Grid item>
                        <img src={getFlightLogo(flightCode)} style={{ height: 43 }} alt={"logo"} />
                    </Grid>
                    <Grid item>
                        <CaptionText style={{ fontSize: 14 }}>
                            {flightName} {marketingCarrier}-{flightNumber}
                        </CaptionText>
                    </Grid>
                </Grid>
                {segments?.map((segment, index) => (
                    <div key={index}>
                        <Grid container style={{ marginTop: 40 }}>
                            <Grid item xs={1} style={{ display: "flex" }}>
                                <InfoText>{convertMinsToHrsMins(segment.duration)}</InfoText>
                            </Grid>
                            <Grid item xs={11} style={{ display: "flex", paddingLeft: 20 }}>
                                <VerticaltrackerContainer>
                                    <VerticalTracker />
                                </VerticaltrackerContainer>
                                <Grid item>
                                    <Grid
                                        container
                                        style={{ height: "100%" }}
                                        direction={"column"}
                                        gap={4}
                                        justifyContent={"space-between"}>
                                        <Grid item>
                                            <HeadingText>
                                                {convertTimeToAmPm(segment.departureTime)}{" "}
                                                {getAirportName(segments[index].origin)}
                                            </HeadingText>
                                        </Grid>
                                        <Grid item>
                                            <HeadingText>
                                                {convertTimeToAmPm(segment.arrivalTime)}{" "}
                                                {getAirportName(segments[index].destination)}
                                            </HeadingText>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        {index < segments.length - 1 && (
                            <FlightConnectContainer container>
                                <Grid item xs={12}>
                                    {layovers && layovers[index] && (
                                        <Typography style={{ fontSize: "0.9rem", fontWeight: "bold" }}>
                                            {convertMinsToHrsMins(layovers[index].duration)} - Connect in airport
                                        </Typography>
                                    )}
                                </Grid>
                            </FlightConnectContainer>
                        )}
                    </div>
                ))}
            </Grid>
            <Grid container spacing={3} style={{ marginTop: 20 }}>
                <Grid item>
                    <InfoTitleText>
                        Arrives: <InfoSubText>{reachDate}</InfoSubText>
                    </InfoTitleText>
                </Grid>
                <Grid item>
                    <InfoTitleText>
                        Duration: <InfoSubText>{duration}</InfoSubText>
                    </InfoTitleText>
                </Grid>
            </Grid>
        </FlightDetailsContainer>
    );
};

export default FlightDetails;
