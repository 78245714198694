import { Grid, FormControl, InputLabel, Select, MenuItem, Divider } from "@mui/material";
import React, { FC } from "react";
import PropertySearchCard from "./PropertySearchCard";
import { propertySearchData } from "./PropertySearchData";
import { HeadingTypography } from "../../../commoncomponents/CommonStyle";
import Map from "../details/overview/Map";
import { mapSrc } from "./FilterData";
import PropertySearchFilter from "./PropertySearchFilter";

const PropertySearchView: FC = () => {
    return (
        <Grid container spacing={2} sx={{ px: 1 }}>
            <Grid my={2} item xs={12} container md={3}>
                <Grid item>
                    <Map mapSrc={mapSrc} mapWidth="100%" height="143px" />
                    <Divider />
                    <PropertySearchFilter />
                </Grid>
            </Grid>
            <Grid my={2} item xs={12} container md={9} direction="column">
                <Grid p={1} container item justifyContent="space-between">
                    <Grid item>
                        <HeadingTypography variant="subtitle1" sx={{ fontWeight: { md: "bold" } }}>
                            What we are paid impacts our sort order
                        </HeadingTypography>
                    </Grid>
                    <Grid item>
                        <FormControl sx={{ width: { md: "280px" } }} fullWidth>
                            <InputLabel id="sort-by">Sort By</InputLabel>
                            <Select labelId="" id="sort-by" value={"RECOMMENDED"} label="Age">
                                <MenuItem value={"RECOMMENDED"}>Recommended</MenuItem>
                                <MenuItem value={"PRICE_LOW_TO_HIGH"}>Price</MenuItem>
                                <MenuItem value={"PRICE_RELEVANT"}>Price + our choices</MenuItem>
                                <MenuItem value={"BEST_DEAL"}>Deals</MenuItem>
                                <MenuItem value={"REVIEW"}>Guest rating + our choices</MenuItem>
                                <MenuItem value={"DISTANCE"}>Distance from airport</MenuItem>
                                <MenuItem value={"PROPERTY_CLASS"}>Property class</MenuItem>
                                <MenuItem value={"VACATION_RENTAL"}>Holiday rentals</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>

                {propertySearchData.map((propertyData) => {
                    return <PropertySearchCard key={propertyData.id} propertyData={propertyData} />;
                })}
            </Grid>
        </Grid>
    );
};

export default PropertySearchView;
