import { FC } from "react";
import { Grid } from "@mui/material";
// import { ExploreContainer, ExploreTitle } from "./Style";

type ExploreBoxProps = {
    title: string;
    imageUrl: string;
};
const ExploreBox: FC<ExploreBoxProps> = ({ title, imageUrl }) => {
        return (
        <Grid item xs={12} sm={3}>
            <div 
                onClick={() => window.scroll({top: 0, left: 0, behavior: 'smooth' })}
                style={{ 
                    backgroundImage: `url('${imageUrl}')`,
                    backgroundSize: "cover",
                    backgroundPosition: "center center",
                    padding: "140px 10px",
                    borderRadius: "8px",
                    cursor: "pointer"
                }}
            >
                <h3 style={{
                    color: "white",
                    fontSize: "30px"
                }}>{title}</h3>
            </div>
        </Grid>
    );
};

export default ExploreBox;
