import { Grid } from "@mui/material";
import React from "react";
import { CaptionSubText, InfoText } from "../../commoncomponents/CommonStyle";
import TbcCard from "../../commoncomponents/layout/card/TbcCard";
import CarryBag from "../../images/baggage.svg";

const BaggageDimension = () => {
    return (
        <TbcCard title={"Baggage Dimensations"}>
            <Grid container>
                <Grid item xs={8}>
                    <CaptionSubText>
                        <img src={CarryBag} height={20} width={20} />
                        Carry-on baggage
                    </CaptionSubText>
                </Grid>
                <Grid item xs={4}>
                    <InfoText>48x23x34cm, 7kg | 56x23x36cm, 7kg</InfoText>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={8}>
                    <CaptionSubText>
                        <img src={CarryBag} height={20} width={20} />
                        Checked baggage
                    </CaptionSubText>
                </Grid>
                <Grid item xs={4}>
                    <InfoText>48x23x34cm, 7kg | 56x23x36cm, 7kg</InfoText>
                </Grid>
            </Grid>
        </TbcCard>
    );
};

export default BaggageDimension;
