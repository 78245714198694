import { Container, Grid } from "@mui/material";
import ExploreBox from './ExploreBox';
import { Button } from "@mui/material";
import { ExploreContainer, ExploreTitle } from "./Style";
import CancunImage from "../../images/cancun.jpg";
import HawaiiImage from "../../images/hawaii.jpg";
import ParisImage from "../../images/paris.jpg";
import DubaiImage from "../../images/dubai.jpg";

const Explore = () => {
    return (
        <ExploreContainer>
            <ExploreTitle>Ready for an adventure?</ExploreTitle>
            <Container>
                <Grid
                    container 
                    spacing={{ sm: 2 }}
                    rowSpacing={{ xs: 2 }}
                >
                    <ExploreBox title="Cancun" imageUrl={CancunImage}/>
                    <ExploreBox title="Hawaii" imageUrl={HawaiiImage}/>
                    <ExploreBox title="Paris" imageUrl={ParisImage}/>
                    <ExploreBox title="Dubai" imageUrl={DubaiImage}/>
                </Grid>      
            </Container>
            <Button 
                variant="contained" 
                style={{ marginTop: "20px" }}
                onClick={() => window.scroll({top: 0, left: 0, behavior: 'smooth' })}
            >Search Flights</Button>      
        </ExploreContainer>
    );
};

export default Explore;
