import React, { FC } from "react";
import TextField from "@mui/material/TextField";
import AdapterMoment from "@mui/lab/AdapterMoment";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import { DateType } from "./Base";

type CustomDatePickerProps = {
    date: DateType;
    setDate: (date: DateType) => void;
};

const CustomSingleDatePicker: FC<CustomDatePickerProps> = ({ date, setDate }) => {
    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
                label="Check in"
                value={date}
                onChange={(newValue) => {
                    setDate(newValue);
                }}
                showDaysOutsideCurrentMonth
                renderInput={(params) => <TextField size={"small"} fullWidth {...params} />}
            />
        </LocalizationProvider>
    );
};

export default CustomSingleDatePicker;
