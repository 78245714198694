import styled from "@emotion/styled";

export const ListItem = styled.li`
    list-style-type: none;
    margin-bottom: 5px;
    padding-left: 10px;
`;

export const UnorderedList = styled.ul`
    margin-bottom: 2em;
`;
