import { Grid } from "@mui/material";
import React, { FC } from "react";
import { CardContainer } from "./CommonStyle";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

type LoadingShimmerRowsProps = {
    noOfCards: number;
    isLoading: boolean;
};

const LoadingShimmerRows: FC<LoadingShimmerRowsProps> = ({ noOfCards, isLoading, children }) => {
    const loadingArray = [];

    for (let i = 0; i < noOfCards; i++) {
        loadingArray.push(
            <CardContainer key={i}>
                <Grid container justifyContent={"space-between"} alignItems={"center"}>
                    <Grid item xs={2}>
                        <Skeleton circle height={"77px"} width={"50%"} containerClassName="avatar-skeleton" />
                    </Grid>
                    <Grid item xs={4}>
                        <Skeleton count={4} />
                    </Grid>
                    <Grid item xs={2}>
                        <Skeleton count={2} />
                    </Grid>
                    <Grid item xs={2}>
                        <Skeleton count={4} />
                    </Grid>
                </Grid>
            </CardContainer>
        );
    }

    if (isLoading) {
        return (
            <Grid item xs={12}>
                {loadingArray}
            </Grid>
        );
    }

    return <>{children}</>;
};

export default LoadingShimmerRows;
