import React from "react";
import { useNavigate } from "react-router";
import { LinkTitle } from "../../commoncomponents/CommonStyle";
import TbcCard from "../../commoncomponents/layout/card/TbcCard";

const ReservationTerms = () => {
    const navigate = useNavigate();
    return (
        <TbcCard title={"Reservation Terms and Booking Conditions"}>
            <span>By completing this booking form, you agree to the</span>
            <LinkTitle
                onClick={() => {
                    navigate("/terms-of-service");
                }}>
                {" "}
                Terms of Service{" "}
            </LinkTitle>{" "}
            and
            <LinkTitle
                onClick={() => {
                    navigate("/privacy-policy");
                }}>
                Privacy Policy
            </LinkTitle>
        </TbcCard>
    );
};

export default ReservationTerms;
