import { Grid, Typography, Box } from "@mui/material";
import React, { FC } from "react";
import { CardContainer, HeadingTypography } from "../../../commoncomponents/CommonStyle";
import { PropertySearchItem } from "./Types";
import { CardImg, MemberPrice } from "./Styled";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import Carousel from "react-material-ui-carousel";

type PropertySearchCard = {
    propertyData: PropertySearchItem;
};
const PropertySearchCard: FC<PropertySearchCard> = ({ propertyData }) => {
    return (
        <CardContainer>
            <Grid container direction="row" spacing={1}>
                <Grid item xs={12} md={4}>
                    <Carousel autoPlay={false} navButtonsAlwaysVisible indicators={false}>
                        {propertyData.images.map((image) => (
                            <CardImg key={image} src={image} />
                        ))}
                    </Carousel>
                </Grid>
                <Grid item container xs={12} md={8}>
                    <Box>
                        <HeadingTypography variant="h6" sx={{ fontWeight: { md: "bold" } }}>
                            {propertyData.name}
                        </HeadingTypography>
                        <Typography mb={1} variant="body2">
                            {propertyData.distanceInfo}
                        </Typography>
                        {propertyData.description.map((info) => (
                            <Typography key={info} variant="body2">
                                {info}
                            </Typography>
                        ))}
                    </Box>
                    <Grid item container mt={2} justifyContent="space-between">
                        <Grid item>
                            {propertyData.fullyRefundable && (
                                <Typography mb={1} variant="body2" color="green">
                                    Fully refundable
                                </Typography>
                            )}
                            <Typography variant="body1">
                                <strong>{`${propertyData.rating.value}/5 `}</strong>
                                {`${propertyData.rating.description} (${propertyData.rating.reviewsCount} reviews)`}
                            </Typography>
                        </Grid>
                        <Grid item>
                            {propertyData.MemberPrice && (
                                <MemberPrice>
                                    <LocalOfferOutlinedIcon fontSize="small" />
                                    <span>Member Price available</span>
                                </MemberPrice>
                            )}

                            <HeadingTypography variant="h6" sx={{ fontWeight: { md: "bold" } }}>
                                {propertyData.cost.value}
                            </HeadingTypography>
                            <Typography variant="subtitle2">{propertyData.cost.details}</Typography>
                            <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                                {propertyData.cost.includes}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </CardContainer>
    );
};
export default PropertySearchCard;
