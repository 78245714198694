import { Grid, Typography } from "@mui/material";
import { FormikProps } from "formik";
import React, { FC } from "react";
import TbcCard from "../../../commoncomponents/layout/card/TbcCard";
import PhoneInputWrapper from "../../../commoncomponents/PhoneInputWrapper";
import SelectDropdownFormikWrapper from "../../../commoncomponents/SelectDropdownWrapper";
import TextFieldWrapper from "../../../commoncomponents/TextFieldWrapper";
import { TitleOptions, GenderOptions, IssuesByOptions } from "../../../constants/SiteOptions";

type PassengerDetailFormProps<T> = {
    formik: FormikProps<T>;
    title: string;
    isPrimary: boolean;
    formName: string;
};

const PassengerDetailForm: FC<PassengerDetailFormProps<any>> = ({ formik, title, isPrimary, formName }) => {
    return (
        <div>
            <TbcCard title={title}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1" color="primary">
                            Basic details
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <SelectDropdownFormikWrapper
                            formik={formik}
                            name={`${formName}.title`}
                            label="Title"
                            options={TitleOptions}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextFieldWrapper
                            formik={formik}
                            name={`${formName}.firstName`}
                            label="First name"
                            onChange={formik.handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextFieldWrapper
                            formik={formik}
                            name={`${formName}.middleName`}
                            label="Middle name"
                            onChange={formik.handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextFieldWrapper formik={formik} name={`${formName}.lastName`} label="Last name" />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextFieldWrapper
                            formik={formik}
                            name={`${formName}.dob`}
                            label="Date of birth"
                            type="date"
                            InputLabelProps={{
                                shrink: true
                            }}
                            variant="standard"
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <SelectDropdownFormikWrapper
                            formik={formik}
                            name={`${formName}.gender`}
                            label="Gender"
                            options={GenderOptions}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}></Grid>
                    {isPrimary && (
                        <>
                            <Grid item xs={12} md={3}>
                                <PhoneInputWrapper formik={formik} name={`${formName}.phone`} />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextFieldWrapper
                                    formik={formik}
                                    name={`${formName}.email`}
                                    label="E-mail"
                                    variant="standard"
                                />
                            </Grid>
                            <Grid item xs={12} md={6} />

                            <Grid item xs={12} md={6}>
                                <Typography variant="caption" color="tertiary">
                                    <i>Booking confirmation will be sent to this email address.</i>
                                </Typography>
                            </Grid>
                        </>
                    )}
                </Grid>
                <br />
                <br />
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1" color="primary">
                            Passport or ID details
                        </Typography>
                        <Typography variant="caption">
                            Please enter details exactly as they appear on your passport/travel document.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <SelectDropdownFormikWrapper
                            formik={formik}
                            name={`${formName}.issuedBy`}
                            label="Passport Origin"
                            options={IssuesByOptions}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}></Grid>
                </Grid>
                <br />
            </TbcCard>
        </div>
    );
};

export default PassengerDetailForm;
