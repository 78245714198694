import React from "react";
import { FooterContainer, FooterLink } from "./FooterStyle";
import { useNavigate } from "react-router";
import { getCurrentYear } from "../../util/SiteUtils";
const Footer = () => {
    const navigate = useNavigate();

    return (
        <FooterContainer>
            <FooterLink>© {getCurrentYear()} Travel By Crypto</FooterLink>
            <FooterLink>
                <a onClick={() => navigate("/terms-of-service")}>Terms of Service</a>
            </FooterLink>
            <FooterLink>
                <a onClick={() => navigate("/privacy-policy")}>Privacy Policy</a>
            </FooterLink>
        </FooterContainer>
    );
};

export default Footer;
