import { Grid } from "@mui/material";
import React, { FC } from "react";
import { mainBackgroundColor } from "../../../constants/ColorsConstants";

const urlsForFullWidth = [
    "/",
    "/flights",
    "/flights/search",
    "/flights/passengerinfo",
    "/flights/payment",
    "/thankyou"
];

const MainContainer: FC = ({ children }) => {
    return (
        <Grid
            key={window.location.pathname}
            container
            justifyContent="center"
            style={{ background: mainBackgroundColor }}>
            <Grid item xs={12} md={urlsForFullWidth.includes(window.location.pathname) ? 12 : 10}>
                {children}
            </Grid>
        </Grid>
    );
};

export default MainContainer;
