import React, { FC } from "react";
import TextField from "@mui/material/TextField";
import DateRangePicker from "@mui/lab/DateRangePicker";
import AdapterMoment from "@mui/lab/AdapterMoment";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { DateType } from "./Base";
import { DateRange } from "@mui/lab/DateRangePicker/RangeTypes";

type CustomDateRangePickerProps = {
    endDate: DateType;
    startDate: DateType;
    setFromDate: (date: DateType) => void;
    setToDate: (date: DateType) => void;
};

const CustomDateRangePicker: FC<CustomDateRangePickerProps> = ({ endDate, startDate, setFromDate, setToDate }) => {
    const [value, setValue] = React.useState<DateRange<unknown>>([startDate, endDate]);
    const onChange = (newValue: DateRange<unknown>) => {
        setValue(newValue);
        setFromDate(newValue[0] as DateType);
        setToDate(newValue[1] as DateType);
    };
    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <DateRangePicker
                startText="Depart"
                endText="Return"
                value={value}
                onChange={onChange}
                renderInput={(startProps, endProps) => (
                    <>
                        <TextField size={"small"} {...startProps} />
                        <TextField style={{ marginLeft: 5 }} size={"small"} {...endProps} />
                    </>
                )}
            />
        </LocalizationProvider>
    );
};

export default CustomDateRangePicker;
