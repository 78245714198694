import { FormikErrors } from "formik";
import moment from "moment";
import { object, string } from "yup";
import { DateType } from "../../commoncomponents/Base";
import { ADULTS, CHILDREN, INFANTS, RETURN } from "../../constants/SiteConstants";
import { FlightSearchType } from "./Type";

export const flightSearchInitialValue: FlightSearchType = {
    searchType: RETURN,
    from: "",
    to: "",
    fromCode: "",
    toCode: "",
    departureDate: moment(new Date()),
    returnDate: moment().add(7, "days"),
    passengers: {
        flightType: "Economy",
        passengerType: [ADULTS]
    }
};

export const flightSearchValidation = object().shape({
    from: string().required("From is required"),
    to: string().required("To is required"),
    departureDate: string().required("Departure date is required"),
    returnDate: string().required("Return date is required")
});

export const getTotalPassengerLink = (totalChild: number, totalInfant: number) => {
    if (totalChild !== 0 && totalInfant !== 0) {
        return `${CHILDREN}=${totalChild}&${INFANTS}=${totalInfant}&`;
    }
    if (totalChild !== 0) {
        return `${CHILDREN}=${totalChild}&`;
    }
    if (totalInfant !== 0) {
        return `${INFANTS}=${totalInfant}&`;
    }
    return "";
};

export const setFlightFormValues = (
    setterFn: (field: string, value: any) => Promise<FormikErrors<any>> | Promise<void>,
    fieldName: string,
    fieldValue: string | string[] | DateType
): void => {
    setterFn(fieldName, fieldValue);
};
