import { Toolbar, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { CustomAppBar, CustomToolbar, HeaderContainer, HeaderMenuContainer, LogoImage, MenuLinks } from "./HeaderStyle";
import compLogo from "../../images/TBCLogo.png";
import MobileHeader from "./MobileHeader";
import CryptoIcons from "./CryptoIcons";
import { useNavigate } from "react-router";

const Header = () => {
    const theme = useTheme();
    const mobileView = useMediaQuery(theme.breakpoints.down(800));
    const navigate = useNavigate();

    return (
        <>
            <CustomAppBar position="fixed">
                <CustomToolbar>
                    <HeaderContainer>
                        <HeaderMenuContainer>
                            <LogoImage src={compLogo} alt="logo" onClick={() => navigate("/flights")} />
                            {!mobileView && (
                                <HeaderMenuContainer>
                                    <MenuLinks onClick={() => navigate("/flights")}>Flights</MenuLinks>
                                    <CryptoIcons />
                                </HeaderMenuContainer>
                            )}
                        </HeaderMenuContainer>
                        {mobileView && (
                            <HeaderMenuContainer>
                                <MobileHeader />
                            </HeaderMenuContainer>
                        )}
                    </HeaderContainer>
                </CustomToolbar>
            </CustomAppBar>
            <Toolbar />
        </>
    );
};

export default Header;
