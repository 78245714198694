import React, { useEffect, useState } from "react";

interface BooleanState {
    value: boolean;
    setTrue: () => void;
    setFalse: () => void;
    toggle: () => void;
}

export const useBooleanState = (initialValue = false): BooleanState => {
    const [value, setValue] = useState<boolean>(initialValue);
    const setTrue = () => setValue(true);
    const setFalse = () => setValue(false);
    const toggle = () => setValue(!value);
    return { value, toggle, setTrue, setFalse };
};

interface DataState<T> {
    value: T;
    set: (newValue: T) => void;
    reset: () => void;
}

export const useDataState = <T>(initialValue?: T): DataState<T | undefined> => {
    const [value, setValue] = useState<T | undefined>(initialValue);
    const set = (newValue: T | undefined) => setValue(newValue);
    const reset = () => setValue(undefined);
    return { value, set, reset };
};

export const useOnClickOutside = (ref: React.RefObject<HTMLDivElement>, handler: () => void) => {
    useEffect(() => {
        const listener = (event: any) => {
            if (!ref.current || ref.current.contains(event.target)) {
                return;
            }
            handler();
        };
        document.addEventListener("mousedown", listener);
        document.addEventListener("touchstart", listener);
        return () => {
            document.removeEventListener("mousedown", listener);
            document.removeEventListener("touchstart", listener);
        };
    }, [ref, handler]);
};
