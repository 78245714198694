export const Policies_ = [
    {
        id: 1,
        title: "Check-in",
        details: ["Check-in from 3:00 PM - anytime", "Express check-in", "Minimum check-in age - 21"]
    },
    {
        id: 2,
        title: "Check-out",
        details: ["Check-out before 11:00 AM"]
    },
    {
        id: 3,
        title: "Special check-in instructions",
        details: ["Front desk staff will greet guests on arrival"]
    },
    {
        id: 4,
        title: "Access methods",
        details: ["Staffed front desk"]
    },
    {
        id: 5,
        title: "Pets",
        details: ["Pets not allowed (service animals welcome)", "Service animals are exempt from fees"]
    },
    {
        id: 6,
        title: "Children and extra beds",
        details: [
            "Children are welcome",
            "Rollaway/extra beds are available for USD 50.0 per day.",
            "Free cots (infant beds)"
        ]
    },
    {
        id: 7,
        title: "Payment types",
        details: [
            "/images/Payment-types/cards-cc_american_express.svg",
            "/images/Payment-types/cards-cc_diners_club.svg",
            "/images/Payment-types/cards-cc_discover.svg",
            "/images/Payment-types/cards-cc_jcb.svg",
            "/images/Payment-types/cards-cc_master_card.svg",
            "/images/Payment-types/cards-cc_visa.svg"
        ]
    }
];

export const ImportantInformation = [
    {
        id: 1,
        title: "Fees",
        details: [
            "You'll be asked to pay the following charges at the property:",
            ["Deposit: USD 150 per accommodation, per night", "Resort fee: USD 51.02 per accommodation, per night"],
            "The resort fee includes:",
            ["Pool access", "Phone calls", "Additional inclusions"],
            "We have included all charges provided to us by the property."
        ]
    },
    {
        id: 2,
        title: "Optional extras",
        details: [
            "The following fees and deposits are charged by the property at the time of service, check-in or check-out.",
            [
                "Fee for continental breakfast: USD 25 for adults and USD 15 for children (approximately)",
                "Valet parking fee: USD 15 per day",
                "Rollaway bed fee: USD 50.0 per day"
            ],
            "The above list may not be comprehensive. Fees and deposits may not include tax and are subject to change."
        ]
    },
    {
        id: 3,
        title: "You need to know",
        details: [
            "Extra-person charges may apply and vary depending on property policy",
            "Government-issued photo identification and a credit card may be required at check-in for incidental charges",
            "Special requests are subject to availability upon check-in and may incur additional charges; special requests cannot be guaranteed",
            "This property accepts Visa, Mastercard, American Express, Discover, Diners Club, JCB International, Union Pay, debit cards, cash, mobile payments, Apple Pay and Samsung Pay",
            "Safety features at this property include a carbon monoxide detector and a smoke detector"
        ]
    },
    {
        id: 4,
        title: "We should mention",
        details: [
            "Pool access available from 8:00 AM to 8:00 PM",
            "Guests under 18 years old are not allowed in the spa",
            "This property welcomes guests of all sexual orientations and gender identities (LGBTQ friendly)"
        ]
    }
];

export const FAQ = [
    {
        id: 1,
        title: "Does Conrad Las Vegas at Resorts World have a pool?",
        details: [
            "Yes, this property has 7 outdoor pools and a children's pool. Pool access is from 8:00 AM to 8:00 PM."
        ]
    },
    {
        id: 2,
        title: "Is Conrad Las Vegas at Resorts World pet-friendly?",
        details: ["No, only service animals are welcome at the property."]
    },
    {
        id: 3,
        title: "How much is parking at Conrad Las Vegas at Resorts World?",
        details: ["Self-parking is free at this property."]
    },
    {
        id: 4,
        title: "What time is check-in at Conrad Las Vegas at Resorts World?",
        details: ["Check-in is from 3:00 PM - anytime."]
    },
    {
        id: 5,
        title: "What time is check-out at Conrad Las Vegas at Resorts World?",
        details: ["Checkout is at 11:00 AM."]
    },
    {
        id: 6,
        title: "Where is Conrad Las Vegas at Resorts World located?",
        details: [
            "Located in Las Vegas Strip, this luxury hotel is within a 15-minute walk of Fashion Show Mall and Las Vegas Convention Center. AREA15 and T-Mobile Arena are also within 3 mi (5 km). Las Vegas Convention Center Monorail Station is 15 minutes by foot and Westgate Las Vegas Monorail Station is 19 minutes."
        ]
    },
    {
        id: 7,
        title: "Can I cancel my reservation for free at Conrad Las Vegas at Resorts World and receive a full refund?",
        details: [
            "Yes, Conrad Las Vegas at Resorts World offers free cancellation on select room rates, because flexibility matters! Please refer to Conrad Las Vegas at Resorts World cancellation policy on our site for more details about any exclusions or requirements."
        ]
    }
];
