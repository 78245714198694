import { Button, Grid, Typography } from "@mui/material";
import { capitalize } from "lodash";
import React, { FC } from "react";
import { FlightDetailInfoType, FlightSearchResultsType } from "../results/Type";
import { getAirportName } from "../results/Utils";
import SelectedFlightDetail from "./SelectedFlightDetail";
import { TooltipWrapper } from "./Style";

type SelectedFlightStepperProps = {
    selectedDepartureFlight: FlightSearchResultsType;
    selectedArrivalFlight: FlightSearchResultsType | null;
    fromAirport: string;
    changeDepartureFlight: () => void;
    airlineList?: FlightDetailInfoType[];
};

const SelectedFlightStepper: FC<SelectedFlightStepperProps> = ({
    selectedDepartureFlight,
    selectedArrivalFlight,
    fromAirport,
    changeDepartureFlight,
    airlineList
}) => {
    const departureFlightSegment = selectedDepartureFlight.itineraries[0].segments;
    const arrivalFlightSegment = selectedArrivalFlight ? selectedArrivalFlight.itineraries[0].segments : null;

    return (
        <Grid style={{ marginTop: 20 }} container alignItems={"center"} spacing={2}>
            <Grid item>
                <TooltipWrapper
                    title={
                        <SelectedFlightDetail
                            flightDetail={selectedDepartureFlight}
                            flightName={getAirportName(
                                selectedDepartureFlight.validatingCarrierCode || "",
                                airlineList || []
                            )}
                        />
                    }
                    arrow
                    placement={"top"}>
                    <Button
                        variant={"text"}
                        style={{ fontWeight: "bold", fontSize: 16, paddingLeft: 0 }}
                        onClick={changeDepartureFlight}>
                        {departureFlightSegment[0].origin} -{" "}
                        {departureFlightSegment[departureFlightSegment.length - 1].destination}
                    </Button>
                </TooltipWrapper>
            </Grid>
            <Grid item>
                <Typography> {">"} </Typography>
            </Grid>
            <Grid item>
                {arrivalFlightSegment ? (
                    <TooltipWrapper
                        title={
                            <SelectedFlightDetail
                                flightDetail={selectedArrivalFlight}
                                flightName={getAirportName(
                                    selectedArrivalFlight?.validatingCarrierCode || "",
                                    airlineList || []
                                )}
                            />
                        }
                        arrow
                        placement={"top"}>
                        <Button
                            onClick={changeDepartureFlight}
                            variant={"text"}
                            style={{ fontWeight: "bold", fontSize: 16, paddingLeft: 0 }}>
                            {arrivalFlightSegment[0].origin} -{" "}
                            {arrivalFlightSegment[arrivalFlightSegment.length - 1].destination}
                        </Button>
                    </TooltipWrapper>
                ) : (
                    <Typography style={{ fontWeight: "bold" }}>Select return to {capitalize(fromAirport)}</Typography>
                )}
            </Grid>
        </Grid>
    );
};

export default SelectedFlightStepper;
