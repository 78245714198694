export const overallRating: { value: string; description: string; reviewsCount: number } = {
    value: "4.4",
    description: "Excellent",
    reviewsCount: 299
};

export const reviewsSummary: {
    id: number;
    title: string;
    value: number;
}[] = [
    {
        id: 1,
        title: "5 - Excellent",
        value: 170
    },
    {
        id: 2,
        title: "4 - Good",
        value: 89
    },
    {
        id: 3,
        title: "3 - Okay",
        value: 32
    },
    {
        id: 4,
        title: "2 - Poor",
        value: 4
    },
    {
        id: 5,
        title: "1 - Terrible",
        value: 4
    }
];
export const featuresReviews: {
    id: number;
    title: string;
    value: string;
}[] = [
    {
        id: 1,
        title: "Cleanliness",
        value: "4.5"
    },
    {
        id: 2,
        title: "Staff & service",
        value: "4.4"
    },
    {
        id: 3,
        title: "Amenities",
        value: "4.3"
    },
    {
        id: 4,
        title: "Property conditions & facilities",
        value: "4.4"
    }
];

export const reviews: {
    id: number;
    review: string;
    user: string;
    date: string;
    liked: string[];
    title: string;
    comment: string;
    duration: string;
    likesCount: number;
}[] = [
    {
        id: 1,
        review: "5/5 Excellent",
        user: "Whitety",
        date: "7 Nov 2021",
        liked: ["Cleanliness, room comfort"],
        title: "Perfect getaway",
        comment:
            "The staffs were welcoming and it makes our stay very pleasant. Only regret is not to stay longer, wish we had more time to explore the area and enjoy the infinity pool and other facilities.",
        duration: "Stayed 1 night in Oct 2021",
        likesCount: 7
    },
    {
        id: 2,
        review: "4/5 Good",
        user: "Verified traveller",
        date: "7 Nov 2021",
        liked: [],
        title: "",
        comment: "",
        duration: "Stayed 1 night in Oct 2021",
        likesCount: 0
    },
    {
        id: 3,
        review: "4/5 Good",
        user: "Verified traveller",
        date: "5 July 2021",
        liked: ["Cleanliness, room comfort"],
        title: "",
        comment: "",
        duration: "Stayed 1 night in Jul 2021",
        likesCount: 0
    },
    {
        id: 4,
        review: "5/5 Excellent",
        user: "Verified traveller",
        date: "7 Nov 2021",
        liked: ["Cleanliness", "amenities", "property conditions & facilities"],
        title: "Perfect getaway",
        comment:
            "Excellent value and size of rooms, full set of amenities (kitchen, laundry), great for friends and groups. Not so recommended for kids if playground is needed, but proximity to the bay area is excellent for cycling and trekking activities. Close to 7-Eleven. Weekend F&B at Raffles Green is mostly closed, but plenty of food options at the quay areas. Highly recommended for being away from crowds over weekend.",
        duration: "Stayed 1 night in Jul 2021",
        likesCount: 0
    },
    {
        id: 5,
        review: "4/5 Good",
        user: "Iszam",
        date: "5 July 2021",
        liked: ["Cleanliness, room comfort"],
        title: "",
        comment: "",
        duration: "Stayed 1 night in Jul 2021",
        likesCount: 0
    }
];
