import { Grid } from "@mui/material";
import React, { FC } from "react";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import moment from "moment";
import { useBooleanState } from "../../../commoncomponents/CommonHooks";
import {
    CardContainer,
    InfoText,
    HeadingText,
    CaptionText,
    PrimaryButton
} from "../../../commoncomponents/CommonStyle";
import FlightTracker from "../../../commoncomponents/FlightTracker";
import { iconPrimaryColor } from "../../../constants/ColorsConstants";
import FlightDetails from "../../flights/results/FlightDetails";
import { FlightInfoType } from "../../flights/results/Type";

type FlightSummaryCardProps = {
    flightDetails: FlightInfoType;
};

const FlightSummaryCard: FC<FlightSummaryCardProps> = ({ flightDetails }) => {
    const { departurePlace, departureTime, landingPlace, landingTime, flightIcon, totalTravelTime, flightName } =
        flightDetails;
    const showFlightDetails = useBooleanState(false);
    return (
        <CardContainer style={{ padding: 30 }}>
            <Grid container justifyContent={"center"} alignItems={"center"} spacing={3}>
                <Grid item>
                    {flightName !== "" && flightIcon ? (
                        <InfoText>
                            <img src={flightIcon} alt="flight-icon" height={30} width={30} />
                            {flightName}
                        </InfoText>
                    ) : (
                        <InfoText>
                            <FlightTakeoffIcon style={{ color: iconPrimaryColor }} />
                            Multiple airlines
                        </InfoText>
                    )}
                </Grid>
                <Grid item>
                    <Grid container>
                        <Grid item style={{ paddingRight: 20 }}>
                            <HeadingText>{departureTime}</HeadingText>
                            <CaptionText>{departurePlace}</CaptionText>
                        </Grid>
                        <Grid item>
                            <FlightTracker totalTime={1630} totalStops={2} />
                        </Grid>
                        <Grid item style={{ paddingLeft: 20 }}>
                            <HeadingText>{landingTime}</HeadingText>
                            <CaptionText>{landingPlace}</CaptionText>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <PrimaryButton
                        disableRipple
                        fullWidth
                        style={{ marginTop: 5 }}
                        variant={"text"}
                        onClick={() => showFlightDetails.toggle()}>
                        {showFlightDetails.value ? "Hide Details" : "Show Details"}
                    </PrimaryButton>
                </Grid>
            </Grid>
            {showFlightDetails.value && (
                <FlightDetails
                    flightCode={"Quantas"}
                    flightName={flightName}
                    departureDate={moment(new Date()).format("MMM Do YY").toString()}
                    reachDate={moment(new Date()).add(totalTravelTime, "m").format("MMM Do YY").toString()}
                    duration={totalTravelTime}
                    marketingCarrier={"aa"}
                    flightNumber={123}
                />
            )}
        </CardContainer>
    );
};

export default FlightSummaryCard;
