export const getArrayOfItems = (count: number, name: string) => {
    const items = [];
    for (let i = 0; i < count; i++) {
        items.push(name);
    }
    return items;
};

export const convertMinsToHrsMins = (mins: number) => {
    const h = Math.floor(mins / 60);
    const m = mins % 60;
    return `${h}h ${m}m`;
};

export const getCurrentYear = () => {
    const d = new Date();
    const year = d.getFullYear();
    return year;
};

export const convertTimeToAmPm = (time: string) => {
    const convertedTime = new Date(time).toTimeString();
    const timeArr = convertedTime.split(":");
    const hour = parseInt(timeArr[0]);
    const minute = parseInt(timeArr[1]);
    const ampm = hour >= 12 ? "pm" : "am";
    const newHour = hour % 12;
    return `${newHour === 0 ? "12" : newHour}:${minute}${ampm}`;
};

export const get24HourTime = (time: string) => {
    const hours = time.split("T")[1].split(":")[0];
    const mins = time.split("T")[1].split(":")[1];
    return `${hours}:${mins}`;
};

export const getFlightLogo = (flightCode: string) => {
    return `http://www.gstatic.com/flights/airline_logos/70px/${flightCode}.png`;
};
