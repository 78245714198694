import { Divider, Grid } from "@mui/material";
import React, { FC, useMemo, useState } from "react";
import TbcCard from "../../../commoncomponents/layout/card/TbcCard";
import { FlightSummaryPrimaryText } from "../flightsummary/Styled";
import { PassengerCountType } from "../passengerinfo/Type";
import { CurrencyDecimalText, PassengerPriceAmount, PassengerPriceDescription } from "./Styled";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

type BookingPriceCardProps = {
    passengerCount?: PassengerCountType;
    passengerPrice: number;
};

const renderCurrency = (price: string) => {
    const priceArray = price.split(".");
    return (
        <span style={{ fontSize: 24, fontWeight: "bold" }}>
            {priceArray[0]}
            <CurrencyDecimalText>.{priceArray[1].substring(0, 2)}</CurrencyDecimalText>
        </span>
    );
};

const BookingPriceCard: FC<BookingPriceCardProps> = ({ passengerCount, passengerPrice }) => {
    const [isExpanded, setIsExpanded] = useState(true);

    const renderFlightDescription = useMemo(() => {
        return (
            <Grid
                container
                spacing={2}
                justifyContent="center"
                direction={"column"}
                style={{ paddingLeft: 20, marginTop: 20 }}>
                {passengerCount?.adult && (
                    <Grid container direction="row" justifyContent="space-between">
                        <PassengerPriceDescription variant="subtitle1">
                            {passengerCount?.adult.count} x Adult
                        </PassengerPriceDescription>
                        <PassengerPriceAmount variant="subtitle1">
                            {(passengerCount?.adult.count * passengerCount?.adult.perHead).toFixed(2)} USD
                        </PassengerPriceAmount>
                    </Grid>
                )}
                {passengerCount?.child && passengerCount?.child.count !== 0 && (
                    <Grid container direction="row" justifyContent="space-between">
                        <PassengerPriceDescription variant="subtitle1">
                            {passengerCount?.child.count} x Child
                        </PassengerPriceDescription>
                        <PassengerPriceAmount variant="subtitle1">
                            {(passengerCount?.child.count * passengerCount?.child.perHead).toFixed(2)} USD
                        </PassengerPriceAmount>
                    </Grid>
                )}
                {passengerCount?.infant && passengerCount?.infant.count !== 0 && (
                    <Grid container direction="row" justifyContent="space-between">
                        <PassengerPriceDescription variant="subtitle1">
                            {passengerCount?.infant.count} x Infant
                        </PassengerPriceDescription>
                        <PassengerPriceAmount variant="subtitle1">
                            {(passengerCount?.infant.count * passengerCount?.infant.perHead).toFixed(2)} USD
                        </PassengerPriceAmount>
                    </Grid>
                )}
                {/* <Grid container style={{ borderTop: "1px solid grey" }} />
                <Grid container direction="row" justifyContent="space-between">
                    <PassengerPriceDescription variant="subtitle1">Sub Total</PassengerPriceDescription>
                    <PassengerPriceAmount variant="subtitle1">{passengerPrice} USD</PassengerPriceAmount>
                </Grid> */}
                <Grid item xs={12} style={{ paddingLeft: 0 }}>
                    <Divider />
                </Grid>
                <Grid item xs={12} style={{ paddingLeft: 0 }}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        style={{
                            fontWeight: "bold"
                        }}>
                        <Grid item>
                            <span>Total Charge</span>
                        </Grid>
                        <Grid item>
                            <span>{renderCurrency(passengerPrice.toFixed(2))} USD</span>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }, [passengerCount]);

    const renderHeaderSection = useMemo(() => {
        return (
            <Grid
                onClick={() => setIsExpanded((prev) => !prev)}
                style={{ cursor: "pointer" }}
                container
                justifyContent={"space-between"}
                alignItems={"center"}>
                <Grid item>
                    <FlightSummaryPrimaryText>Price Summary</FlightSummaryPrimaryText>
                </Grid>
                <Grid item>{isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</Grid>
            </Grid>
        );
    }, [isExpanded]);

    return (
        <TbcCard>
            <Grid>
                {renderHeaderSection}
                <Divider style={{ margin: "10px 0px" }} />

                {isExpanded && renderFlightDescription}
            </Grid>
        </TbcCard>
    );
};
export default BookingPriceCard;
