import styled from "@emotion/styled";
import { Typography } from "@mui/material";

export const PassengerPriceDescription = styled(Typography)`
    font-size: 16px;
    color: #666;
    font-style: normal;
    margin-top: 12px;
`;

export const PassengerPriceAmount = styled(Typography)`
    font-size: 16px;
    font-weight: bold;
    color: #666;
    font-style: normal;
    margin-top: 12px;
`;

export const TotalAmountContainer = styled(Typography)`
    color: green;
    background: #ebfce8;
    padding: 10px;
    border: 1px solid green;
    font-weight: bold;
`;

export const CurrencyDecimalText = styled.sup`
    font-size: 14px;
    position: relative;
    top: 2px;
`;
