import { Grid, Typography } from "@mui/material";
import React, { FC } from "react";
import TbcCard from "../../../commoncomponents/layout/card/TbcCard";
import { TravelRestrictionMessage } from "./Styled";

const Covid19TravelInfo: FC = () => {
    return (
        <TbcCard title="Important information">
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="h6" color="primary">
                        Covid-19 Travel Notice
                    </Typography>
                    <Typography variant="subtitle1">
                        <li>
                            Prior to your flight departure, please ensure that you (or anyone travelling with you) have
                            not returned a positive Covid-19 result or have been in contact with someone who is
                            suspected of being Covid-19 positive, or if you or anyone travelling with you are
                            experiencing any Covid-19 symptoms such as fever, cough, sore throat, runny nose and/or any
                            other known Covid-19 symptoms. You may be refused from boarding the flight due to the
                            aforesaid circumstances.
                        </li>
                    </Typography>
                    <br />
                    <Typography variant="subtitle1">
                        <li>
                            Please also take note that you are required to <b>fulfil the destination's requirements</b>{" "}
                            (as well as any requirements set by relevant transit points) for entry, which may include a
                            vaccination certificate, Covid-19 test result, or any other relevant travel requirements set
                            by the airline you are flying with or by the relevant authorities of the destination you are
                            flying to (including any transit points you may pass through). We therefore urge you to
                            check the latest requirements set by the authorities at your origin, transit points and
                            destination.
                        </li>
                    </Typography>
                    <br />
                    <Typography variant="subtitle1">
                        <li>
                            <b>Please contact us for further information on the matter.</b> Any cancelation or
                            rescheduling shall be subject to our booking terms and conditions and the applicable
                            airline's booking terms and conditions and cancelation or rescheduling policy.
                        </li>
                    </Typography>
                </Grid>
                <TravelRestrictionMessage>
                    <Typography variant="subtitle2">
                        Please check the COVID-19 travel restrictions for all stops in your itinerary. You will need to
                        complete the following mandatory documentation before your trip as you might not be allowed on
                        board without it. Failure to do so may incur a fee/penalty imposed by the relevant authorities.
                    </Typography>
                </TravelRestrictionMessage>
            </Grid>
            <div style={{ border: "1px solid red", padding: 10, borderRadius: 5 }}>
                <Typography variant="button" color="error">
                    Health warningIMPORTANT: Visa & COVID-19 Travel Restrictions
                </Typography>
                <Typography variant="body1" style={{ marginTop: 10 }}>
                    It is your sole responsibility to ensure that you comply with all applicable{" "}
                    <b>COVID-19 travel restrictions, visa requirements</b> and any other requirements for your travel
                    from the point of departure to the final destination as well as all transit points in between.{" "}
                    <br />
                    <b>We will not be liable for your refusal</b> of entry into any flight, transit point or destination
                    and/or any other incidents, loss, fine, penalties or damages (including direct and/or consequential
                    loss and damage) which result from your failure to comply with such restrictions and/or requirements
                    (set by any government authority, airline and/or applicable commercial party).
                </Typography>
            </div>
        </TbcCard>
    );
};

export default Covid19TravelInfo;
