import { Grid } from "@mui/material";
import { debounce } from "lodash";
import React, { FC, useCallback, useEffect, useState } from "react";
import { AirportListType } from "../component/searchtabs/Type";
import { useBooleanState, useDataState } from "./CommonHooks";
import SearchTextfieldWrapper from "./searchtextfield/SearchTextfieldWrapper";
import { debounceTime } from "../constants/SiteConstants";
import { getSearchedList } from "../component/searchtabs/Action";

type AirportDepartureArrivalViewProps = {
    fromPlace: string;
    toPlace: string;
    setFromPlace: (value: string, code: string) => void;
    setToPlace: (value: string, code: string) => void;
};

const AirportDepartureArrivalView: FC<AirportDepartureArrivalViewProps> = ({
    fromPlace,
    toPlace,
    setFromPlace,
    setToPlace
}) => {
    const fromSearchText = useDataState<string>(fromPlace);
    const [fromSearchedAirports, setFromSearchedAirports] = useState<AirportListType[]>([]);
    const isFromSearchLoading = useBooleanState(false);
    const toSearchText = useDataState<string>(toPlace);
    const [toSearchedAirports, setToSearchedAirports] = useState<AirportListType[]>([]);
    const isToSearchLoading = useBooleanState(false);

    useEffect(() => {
        fromSearchText.set(fromPlace);
        toSearchText.set(toPlace);
    }, [fromPlace, toPlace]);

    const searchFromAirports = useCallback(
        debounce((text) => {
            if (text) {
                isFromSearchLoading.setTrue();
                getSearchedList(text)
                    .then((res: AirportListType[]) => {
                        setFromSearchedAirports(res);
                    })
                    .finally(() => {
                        isFromSearchLoading.setFalse();
                    });
            }
        }, debounceTime),
        []
    );

    const searchToAirports = useCallback(
        debounce((text) => {
            if (text) {
                isToSearchLoading.setTrue();
                getSearchedList(text)
                    .then((res: AirportListType[]) => {
                        setToSearchedAirports(res);
                    })
                    .finally(() => {
                        isToSearchLoading.setFalse();
                    });
            }
        }, debounceTime),
        []
    );

    const handleFromSearchTextChange = (text: any) => {
        fromSearchText.set(text);
        searchFromAirports(text);
    };
    const handleToSearchTextChange = (text: any) => {
        toSearchText.set(text);
        searchToAirports(text);
    };

    return (
        <>
            <Grid item xs={12} md={3}>
                <SearchTextfieldWrapper
                    name={"from"}
                    size={"small"}
                    value={fromSearchText.value}
                    options={fromSearchedAirports || []}
                    onInputChange={handleFromSearchTextChange}
                    onOptionSelect={(text: string, textCode?: string) => {
                        setFromPlace(text, textCode || "");
                    }}
                    isloading={isFromSearchLoading.value}
                    placeholder={"Flying From"}
                    label={"From"}
                />
            </Grid>
            <Grid item xs={12} md={3}>
                <SearchTextfieldWrapper
                    name={"to"}
                    value={toSearchText.value}
                    size={"small"}
                    isloading={isToSearchLoading.value}
                    placeholder={"Flying to"}
                    onInputChange={handleToSearchTextChange}
                    onOptionSelect={(text: string, textCode?: string) => {
                        setToPlace(text, textCode || "");
                    }}
                    options={toSearchedAirports || []}
                    label={"To"}
                />
            </Grid>
        </>
    );
};

export default AirportDepartureArrivalView;
