import { Grid } from "@mui/material";
import React, { FC } from "react";
import {
    CaptionText,
    FlightSummaryCard,
    InfoText,
    PriceText,
    PrimaryButton
} from "../../../commoncomponents/CommonStyle";
import { AirportsDetailsType, ItinerariesType } from "./Type";
import { useBooleanState } from "../../../commoncomponents/CommonHooks";
import FlightDetails from "./FlightDetails";
import moment from "moment";
import { convertMinsToHrsMins, getFlightLogo } from "../../../util/SiteUtils";
import { getTotalTravelTime } from "./Utils";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

type FlightCardDetailProps = {
    flightDetails: ItinerariesType;
    addToSelectedFlight?: () => void;
    isFlightSelected?: boolean;
    totalCost?: number;
    flightName: string;
    airportList: AirportsDetailsType[];
    isReturningFlight?: boolean;
    flightCode: string;
    searchType: string;
};

const FlightCardDetail: FC<FlightCardDetailProps> = ({
    flightDetails,
    totalCost,
    flightName,
    airportList,
    addToSelectedFlight,
    isReturningFlight,
    flightCode,
    searchType
}) => {
    const { duration, layovers, segments } = flightDetails;
    const showFlightDetails = useBooleanState(false);
    const stops = layovers ? layovers.length : 0;
    return (
        <>
            <FlightSummaryCard>
                <Grid container style={{ padding: 30 }} justifyContent={"space-between"} alignItems={"center"}>
                    <Grid item xs={12} md={1}>
                        <img src={getFlightLogo(flightCode)} style={{ height: 43 }} alt={"logo"} />
                    </Grid>
                    <Grid item xs={12} md={2} style={{ marginBottom: 10 }}>
                        <InfoText>{getTotalTravelTime(segments)}</InfoText>
                        <CaptionText>{flightName}</CaptionText>
                    </Grid>
                    <Grid item md={2} style={{ marginBottom: 10 }}>
                        <InfoText>{convertMinsToHrsMins(duration)}</InfoText>
                        <Grid item style={{ paddingRight: 20 }}>
                            <CaptionText>
                                {!isReturningFlight
                                    ? `${segments[0].origin} - ${segments[segments.length - 1].destination}`
                                    : `${segments[segments.length - 1].destination} - ${segments[0].origin}`}
                            </CaptionText>
                        </Grid>
                    </Grid>
                    <Grid item md={2} style={{ marginBottom: 10 }}>
                        <InfoText>{stops === 0 ? "Non Stop" : `${stops} Stop`}</InfoText>
                        <Grid item style={{ paddingRight: 20 }}>
                            <CaptionText>
                                {segments
                                    .map((segment) => segment.destination)
                                    .slice(0, segments.length - 1)
                                    .join(",")}
                            </CaptionText>
                        </Grid>
                    </Grid>
                    <Grid item style={{ marginBottom: 10 }}>
                        <PriceText>
                            <span style={{ fontWeight: "bold", fontSize: 16 }}>{totalCost?.toFixed(2)} USD</span>
                        </PriceText>
                        <CaptionText>{searchType}</CaptionText>
                    </Grid>
                    <Grid item style={{ marginBottom: 10 }}>
                        <PrimaryButton
                            disableRipple
                            fullWidth
                            style={{ marginTop: 5 }}
                            variant={"outlined"}
                            onClick={addToSelectedFlight}>
                            Select flight
                        </PrimaryButton>
                    </Grid>
                    <Grid item style={{ marginBottom: 10 }}>
                        <PrimaryButton
                            disableRipple
                            fullWidth
                            style={{ marginTop: 5 }}
                            variant={"text"}
                            onClick={() => showFlightDetails.toggle()}>
                            {showFlightDetails.value ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </PrimaryButton>
                    </Grid>
                </Grid>
                {showFlightDetails.value && (
                    <FlightDetails
                        airportsList={airportList}
                        segments={segments}
                        layovers={layovers}
                        flightCode={flightCode}
                        flightName={flightName}
                        marketingCarrier={segments[0].marketingCarrier}
                        flightNumber={segments[0].flightNumber}
                        reachDate={moment(new Date(segments[0].arrivalTime)).format("MMM Do YY").toString()}
                        departureDate={moment(new Date(segments[0].departureTime)).format("MMM Do YY").toString()}
                        duration={`${convertMinsToHrsMins(duration)}`}
                    />
                )}
            </FlightSummaryCard>
        </>
    );
};

export default FlightCardDetail;
