import { AllPassengerInfoType, PassengerCountType, PassengerDetailType } from "./Type";
import { string, object } from "yup";
import { Client } from "../../../commoncomponents/Client";
import { getBookingCpnrApiUrl } from "../../../constants/ApiConstants";
import { createRef } from "react";
import { ADULT_TYPE, CHILD_TYPE, INFANT_TYPE } from "../../../constants/SiteConstants";

export const passengerDetailSchema = (totalNumberOfForm: number) => {
    const validationSchema: any = {};
    for (let i = 0; i < totalNumberOfForm; i++) {
        validationSchema[`form${i}`] = object().shape({
            firstName: string().required("Required"),
            lastName: string().required("Required"),
            dob: string().required("Required"),
            gender: string().required("Required"),
            phone: string().required("Mobile Number is required"),
            email:
                i === 0
                    ? string().email("Please enter correct email address").required("Required")
                    : string().email("Please enter correct email address")
        });
    }
    return object().shape(validationSchema);
};

export const getInitialPassengerDetail = (res: AllPassengerInfoType[]): any => {
    const adult = res[0].oneWayPrice.adults;
    const child = res[0].oneWayPrice.children;
    const infant = res[0].oneWayPrice.infants;

    const perPassengerAmountInfo: PassengerCountType = {} as PassengerCountType;

    perPassengerAmountInfo.adult = {
        count: adult,
        perHead: res.map((item) => item.oneWayPrice.perAdult).reduce((a, b) => a + b)
    };
    perPassengerAmountInfo.child = {
        count: child,
        perHead: res.map((item) => item.oneWayPrice.perChild).reduce((a, b) => a + b) || 0
    };
    perPassengerAmountInfo.infant = {
        count: infant,
        perHead: res.map((item) => item.oneWayPrice.perInfant).reduce((a, b) => a + b) || 0
    };

    const initialValue = {
        title: "",
        firstName: "",
        middleName: "",
        lastName: "",
        gender: "",
        phone: "",
        email: "",
        issuedBy: "",
        dob: ""
    };

    const allPassengerDetailArray: Record<string, any> = {};
    for (let i = 0; i < adult + child + infant; i++) {
        allPassengerDetailArray[`form${i}`] = {
            id: Math.random(),
            typeText: i < adult ? "Adult" : i < adult + child ? "Child" : "Infant",
            type: i < adult ? ADULT_TYPE : i < adult + child ? CHILD_TYPE : INFANT_TYPE,
            ...initialValue
        };
    }
    return {
        passengerDetails: allPassengerDetailArray,
        totalNumberOfPassenger: adult + child + infant,
        perPassengerAmountInfo,
        totalAmount: res.map((item) => item.oneWayPrice.total).reduce((a, b) => a + b) || 0
    };
};

const reverseTranformData = (keys: string[], singleTicket: boolean, passengerDetails: PassengerDetailType[]) => {
    return {
        keys: keys,
        single_ticket: singleTicket,
        contactInformation: {
            email: passengerDetails[0].email,
            phoneNumber: passengerDetails[0].phone
        },
        passengers: passengerDetails.map((item) => ({
            title: item.title,
            firstName: item.firstName,
            lastName: item.lastName,
            middleName: item.middleName,
            type: item.type,
            dateOfBirth: item.dob,
            gender: item.gender,
            issuedBy: item.issuedBy
        }))
    };
};

export const bookTickets = (keys: string[], singleTicket: boolean, passengerDetails: PassengerDetailType[]) =>
    Client.getInstance()
        .createData(getBookingCpnrApiUrl, reverseTranformData(keys, singleTicket, passengerDetails))
        .then((res) => Promise.resolve(res))
        .catch((err) => Promise.reject(err));

export const getRefArray = () => {
    const form = [];
    for (let i = 0; i < 50; i++) {
        form.push(createRef());
    }
    return form;
};
