import { Box } from "@mui/system";
import React, { FC } from "react";
import { HeadingTypography } from "../../../commoncomponents/CommonStyle";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { TextField, Typography, FormGroup, FormControlLabel, Checkbox, Button, Divider } from "@mui/material";
import {
    filterBy,
    propertyClass,
    yourBudget,
    guestRating,
    cleaningAndSafetyPractices,
    paymentType,
    propertyType,
    area,
    popularLocations,
    mealPlansAvailable,
    amenities,
    expediaRewards,
    accessibility,
    travellerExperience
} from "./FilterData";
import CheckBoxList from "./CheckBoxList";
import RadioButtonsList from "./RadioButtonsList";

const PropertySearchFilter: FC = () => (
    <>
        <Box my={2}>
            <HeadingTypography variant="h6">Search by property name</HeadingTypography>
            <TextField size="small" margin="dense" label="e.g. Best Western" variant="outlined" />
        </Box>
        <Divider sx={{ my: 1 }} />
        <Box my={2}>
            <HeadingTypography mb={2} variant="h6">
                Filter by
            </HeadingTypography>
            <CheckBoxList heading="Popular filters" data={filterBy} />
        </Box>
        <Box my={2}>
            <Typography>Property class</Typography>
            {propertyClass.map((item) => (
                <Button sx={{ m: 0.5 }} key={item} variant="outlined" endIcon={<FiberManualRecordIcon />}>
                    {item}
                </Button>
            ))}
        </Box>
        <CheckBoxList heading="Your budget" data={yourBudget} />
        <RadioButtonsList heading="Guest rating" data={guestRating} />
        <Box my={2}>
            <Typography my={1}>Cleaning and safety practices</Typography>
            <FormGroup>
                <FormControlLabel control={<Checkbox />} label={cleaningAndSafetyPractices.enhancedCleaning.title} />
                <Typography ml={4} variant="subtitle2">
                    {cleaningAndSafetyPractices.enhancedCleaning.info}
                </Typography>
            </FormGroup>
        </Box>
        <CheckBoxList heading="Payment type" data={paymentType} />
        <CheckBoxList heading="Property type" data={propertyType} />
        <RadioButtonsList heading="Area" data={area} />
        <RadioButtonsList heading="Popular locations" data={popularLocations} />
        <CheckBoxList heading="Meal plans available" data={mealPlansAvailable} />
        <CheckBoxList heading="Amenities" data={amenities} />
        <Box my={2}>
            <Typography my={1}>Expedia Rewards</Typography>
            <FormGroup>
                <FormControlLabel control={<Checkbox />} label={expediaRewards.vipAccess.title} />
                <Typography ml={4} variant="subtitle2">
                    {expediaRewards.vipAccess.info}
                </Typography>
            </FormGroup>
        </Box>
        <CheckBoxList heading="Accessibility" data={accessibility} />
        <Box my={2}>
            <Typography my={1}>Traveller experience</Typography>
            <FormGroup>
                {travellerExperience.map((filter) => (
                    <Box key={filter.id}>
                        <FormControlLabel control={<Checkbox />} label={filter.title} />
                        <Typography ml={4} variant="subtitle2">
                            {filter.info}
                        </Typography>
                    </Box>
                ))}
            </FormGroup>
        </Box>
    </>
);

export default PropertySearchFilter;
