import { Grid, Paper } from "@mui/material";
import React from "react";
import { NumberHeading, SectionText, PointText } from "./styled";

export const TermsOfServicePage = () => {
    return (
        <Grid container justifyContent={"center"}>
            <Grid item xs={12} md={10}>
                <Paper style={{ padding: 40 }}>
                    <h1>Terms and Conditions</h1>
                    <div>
                        <p>These Terms {`&`} Conditions (“Terms”) apply to all users of Travel By Crypto.</p>
                        <p>
                            By using this Website/Site, you agree and acknowledge that you have read all legal documents
                            carefully and you shall be bound by these Terms {`&`} Conditions.
                        </p>
                        <p>
                            References in these Terms to “we” or “us” or “Website/Site” or “company” or “Travel By
                            Crypto” are references to the Travel By Crypto Website and “you” as “subscriber” or “user”
                            “customer” or “visitor” or “client” (including both natural and legal persons) of Travel By
                            Crypto.
                        </p>
                        <p>
                            The term ‘User’, ‘You’ or ‘Your’ refers to you who access the Website and pages provided by
                            Travel By Crypto and/or avail any other service provided by Travel By Crypto.{" "}
                        </p>
                        <p>
                            The term ‘Third Party’ refers to any person, natural or juristic, other than Travel By
                            Crypto and the User.
                        </p>
                        <p>
                            These Terms {`&`} Conditions and the Privacy Policy will be applicable and effective upon
                            your acceptance of the same and will govern the relationship between the user and the
                            Company in connection with the use of this Website and availing services under this Website.
                        </p>
                        <p>
                            {" "}
                            THESE TERMS {`&`} CONDITIONS IS A LEGALLY BINDING DOCUMENT BETWEEN USER AND TRAVEL BY
                            CRYPTO. THESE TERMS {`&`} CONDITIONS WILL BE EFFECTIVE UPON YOUR ACCEPTANCE OF THE SAME
                            (DIRECTLY OR INDIRECTLY IN ELECTRONIC FORM OR BY MEANS OF AN ELECTRONIC RECORD) AND WILL
                            GOVERN THE RELATIONSHIP BETWEEN USER AND TRAVEL BY CRYPTO FOR THE USE OF THE WEBSITE
                            (DEFINED BELOW).
                        </p>
                        <p>
                            PLEASE READ THESE TERMS {`&`} CONDITIONS CAREFULLY BEFORE USING OR REGISTERING ON THE
                            WEBSITE OR ACCESSING ANY MATERIAL, INFORMATION OR SERVICES THROUGH THE WEBSITE. IF YOU DO
                            NOT AGREE WITH THESE TERMS {`&`} CONDITIONS, PLEASE DO NOT USE THE WEBSITE.
                        </p>
                        <p>
                            Any new features or tools which are added to the current store shall also be subject to the
                            Terms and Conditions. You can review the most current version of the Terms and Conditions at
                            any time on this page. We reserve the right to update, change or replace any part of these
                            Terms and Conditions by posting updates and/or changes to our website. It is your
                            responsibility to check this page periodically for changes. Your continued use of or access
                            to the website following the posting of any changes constitutes acceptance of those changes.
                        </p>
                    </div>
                    <div>
                        <ol type="1">
                            <NumberHeading>
                                General
                                <SectionText type="a">
                                    <PointText>
                                        Sky Pay Group is a company incorporated under the laws of Australia, with its
                                        registered office at 100 Harris St, Pyrmont NSW 2009.
                                    </PointText>
                                    <PointText>
                                        These Terms {`&`} Conditions are subject to revision by Travel By Crypto at any
                                        time and hence the Users are requested to carefully read these Terms {`&`}
                                        Conditions from time to time before using the Website. The revised Terms {`&`}
                                        Conditions shall be made available on the Website. You are requested to
                                        regularly visit the Website to view the most current Terms {`&`} Conditions. In
                                        the event such a facility is provided on the Website, You can determine when
                                        Travel By Crypto last modified any part of the Agreement by referring to the
                                        "Last Updated" legend provided in that document. It shall be Your responsibility
                                        to check these Terms {`&`} Conditions periodically for changes. Travel By Crypto
                                        may require You to provide Your direct or indirect consent to any update in a
                                        specified manner before further use of the Website and the Services. If no such
                                        separate consent is sought, Your continued use of the Website and/or Services,
                                        following such changes, will constitute Your acceptance of those changes.
                                    </PointText>
                                </SectionText>
                            </NumberHeading>
                            <NumberHeading>
                                Services
                                <SectionText type="a">
                                    <PointText>
                                        The Website is an electronic platform which allows the Users to book flights,
                                        hotels, hostels, rooms, suites, apartments, B{`&`}Bs, cottages and resorts.
                                    </PointText>
                                    <PointText>
                                        The Website provides the following services
                                        <SectionText type="i">
                                            <PointText>
                                                The Website displays information regarding pricing, availability,
                                                offers, payment methods in order to facilitate the User to avail the
                                                Services. iii.
                                            </PointText>
                                            <PointText>
                                                The Website facilitates the establishment of a legal relationship
                                                between the User and the Final Service Provider i.e. Airlines, Carriers,
                                                Hotel Providers, Room Providers and other Final Service Providers.
                                            </PointText>
                                            <PointText>
                                                The Website provides other ancillary services related to booking and
                                                travelling.
                                            </PointText>
                                        </SectionText>
                                    </PointText>
                                    <PointText>
                                        Notwithstanding, anything given in this document or any other document, the
                                        Website is only an intermediary between the User and the Final Service Provider.
                                        The Website is not responsible regarding the quality or availability of services
                                        provided by the Final Service Provider. Furthermore, the Website does not
                                        endorse or promotes any specific Service or Product.
                                    </PointText>
                                    <PointText>
                                        The services are provided on an "as is" and "as available" basis. Travel By
                                        Crypto may change the features or functionality of the services being provided
                                        at any time, in its sole discretion, without any prior notice. Travel By Crypto
                                        expressly disclaims all warranties of any kind, whether express or implied,
                                        including, but not limited to the implied warranties of merchantability,
                                        reasonably fit for all purposes. No advice or information, whether oral or
                                        written, which the User obtains from Travel By Crypto or through the services
                                        opted shall create any warranty not expressly made herein or in the terms and
                                        conditions of the services.
                                    </PointText>
                                </SectionText>
                            </NumberHeading>
                            <NumberHeading>
                                Booking Conditions
                                <SectionText type="a">
                                    <PointText>
                                        Accommodation Bookings
                                        <SectionText type="i">
                                            <PointText>
                                                Travel By Crypto only acts as a facilitator and provides a platform for
                                                Users to view, select and book accommodations.
                                            </PointText>
                                            <PointText>
                                                All information pertaining to the Accommodation including but not
                                                limited to, pricing, images, room types, availability, offers and
                                                facilities are provided by the hotel to Travel By Crypto. This
                                                information is for reference only. Any discrepancy that may exist
                                                between the website pictures and actual settings of the hotel shall be
                                                raised by the User with the hotel directly, and shall be resolved
                                                between the User and Hotel.
                                            </PointText>
                                            <PointText>
                                                Hotels reserves the sole right of admission and Travel By Crypto has no
                                                say whatsoever in admission or denial for admission by the hotel.
                                                Unmarried or unrelated couples may not be allowed to check-in by some
                                                hotels as per their policies. Similarly, accommodation may be denied to
                                                guests posing as a couple if suitable proof of identification is not
                                                presented at the time check-in. Some hotels may also not allow local
                                                residents to check-in as guests. Travel By Crypto will not be
                                                responsible for any check-in denied by the hotel due to the aforesaid
                                                reasons or any other reason not under the control of Travel By Crypto.
                                                No refund would be applicable in case the hotel denies check-in under
                                                such circumstances.
                                            </PointText>
                                        </SectionText>
                                    </PointText>
                                    <PointText>
                                        Flight Bookings
                                        <SectionText type="i">
                                            <PointText>
                                                The airline tickets available through the Website are subject to the
                                                Terms and Conditions of the concerned airline, including but not limited
                                                to cancellation and refund policies.
                                            </PointText>
                                            <PointText>
                                                Travel By Crypto merely acts as a facilitator to enable the User to book
                                                a flight ticket. The contract of service for utilization of the flight
                                                is always between the User and the concerned airline.
                                            </PointText>
                                            <PointText>
                                                The total price displayed on the Website on the payment page usually
                                                includes base fare, applicable government taxes and convenience fee.
                                                Users are required to pay the entire amount prior to the confirmation of
                                                their booking(s). In the event the User does not pay the entire amount,
                                                Travel By Crypto reserves its right to cancel the booking. User agrees
                                                to pay all statutory taxes, surcharges and fees, as applicable on the
                                                date of travel.
                                            </PointText>
                                        </SectionText>
                                    </PointText>
                                </SectionText>
                            </NumberHeading>
                            <NumberHeading>
                                Eligibility to Use
                                <SectionText type="a">
                                    <PointText>
                                        The Services are not available to minors under the age of eighteen (18) or to
                                        any Users suspended or removed from the Travel By Crypto system by Travel By
                                        Crypto for any reason whatsoever. If You are disqualified as per the preceding
                                        sentence, You shall not be permitted to avail of the Services or use the
                                        Website. You represent that You are of legal age to form a binding contract and
                                        are not a person barred from receiving the Services under the laws as applicable
                                        in Australia. Notwithstanding the foregoing, if You are below the age of
                                        eighteen (18) years, please read through these Terms & Conditions with your
                                        parent or legal guardian, and in such a case these Terms & Conditions shall be
                                        deemed to be a contract between Travel By Crypto and Your legal guardian or
                                        parent and to the extent permissible under applicable laws, enforceable against
                                        You.
                                    </PointText>
                                    <PointText>
                                        Travel By Crypto reserves the right to refuse access to use the Services offered
                                        at the Website to new Users or to terminate access granted to existing Users at
                                        any time without according any reasons for doing so.
                                    </PointText>
                                    <PointText>
                                        You shall not have more than one active Account (defined hereunder) on the
                                        Website. Additionally, You are prohibited from selling, trading, or otherwise
                                        transferring Your Account to another person.
                                    </PointText>
                                </SectionText>
                            </NumberHeading>
                            <NumberHeading>
                                User Account, Password and Security
                                <SectionText type="a">
                                    <PointText>
                                        You may access and use the Website and the Services either as a registered user
                                        or as a guest user. However, not all sections of the Website and Services will
                                        be accessible to guest users.
                                    </PointText>
                                    <PointText>
                                        Registered users: Travel By Crypto makes certain sections of the Services
                                        available to You through the Website only if You have provided Travel By Crypto
                                        certain required User information and created an account through certain log-in
                                        ID and password ("Account").
                                    </PointText>
                                    <PointText>
                                        In the event You register as a User by creating an Account in order to avail of
                                        the Services provided by the Website, You will be responsible for maintaining
                                        the confidentiality and security of the Account Information, and are fully
                                        responsible for all activities that occur under Your Account. You agree to{" "}
                                        <SectionText type="i">
                                            <PointText>
                                                immediately notify Travel By Crypto of any unauthorized use of Your
                                                Account Information or any other breach of security, and{" "}
                                            </PointText>
                                            <PointText>
                                                ensure that You exit from Your Account at the end of each session.
                                                Travel By Crypto cannot and will not be liable for any loss or damage
                                                arising from Your failure to comply with this section. You may be held
                                                liable for losses incurred by Travel By Crypto or any other user of or
                                                visitor to the Website due to authorized or unauthorized use of Your
                                                Account as a result of Your failure in keeping Your Account Information
                                                secure and confidential.
                                            </PointText>
                                        </SectionText>
                                    </PointText>
                                </SectionText>
                            </NumberHeading>
                            <NumberHeading>
                                Payment
                                <SectionText type="a">
                                    <PointText>
                                        The price of the product will be listed on our website and the estimated total
                                        will be provided when the customer will place the order;
                                    </PointText>
                                    <PointText>
                                        The Site reserves the right to vary the price of any product without any prior
                                        notice
                                    </PointText>
                                    <PointText>
                                        Our website offers following modes of payment:
                                        <SectionText type="i">
                                            <PointText>Valid credit cards/Debit cards</PointText>
                                            <PointText>Cryptocurrency</PointText>
                                        </SectionText>
                                    </PointText>
                                    <PointText>
                                        Any booking made by paying through Cryptocurrency will only be confirmed once
                                        transaction is authenticated and confirmed on the relevant blockchain. Bookings
                                        made through Cryptocurrency may be subject to Miner fees, which are receivable
                                        by miners (or nodes) that authenticate and confirm the transaction on
                                        blockchain. The miner fees may vary depending on network conditions the miner
                                        fee selected by you at the time of the cryptocurrency transaction.
                                    </PointText>
                                    <PointText>
                                        The Users can choose either of the options as per its own choice and
                                        convenience;
                                    </PointText>
                                    <PointText>
                                        Users expressly agree and acknowledge that our website may employ or collaborate
                                        with third party payment gateways using secure connections in order to
                                        facilitate, distribute, transact and receive payments for the Services offered
                                        and received on or through our website.
                                    </PointText>
                                    <PointText>
                                        Our website reserves the right to change or replace the payment gateway at its
                                        sole discretion without any reservation whatsoever. Our website may store and
                                        process card and bank information necessary to collect payments from Users. All
                                        transactions are completed through third party payment gateways and at no point
                                        of time our website assumes any liability for any loss of data or wrongful
                                        payment or invalid payment processing by such a third party.
                                    </PointText>
                                    <PointText>
                                        Users agree that they will hold our website harmless against any such dispute or
                                        legal claim. We shall not be responsible for delays or erroneous transaction
                                        execution or due to payment issues.
                                    </PointText>
                                    <PointText>
                                        We take utmost care to work with 3rd party payment providers, but do not control
                                        their systems, processes, technology and work flows, hence cannot be held
                                        responsible for any fault at the end of payment provider. For more information
                                        on their security and privacy policy you may refer to the Privacy Policy of
                                        these third party payment providers before proceeding with payment option.
                                    </PointText>
                                </SectionText>
                            </NumberHeading>
                            <NumberHeading>
                                Warranties By Users
                                <SectionText type="a">
                                    <PointText>
                                        Users warrant as follows:-
                                        <SectionText type="i">
                                            <PointText>
                                                You shall be responsible to understand and comprehend the terms and
                                                conditions associated with any booking or avail of Services.
                                            </PointText>
                                            <PointText>
                                                You shall be responsible to make sure that you have all the documents,
                                                identity documents, passport, visas, permits, authorizations and any
                                                other document in order to avail the Services.
                                            </PointText>
                                            <PointText>
                                                You will provide authentic and true information in all instances where
                                                such information is requested of you. We reserve the right to confirm
                                                and validate the information and other details provided by you at any
                                                point of time. If upon confirmation your details are found not to be
                                                true (wholly or partly), we have the right in our sole discretion to
                                                reject the booking and debar you from using the Services of our website
                                                without prior intimation whatsoever.
                                            </PointText>
                                            <PointText>
                                                That you are accessing the services available on this Site and
                                                transacting at your sole risk and are using your best and prudent
                                                judgment before entering into any transaction through this Site.
                                            </PointText>
                                            <PointText>
                                                You shall at all times ensure full compliance with various laws
                                                regarding your use of our services.
                                            </PointText>
                                        </SectionText>
                                    </PointText>
                                </SectionText>
                            </NumberHeading>
                            <NumberHeading>
                                Refunds, Cancellations and Changes
                                <SectionText type="a">
                                    <PointText>
                                        Changes
                                        <SectionText type="i">
                                            <PointText>
                                                Any changes that are made to any existing booking shall be subject to
                                                certain charges levied by the respective airline, apart from the service
                                                fee charged by Travel By Crypto.
                                            </PointText>
                                            <PointText>
                                                The User shall be obligated to pay applicable charges in the event of
                                                any alteration or modification to an existing booking. However,
                                                depending on the Final Service Provider’s policy, charges for changes or
                                                modifications to existing bookings may vary.
                                            </PointText>
                                        </SectionText>
                                    </PointText>
                                    <PointText>
                                        Cancellation
                                        <SectionText type="i">
                                            <PointText>
                                                If your Booking is cancelled by the Final Service Provider, we will
                                                notify you of the cancellation as soon as possible. Please note that we
                                                are not liable for the consequences of any cancellations or changes made
                                                by these third parties.
                                            </PointText>
                                        </SectionText>
                                    </PointText>
                                    <PointText>
                                        Refunds
                                        <SectionText type="i">
                                            <PointText>
                                                Refunds will be processed as per the Final Service Provider’s rules and
                                                cancellation policy. Such refunds shall be subject to Travel By Crypto
                                                receiving the amount from the Final Service Provider minus an
                                                administrative fee.{" "}
                                            </PointText>
                                            <PointText>
                                                The refund will be credited to the same account from which the payment
                                                was made minus an administrative fee.
                                            </PointText>
                                        </SectionText>
                                    </PointText>
                                </SectionText>
                            </NumberHeading>
                            <NumberHeading>
                                Misuse Of The Website
                                <SectionText type="a">
                                    <PointText>
                                        You are prohibited from using the Site to post or transmit any material which is
                                        or may be infringing, threatening, false, misleading, inflammatory, libelous,
                                        invasive of privacy, obscene, pornographic, abusive, discriminating, illegal or
                                        any material that could constitute or encourage conduct that would be considered
                                        a criminal offence, violate the rights of any party or which may otherwise give
                                        rise to civil liability or violate any law. You are also prohibited from using
                                        the Site to advertise or perform any commercial solicitation
                                    </PointText>
                                    <PointText>
                                        You shall not create liability for us or cause us to lose (in whole or in part)
                                        the services of our internet service provider ("ISPs") or other suppliers;
                                    </PointText>
                                    <PointText>
                                        You shall not use any "deep-link", "page-scrape", "robot", "spider" or other
                                        automatic device, program, algorithm or methodology, or any similar or
                                        equivalent manual process, to access, acquire, copy or monitor any portion of
                                        the website or any Content, or in any way reproduce or circumvent the
                                        navigational structure or presentation of the website or any Content, to obtain
                                        or attempt to obtain any materials, documents or information through any means
                                        not purposely made available through the website. We reserve our right to bar
                                        any such activity.
                                    </PointText>
                                    <PointText>
                                        You shall not attempt to gain unauthorized access to any portion or feature of
                                        the website, or any other systems or networks connected to the website or to any
                                        server, computer, network, or to any of the services offered on or through the
                                        website, by hacking, password "mining" or any other illegitimate means.
                                    </PointText>
                                    <PointText>
                                        You shall not probe, scan or test the vulnerability of the website or any
                                        network connected to the website nor breach the security or authentication
                                        measures on the website or any network connected to the website. You may not
                                        reverse look-up, trace or seek to trace any information of any other User or
                                        visitor to website, or any other customer, including any account on the website
                                        not owned by You, to its source, or exploit the website or any service or
                                        information made available or offered by or through the website, in any way
                                        where the purpose is to reveal any information, including but not limited to
                                        personal identification or information, other than Your own information, as
                                        provided for by the website.
                                    </PointText>
                                    <PointText>
                                        You shall not make any negative, denigrating or defamatory statement(s) or
                                        comment(s) about Us or the brand name or domain name used by Us or otherwise
                                        engage in any conduct or action that might tarnish the image or reputation, of
                                        our website or otherwise tarnish or dilute any of our trade or service marks,
                                        trade name and/or goodwill associated with such trade or service marks, trade
                                        name as may be owned or used by us. You agree that you will not take any action
                                        that imposes an unreasonable or disproportionately large load on the
                                        infrastructure of the website or our systems or networks, or any systems or
                                        networks connected to us.
                                    </PointText>
                                    <PointText>
                                        You agree not to use any device, software or routine to interfere or attempt to
                                        interfere with the proper working of the website or any transaction being
                                        conducted on the website, or with any other person's use of the website.
                                    </PointText>
                                    <PointText>
                                        You may not forge headers or otherwise manipulate identifiers in order to
                                        disguise the origin of any message or transmittal you send to us on or through
                                        the website or any service offered on or through the website. You may not
                                        pretend that you are, or that you represent, someone else, or impersonate any
                                        other individual or entity.
                                    </PointText>
                                    <PointText>
                                        You may not use the website or any content for any purpose that is unlawful or
                                        prohibited by these Terms & Conditions, or to solicit the performance of any
                                        illegal activity or other activity which infringes the rights of our website and
                                        / or others.
                                    </PointText>
                                    <PointText>
                                        You shall solely enable us to use the information (such as comments, questions,
                                        messages etc.) you supply us with, so that we are not violating any rights you
                                        might have in your Information, you agree to grant Us a non-exclusive,
                                        worldwide, perpetual, irrevocable, royalty-free, sub-licensable (through
                                        multiple tiers) right to exercise the copyright, publicity, database rights or
                                        any other rights you have in your Information, in any media now known or not
                                        currently known, with respect to your Information. We will only use your
                                        information in accordance with the Terms & Conditions and Privacy Policy
                                        applicable to use of the website.
                                    </PointText>
                                    <PointText>
                                        We reserve the right, but have no obligation, to monitor the materials posted on
                                        the website. Our website shall have the right to remove or edit any content that
                                        in its sole discretion violates, or is alleged to violate, any applicable law or
                                        either the spirit or letter of these Terms & Conditions. Notwithstanding this
                                        right, you remain solely responsible for the content of the materials you post
                                        on the website and in your private messages. Please be advised that such Content
                                        posted does not necessarily reflect our views. In no event shall our website
                                        assume or have any responsibility or liability for any Content posted or for any
                                        claims, damages or losses resulting from use of Content and/or appearance of
                                        Content on the website. You hereby represent and warrant that you have all
                                        necessary rights in and to all Content which you provide and all information it
                                        contains and that such Content shall not infringe any proprietary or other
                                        rights of third parties or contain any libelous, tortuous, or otherwise unlawful
                                        information.
                                    </PointText>
                                </SectionText>
                            </NumberHeading>
                            <NumberHeading>
                                Intellectual Property Rights
                                <SectionText type="a">
                                    <PointText>
                                        The Website and the processes, and their selection and arrangement, including
                                        but not limited to all text, graphics, user interfaces, visual interfaces,
                                        sounds and music (if any), artwork and computer code (collectively, the
                                        "Content") on the Website is owned and controlled by Travel By Crypto or its
                                        licensors and the design, structure, selection, coordination, expression, look
                                        and feel and arrangement of such Content is protected by copyright, patent and
                                        trademark laws, and various other intellectual property rights. Through Your use
                                        of the Website, by no means are any rights impliedly or expressly granted to You
                                        in respect of such Content. Travel By Crypto reserves the right to change or
                                        modify the Content from time to time at its sole discretion.
                                    </PointText>
                                    <PointText>
                                        The trademarks, logos and service marks displayed on the Website ("Marks") are
                                        the property of Travel By Crypto or their vendors/seller or respective third
                                        parties. You are not permitted to use the Marks without the prior consent of
                                        Travel By Crypto, the vendor/seller or the third party that may own the Marks.
                                    </PointText>
                                    <PointText>
                                        Unless otherwise indicated or anything contained to the contrary or any
                                        proprietary material owned by a third party and so expressly mentioned, Travel
                                        By Crypto owns all intellectual property rights to and into the trademark
                                        "Travel By Crypto", and the Website, including, without limitation, any and all
                                        rights, title and interest in and to copyright, related rights, patents, utility
                                        models, designs, know-how, trade secrets and inventions (patent pending),
                                        goodwill, source code, meta tags, databases, text, content, graphics, icons, and
                                        hyperlinks.
                                    </PointText>
                                    <PointText>
                                        Except as expressly provided herein, You acknowledge and agree that You shall
                                        not copy, republish, post, display, translate, transmit, reproduce or distribute
                                        any Content through any medium without obtaining the necessary authorization
                                        from Travel By Crypto or thirty party owner of such Content.
                                    </PointText>
                                </SectionText>
                            </NumberHeading>
                            <NumberHeading>
                                Disclaimer of Warranties and Liability
                                <SectionText type="a">
                                    <PointText>
                                        The website, services, content, and any third party content are provided by
                                        Travel By Crypto on an "as is" basis without warranty of any kind, express,
                                        implied, statutory or otherwise, including the implied warranties of title,
                                        non-infringement, merchantability or fitness for a particular purpose. Without
                                        limiting the foregoing, Travel By Crypto makes no warranty that (i) the website
                                        or the services will meet your requirements or your use of the website or the
                                        services will be uninterrupted, timely, secure or error-free; (ii) the results
                                        that may be obtained from the use of the website or services will be effective,
                                        accurate or reliable; (iii) the quality of the website or services will meet
                                        your expectations; or that (iv) any errors or defects in the website or services
                                        will be corrected. No advice or information, whether oral or written, obtained
                                        by you from Travel By Crypto or through the website / content or from use of the
                                        services shall create any warranty not expressly stated in these terms &
                                        conditions.
                                    </PointText>
                                    <PointText>
                                        To the maximum extent permitted by applicable law, Travel By Crypto will have no
                                        liability related to user content and / or third party content arising under
                                        intellectual property rights, libel, privacy, publicity, obscenity or other
                                        laws. Travel By Crypto also disclaims all liability with respect to the misuse,
                                        loss, modification or unavailability of any user content and / or third party
                                        content.
                                    </PointText>
                                    <PointText>
                                        You expressly understand and agree that, to the maximum extent permitted by
                                        applicable law Travel By Crypto will not be liable for any loss that you may
                                        incur as a consequence of unauthorized use of your account or account
                                        information in connection with the website or any services, either with or
                                        without your knowledge. Travel By Crypto has endeavoured to ensure that all the
                                        information on the website is correct, but Travel By Crypto neither warrants nor
                                        makes any representations regarding the quality, accuracy or completeness of any
                                        data, information, product or service. Travel By Crypto shall not be responsible
                                        for the delay or inability to use the website or related functionalities, the
                                        provision of or failure to provide functionalities, or for any information,
                                        software, products, functionalities and related graphics obtained through the
                                        website, or otherwise arising out of the use of the website, whether based on
                                        contract, tort, negligence, strict liability or otherwise. Further, Travel By
                                        Crypto shall not be held responsible for non-availability of the website during
                                        periodic maintenance operations or any unplanned suspension of access to the
                                        website that may occur due to technical reasons or for any reason beyond Travel
                                        By Crypto 's control. The user understands and agrees that any material or data
                                        downloaded or otherwise obtained through the website is done entirely at their
                                        own discretion and risk and they will be solely responsible for any damage to
                                        their computer systems or loss of data that results from the download of such
                                        material or data. Travel By Crypto is not responsible for any typographical
                                        error leading to an invalid coupon. Travel By Crypto accepts no liability for
                                        any errors or omissions, with respect to any information provided to you whether
                                        on behalf of itself or third parties.
                                    </PointText>
                                </SectionText>
                            </NumberHeading>
                            <NumberHeading>
                                Term
                                <SectionText type="a">
                                    <PointText>
                                        The Agreement will continue to apply until terminated by either You or Travel By
                                        Crypto as set forth below. If You want to terminate Your agreement with Travel
                                        By Crypto, You may do so by (i) not accessing the Website; or (ii) closing Your
                                        Account for all of the Services that You use, where Travel By Crypto has made
                                        this option available to You.
                                    </PointText>
                                    <PointText>
                                        You agree that Travel By Crypto may, in its sole discretion and without prior
                                        notice, terminate Your access to the Website and block Your future access to the
                                        Website if Travel By Crypto determines that You have violated the terms of these
                                        Terms & Conditions or any other Agreement(s). You also agree that any violation
                                        by You of the Agreement(s) will cause irreparable harm to Travel By Crypto, for
                                        which monetary damages may be inadequate, and You consent to Travel By Crypto
                                        obtaining any injunctive or equitable relief that Travel By Crypto deems
                                        necessary or appropriate in such circumstances. These remedies are in addition
                                        to any other remedies Travel By Crypto may have at law or in equity.
                                    </PointText>
                                    <PointText>
                                        In addition to Clause above, Travel By Crypto may, at any time, with or without
                                        notice, terminate these Terms & Conditions (or portion thereof, such as any
                                        individual Additional Terms) with You if:
                                        <SectionText type="i">
                                            <PointText>
                                                Travel By Crypto is required to do so by law (for example, where the
                                                provision of the Services to You is, or becomes, unlawful), or upon
                                                request by any law enforcement or other government agencies;
                                            </PointText>
                                            <PointText>
                                                The provision of the Services to You by Travel By Crypto is, in Travel
                                                By Crypto's opinion, no longer commercially viable;
                                            </PointText>
                                            <PointText>
                                                Travel By Crypto has elected to discontinue, with or without reason,
                                                access to the Website, the Services (or any part thereof); or
                                            </PointText>
                                            <PointText>
                                                In the event Travel By Crypto faces any unexpected technical issues or
                                                problems that prevent the Website and / or Services from working.
                                            </PointText>
                                        </SectionText>
                                    </PointText>
                                    <PointText>
                                        You agree that all terminations shall be made in Travel By Crypto's sole
                                        discretion and that Travel By Crypto shall not be liable to You or any third
                                        party for any termination of Your Account (and accompanying deletion of Your
                                        Account Information), or Your access to the Website and Services.
                                    </PointText>
                                </SectionText>
                            </NumberHeading>
                            <NumberHeading>
                                Governing Law and Jurisdiction
                                <SectionText type="a">
                                    <PointText>
                                        These Terms & Conditions and all transactions entered into on or through the
                                        Website and the relationship between You and Travel By Crypto shall be governed
                                        in accordance with the laws of New South Wales, Australia without reference to
                                        conflict of laws principles.
                                    </PointText>
                                </SectionText>
                            </NumberHeading>
                            <NumberHeading>
                                General Provisions
                                <SectionText type="a">
                                    <PointText>
                                        Notice: All notices with respect to these Terms & Conditions from Travel By
                                        Crypto will be served to You by email or by general notification on the Website.
                                        Any notice provided to Travel By Crypto pursuant to these Terms & Conditions
                                        should be sent to support@travelbycrypto.com{" "}
                                    </PointText>
                                    <PointText>
                                        Assignment: You cannot assign or otherwise transfer the Agreements, or any
                                        rights granted hereunder or any obligations, to any third party and any such
                                        assignment or transfer or purported assignment or transfer shall be void ab
                                        initio. Travel By Crypto's rights and/or obligations under the Agreement are
                                        freely assignable or otherwise transferable by Travel By Crypto to any third
                                        parties without the requirement of seeking Your prior consent. Travel By Crypto
                                        may inform You of such assignment or transfer in accordance with the notice
                                        requirements under the Agreement. Travel By Crypto shall have right to transfer
                                        Your Account and Account Information to a third party who purchases Travel By
                                        Crypto's business as conducted under the Website.
                                    </PointText>
                                    <PointText>
                                        Severability: If, for any reason, a court of competent jurisdiction finds any
                                        provision of the Agreement, or portion thereof, to be unenforceable, that
                                        provision shall be enforced to the maximum extent permissible so as to give
                                        effect to the intent of the parties as reflected by that provision, and the
                                        remainder of the Agreement shall continue in full force and effect. Travel By
                                        Crypto may amend in a reasonable manner such provision to make it enforceable
                                        and such amendment will be given effect in accordance with the amendment terms
                                        of these Terms & Conditions.
                                    </PointText>
                                    <PointText>
                                        Waiver: Any failure or delay by a party to enforce or exercise any provision of
                                        the Agreement, or any related right, shall not constitute a waiver by such party
                                        of that provision or right. The exercise of one or more of a party's rights
                                        hereunder shall not be a waiver of, or preclude the exercise of, any rights or
                                        remedies available to such party under these Terms & Conditions or in law or at
                                        equity. Any waiver by a party shall only be made in writing and executed by a
                                        duly authorized officer of such party.
                                    </PointText>
                                    <PointText>
                                        Force Majeure: If performance of any service or obligation under these Terms &
                                        Conditions or other Agreement by Travel By Crypto is, or other third parties in
                                        fulfilment of any purchase or sale transaction (for eg: logistics service
                                        provider, fulfilment center, payment gateways etc.) are, prevented, restricted,
                                        delayed or interfered with by reason of labor disputes, strikes, acts of God,
                                        floods, lightning, severe weather, shortages of materials, rationing, utility or
                                        communication failures, earthquakes, war, revolution, acts of terrorism, civil
                                        commotion, acts of public enemies, blockade, embargo or any law, order,
                                        proclamation, regulation, ordinance, demand or requirement having legal effect
                                        of any government or any judicial authority or representative of any such
                                        government, or any other act whatsoever, whether similar or dissimilar to those
                                        referred to in this clause, which are beyond the reasonable control of Travel By
                                        Crypto or its third parties performing such services as sub-contractor to Travel
                                        By Crypto and could not have been prevented by reasonable precautions (each, a
                                        "Force Majeure Event"), then Travel By Crypto shall be excused from such
                                        performance to the extent of and during the period of such Force Majeure Event.
                                        Travel By Crypto shall exercise all reasonable commercial efforts to continue to
                                        perform its obligations hereunder.
                                    </PointText>
                                </SectionText>
                            </NumberHeading>
                        </ol>
                    </div>
                </Paper>
            </Grid>
        </Grid>
    );
};
