import styled from "@emotion/styled";
import TbcCard from "../../../commoncomponents/layout/card/TbcCard";
import { mainHeadingColor } from "../../../constants/ColorsConstants";
import { Typography } from "@mui/material";

export const OverviewCard = styled(TbcCard)`
    padding: 10px;
`;

export const HeadingTypography = styled(Typography)`
    color: ${mainHeadingColor};
`;

export const CarouselImg = styled.img`
    max-width: 100%;
    width: 1080px;
    height: 260px;
    object-fit: contain;
`;
