import { Grid, Typography } from "@mui/material";
import React, { FC } from "react";
import { getDomainImage } from "../../../constants/SiteConstants";
import BookingPriceCard from "../bookingprice/BookingPriceCard";
import Covid19TravelInfo from "./Covid19TravelInfo";
import FlightSummaryCard from "./FlightSummary";
import PaymentCard from "./PaymentCard";

const BookingReviewView: FC = () => {
    return (
        <Grid container>
            <Grid item xs={12} md={8} alignItems="stretch">
                <Grid item style={{ margin: 10 }}>
                    <Typography variant="button">Departing flight</Typography>
                    <FlightSummaryCard
                        flightDetails={{
                            id: 2,
                            departureTime: "13: 25",
                            departurePlace: "AMC",
                            landingTime: "21:10",
                            landingPlace: "MME",
                            totalTravelTime: "19h 15m",
                            price: "US$238.91",
                            flightName: "Spicejet",
                            flightIcon: getDomainImage("spicejet.com"),
                            type: "single"
                        }}
                    />
                    <br />
                    <Typography variant="button">Returning flight</Typography>
                    <FlightSummaryCard
                        flightDetails={{
                            id: 1,
                            departureTime: "23: 25",
                            departurePlace: "IDR",
                            landingTime: "13:10",
                            landingPlace: "MME",
                            totalTravelTime: "19h 15m",
                            price: "US$218.91",
                            flightName: "Air India",
                            type: "single",
                            flightIcon: getDomainImage("airindia.com")
                        }}
                    />
                </Grid>
                <br />
                <Grid item style={{ margin: 10 }}>
                    <Covid19TravelInfo />
                </Grid>
            </Grid>
            <Grid item xs={12} md={4} alignItems="stretch">
                <Grid item style={{ marginTop: 32 }}>
                    <PaymentCard />
                </Grid>
                <Grid item style={{ margin: 10 }}>
                    <BookingPriceCard passengerPrice={154.25} />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default BookingReviewView;
