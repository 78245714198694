export const mainBackgroundColor = "#E3ECED";
export const stepperBackground = "#13163C";
export const whiteColor = "#ffff";
export const menuLinkColor = "rgba(37,99,235,1)";
export const menuLinkColorInactive = "rgb(125 127 130)";
export const menuLinkBgColor = "rgba(239,246,255,1)";
export const borderGreyColor = "rgba(0, 0, 0, 0.23)";
export const tableCellBlackColor = "rgba(39, 48, 63, 1)";
export const sidebarMenuBoxShadowColor = "rgba(255, 217, 84, 0.56)";
export const iconPrimaryColor = `#2B86C9`;
export const iconDisabledColor = `#888888`;
export const disabledColor = `#888888`;
export const blackColor = `#000000`;
export const borderHoverPrimaryColor = `#2d83c2`;
export const successColor = `#27AE60`;
export const SubDetailsBackgroundColor = `#F6F7F8`;
export const trackerColor = `#b2b2bf`;
export const selectedStepperColor = `#4F97CC`;
export const mainHeadingColor = "#141D38";
export const remainingRoomsColor = "#CA2E2B";
export const refundabilityColor = "#0D7066";
export const darkGreen = "#2f7000";
export const bookingButtonColor = "#3d3100";
export const bookingButtonBgColor = "#ffbd24";
export const footerBgColor = "rgb(25, 25, 47)";
export const errorColor = `#DB2F2F`;
