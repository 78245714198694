import { Container, Grid } from "@mui/material";
import { ExplainerContainer, ExplainerParagraph, ExplainerTitle, ExplainerListItem } from "./Style";
import Image from '../../images/explainer.png';

const Features = () => {
    return (
        <ExplainerContainer>
            <Container>
                <Grid
                    container 
                    columnSpacing={{ xs: 5, sm: 0 }}
                    rowSpacing={{ xs: 5, sm: 0 }}
                    alignItems="center"
                >
                    <Grid item xs={12} sm={5}>
                        <ExplainerTitle>Travel By Crypto</ExplainerTitle>
                        <ExplainerParagraph>Instantly search and book flights worldwide from hundreds of airlines using your favorite cryptocurrency.</ExplainerParagraph>
                        <ul>
                            <ExplainerListItem>Pay using Bitcoin, Ethereum, Lite Coin, Lite Coin, USD Coin, Doge Coin, DAI or Bitcoin Cash</ExplainerListItem>
                            <ExplainerListItem>Available for all airlines and destinations</ExplainerListItem>
                            <ExplainerListItem>No hidden fees. No hidden charges.</ExplainerListItem>
                        </ul>
                    </Grid>
                    <Grid item xs={12} sm={7}>
                        <img src={Image} width="140%"/>
                    </Grid>
                </Grid>            
            </Container>
        </ExplainerContainer>
    );
};

export default Features;
