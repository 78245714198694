import React, { FC } from "react";
import { CarouselImg } from "./Styled";
import Carousel from "react-material-ui-carousel";

type CarouselProps = {
    images: string[];
};
const PropertyDetailCarousel: FC<CarouselProps> = ({ images }) => (
    <Carousel autoPlay={false} navButtonsAlwaysVisible indicators={false}>
        {images.map((image) => (
            <CarouselImg key={image} src={image} />
        ))}
    </Carousel>
);

export default PropertyDetailCarousel;
