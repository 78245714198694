import { Grid } from "@mui/material";
import React from "react";
import { PrimaryButton } from "../../commoncomponents/CommonStyle";
import BookingPriceCard from "../booking/bookingprice/BookingPriceCard";
import BaggageDimension from "./BaggageDimension";
import BookingDetailsView from "./BookingDetailsView";
import FlightSummary from "./FlightSummary";
import PaymentMethod from "./PaymentMethod";
import ReservationTerms from "./ReservationTerms";

const PaymentView = () => (
    <>
        {/* <FlightStepper activeStep={3} /> */}
        <Grid container justifyContent="center">
            <Grid item xs={12} md={10}>
                <Grid container spacing={4} style={{ paddingBottom: 30 }}>
                    <Grid item xs={12} md={8}>
                        <BookingDetailsView />
                        <PaymentMethod />
                        <ReservationTerms />
                        <PrimaryButton style={{ marginTop: 20 }} variant={"contained"}>
                            Complete reservation
                        </PrimaryButton>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <BookingPriceCard passengerPrice={154.25} />
                        <BaggageDimension />
                        <FlightSummary />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </>
);

export default PaymentView;
