import { FormHelperText } from "@mui/material";
import { FormikProps } from "formik";
import { get } from "lodash";
import React from "react";
import PhoneInput from "react-phone-input-2";
import { errorColor } from "../constants/ColorsConstants";
import { TProps } from "./Base";
import "react-phone-input-2/lib/style.css";

type PhoneInputWrapperProps<T> = {
    formik: FormikProps<T>;
    name: string;
};
const PhoneInputWrapper = <T extends TProps>(props: PhoneInputWrapperProps<T>) => {
    return (
        <>
            <PhoneInput
                country={"us"}
                autoFormat={false}
                value={props.formik.values[props.name] as string}
                containerStyle={{
                    border:
                        !!get(props.formik.errors, props.name) && !!get(props.formik.touched, props.name)
                            ? `1px solid ${errorColor}`
                            : undefined
                }}
                inputStyle={{ width: "100%", height: 40 }}
                onBlur={() => props.formik.setFieldTouched(props.name, true)}
                onChange={(phone) => props.formik.setFieldValue(props.name, phone)}
            />
            {!!get(props.formik.errors, props.name) && !!get(props.formik.touched, props.name) && (
                <FormHelperText style={{ color: errorColor }}>{get(props.formik.errors, props.name)}</FormHelperText>
            )}
        </>
    );
};

export default PhoneInputWrapper;
