import { Typography, RadioGroup, FormControlLabel, Radio, Button, Collapse } from "@mui/material";
import { Box } from "@mui/system";
import React, { FC } from "react";
import { useBooleanState } from "../../../commoncomponents/CommonHooks";
import { initalShowMoreSize } from "../../../constants/SiteConstants";

type RadioButtonsListProps = {
    heading: string;
    data: string[];
};
const RadioButtonsList: FC<RadioButtonsListProps> = ({ heading, data }) => {
    const showAllFilters = useBooleanState(false);

    const handleClick = () => {
        showAllFilters.toggle();
    };

    return (
        <Box my={2}>
            <Typography>{heading}</Typography>
            <RadioGroup>
                {data.map(
                    (filter, index) =>
                        index <= 7 && (
                            <FormControlLabel
                                key={filter}
                                value={filter.toLowerCase().replace(/ /g, "-")}
                                control={<Radio />}
                                label={filter}
                            />
                        )
                )}
            </RadioGroup>
            {data.length > initalShowMoreSize && (
                <>
                    <Collapse in={showAllFilters.value} timeout="auto" unmountOnExit>
                        <RadioGroup>
                            {data.map(
                                (filter, index) =>
                                    index >= initalShowMoreSize && (
                                        <FormControlLabel
                                            key={filter}
                                            value={filter.toLowerCase().replace(/ /g, "-")}
                                            control={<Radio />}
                                            label={filter}
                                        />
                                    )
                            )}
                        </RadioGroup>
                    </Collapse>
                    <Button variant="text" onClick={handleClick}>
                        {showAllFilters.value ? "See less" : "See more"}
                    </Button>
                </>
            )}
        </Box>
    );
};

export default RadioButtonsList;
