import styled from "@emotion/styled";
import { Box, Button } from "@mui/material";
import { darkGreen, bookingButtonColor, bookingButtonBgColor } from "../../../constants/ColorsConstants";
export const IconWrapper = styled(Box)`
    flex-wrap: nowrap;
    align-items: center;
    display: flex;
    align-items: flex-start;
`;

export const FixedImg = styled.img`
    max-width: 100%;
    height: auto;
`;

export const MoneySavedButton = styled(Button)`
    background-color: ${darkGreen};
    color: white;
    border-radius: 5px;
    padding: 0 10px;
    font-size: 12px;
    font-weight: bold;
    &:hover {
        background-color: ${darkGreen};
    }
`;

export const SubmitButton = styled(Button)`
    background-color: ${bookingButtonBgColor};
    color: ${bookingButtonColor};
    font-weight: bold;
    padding: 1em;
    &:hover {
        background-color: ${bookingButtonBgColor};
    }
`;
