import TbcCard from "../../../../commoncomponents/layout/card/TbcCard";
import React, { FC } from "react";
import { Grid, ListItemText, List, Accordion, AccordionSummary, AccordionDetails, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { HeadingTypography } from "../Styled";
import { Policies_, ImportantInformation, FAQ } from "./PoliciesData";

const Policies: FC = () => (
    <TbcCard>
        <Grid container>
            <Grid item container xs={12}>
                <Grid item xs={12}>
                    <HeadingTypography sx={{ mb: 3, mt: 2 }} variant="h5">
                        Policies
                    </HeadingTypography>
                </Grid>
                <Grid item container xs={12}>
                    {Policies_.map((policy) => (
                        <Grid sx={{ ml: { sm: 10 } }} key={policy.id} item xs={12}>
                            <HeadingTypography sx={{ fontSize: "1rem" }} variant="h5">
                                {policy.title}
                            </HeadingTypography>
                            <List sx={{ mb: 3 }}>
                                {policy.details.map((detail) =>
                                    detail.startsWith("/images") ? (
                                        <img key={detail} src={detail} alt={detail} />
                                    ) : (
                                        <ListItemText sx={{ ml: 5 }} key={detail}>
                                            {detail}
                                        </ListItemText>
                                    )
                                )}
                            </List>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
            <Grid item container xs={12}>
                <Grid item xs={12}>
                    <HeadingTypography sx={{ mb: 3, mt: 2 }} variant="h5">
                        Important information
                    </HeadingTypography>

                    <Grid item container xs={12}>
                        {ImportantInformation.map((info) => (
                            <Grid sx={{ ml: { sm: 10 } }} key={info.id} item xs={12}>
                                <HeadingTypography sx={{ fontSize: "1rem" }} variant="h5">
                                    {info.title}
                                </HeadingTypography>
                                <List sx={{ mb: 3 }}>
                                    {info.details.map((detail) =>
                                        Array.isArray(detail) ? (
                                            detail.map((ele) => (
                                                <ListItemText key={ele} sx={{ pl: 9 }}>
                                                    {`- ${ele}`}
                                                </ListItemText>
                                            ))
                                        ) : (
                                            <ListItemText sx={{ ml: 5 }} key={detail}>
                                                {detail}
                                            </ListItemText>
                                        )
                                    )}
                                </List>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
            <Grid item container xs={12}>
                <Grid item xs={12}>
                    <HeadingTypography sx={{ mb: 3, mt: 2 }} variant="h5">
                        Frequently asked questions
                    </HeadingTypography>

                    <Grid item container xs={12}>
                        {FAQ.map((q) => (
                            <Grid sx={{ ml: { sm: 10 } }} key={q.id} item xs={12}>
                                <Accordion sx={{ m: 0.5 }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography>{q.title}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {q.details.map((answer) => (
                                            <Typography key={answer}>{answer}</Typography>
                                        ))}
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </TbcCard>
);

export default Policies;
