import Axios, { AxiosError, AxiosRequestConfig } from "axios";

const getErrorMessage = (error: AxiosError): any => {
    let message = "";
    let errorMessage = "";
    const statusCode = error.response?.status;

    switch (statusCode) {
        case 400:
            errorMessage = "Bad Request The request was unacceptable, often due to missing a required parameter";
            message = error.response?.data.message || "There was an error with your request.";
            break;
        case 402:
            errorMessage = "Request Failed The parameters were valid but the request failed.(Upgrade required)";
            message = "Require an upgrade";
            break;
        case 401:
            errorMessage = "Unauthorized No valid API key provided.";
            message = "Token expired. Please login again";
            break;
        case 403:
            errorMessage = "Forbidden The API key doesn't have permissions to perform the request.";
            message = "You are not authorised to view this page.";
            break;
        case 404:
            errorMessage = "Not Found The requested resource doesn't exist.";
            message = "The resource you are looking for doesn't exist.";
            break;
        case 429:
            errorMessage = "Too Many Requests Too many requests hit the API too quickly.";
            message = "There was an error with your request.";
            break;
        default:
            message = "There was an error with your request.";
    }

    return { ...error, message, statusCode, errorMessage };
};

export class Client<T> {
    protected constructor() {
        this._data = {};
    }

    public static getInstance<U>() {
        if (!this._instance) {
            this._instance = new Client<U>();
        }

        return this._instance;
    }

    public getData(url: string, forceRefresh = false): Promise<any> {
        if (forceRefresh || !this._data[url]) {
            return Axios.get(url)
                .then((result: any) => {
                    this._data[url] = Promise.resolve(result);
                    return this._data[url];
                })
                .catch((err) => Promise.reject(getErrorMessage(err)));
        }

        return this._data[url];
    }

    public updateData(url: string, data: any): Promise<any> {
        return Axios.put(url, data)
            .then((result: any) => {
                return result;
            })
            .catch((err) => Promise.reject(getErrorMessage(err)));
    }

    public createData(url: string, data: any, forceRefresh = true): Promise<any> {
        if (forceRefresh || !this._data[url]) {
            return Axios.post(url, data)
                .then((result: any) => {
                    this._data[url] = Promise.resolve(result);
                    return this._data[url];
                })
                .catch((err) => Promise.reject(getErrorMessage(err)));
        }

        return this._data[url];
    }

    public deleteData(url: string): Promise<any> {
        return Axios.delete(url)
            .then((result: any) => {
                return result;
            })
            .catch((err) => Promise.reject(getErrorMessage(err)));
    }

    public setAuthenticationClient = (token: string): void => {
        Axios.interceptors.request.use(async (config: any): Promise<AxiosRequestConfig> => {
            // eslint-disable-next-line no-param-reassign
            config.headers.Authorization = `Bearer ${token}`;
            return config;
        });
    };

    private static _instance: Client<any>;

    private _data: { [key: string]: Promise<T> };
}
