import React from "react";
import { TextField, TextFieldProps } from "@mui/material";
import { FormikProps } from "formik";
import { get } from "lodash";

type TProps = Record<string, unknown>;

type TextFieldWrapperProps<T> = {
    isBasic?: boolean;
    formik?: FormikProps<T>;
} & TextFieldProps;

const TextFieldWrapper = <T extends TProps>(props: TextFieldWrapperProps<T>) => {
    if (props.formik) {
        return (
            <TextField
                {...props}
                value={props.name && get(props.formik.values, props.name)}
                error={
                    !!props.name && !!get(props.formik.errors, props.name) && !!get(props.formik.touched, props.name)
                }
                onChange={props.onChange || props.formik.handleChange}
                onBlur={() => {
                    props.name && props.formik && props.formik.setFieldTouched(props.name);
                }}
                helperText={
                    !!props.name && !!get(props.formik.touched, props.name) && get(props.formik.errors, props.name)
                }
                fullWidth
                variant={"outlined"}
                size={"small"}
                autoComplete="off"
            />
        );
    }
    return <TextField {...props} fullWidth variant={"outlined"} size={"small"} autoComplete="off" />;
};

export default TextFieldWrapper;
