export const mapSrc =
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3222.2569783751446!2d-115.16853718493796!3d36.1359557800907!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c8c40c4f536b0d%3A0x2e16fad366495333!2sW%20Resorts%20World%20Dr%2C%20Las%20Vegas%2C%20NV%2089109%2C%20USA!5e0!3m2!1sen!2sin!4v1637083688845!5m2!1sen!2sin";

export const filterBy: string[] = ["Siloso Beach", "All-inclusive", "Breakfast included", "Villa", "Hot tub"];

export const propertyClass: string[] = ["1", "2", "3", "4", "5"];

export const yourBudget: string[] = [
    "Less than AU$75",
    "AU$75 to AU$125",
    "AU$125 to AU$200",
    "AU$200 to AU$300",
    "Greater than AU$300"
];

export const guestRating: string[] = ["Any", "Wonderful", "Very good", "Good"];

export const cleaningAndSafetyPractices: {
    enhancedCleaning: {
        title: string;
        info: string;
    };
} = {
    enhancedCleaning: { title: "Enhanced cleaning", info: "Properties taking additional steps to clean and sanitise" }
};

export const paymentType = ["Fully refundable", "Reserve now, pay later"];

export const propertyType: string[] = [
    "Villa",
    "Hotel",
    "Holiday park",
    "Apart-hotel",
    "Condo",
    "House",
    "Apartment",
    "Campsite"
];

export const area: string[] = [
    "Singapore",
    "Yishun",
    "Harbourfront",
    "River Valley",
    "Kallang",
    "Chinatown",
    "Woodlands",
    "Little India",
    "Tanglin",
    "Geylang",
    "Orchard",
    "Tampines",
    "Balestier Road",
    "East Coast",
    "Downtown Singapore",
    "Marina Bay",
    "Outram",
    "Bukit Merah",
    "Changi",
    "Clarke Quay"
];

export const popularLocations: string[] = [
    "Orchard Road",
    "Marina Bay Sands Casino",
    "Universal Studios Singapore",
    "Marina Bay Sands Skypark",
    "Gardens by the Bay",
    "East Coast Park",
    "Sembawang Park",
    "Singapore Zoo",
    "Raffles Place",
    "Jurong Bird Park",
    "Singapore City Hall",
    "Marina Square",
    "Pasir Ris Park",
    "Singapore Turf Club",
    "Singapore Botanic Gardens",
    "Suntec City",
    "Siloso Beach",
    "Clementi Mall",
    "Singapore Flyer",
    "Singapore Expo"
];

export const mealPlansAvailable: string[] = [
    "Breakfast included",
    "Lunch included",
    "Dinner included",
    "All-inclusive"
];

export const amenities: string[] = [
    "Hot tub",
    "Pool",
    "Air conditioned",
    "Kitchen",
    "Free WiFi",
    "Ocean view",
    "Spa",
    "Free airport shuttle",
    "Washer and dryer",
    "Pet-friendly",
    "Casino",
    "Parking",
    "Water park"
];

export const expediaRewards: {
    vipAccess: {
        title: string;
        info: string;
    };
} = {
    vipAccess: {
        title: "VIP Access properties",
        info: "Top-rated stays with member perks"
    }
};

export const accessibility: string[] = [
    "Lift",
    "Accessible bathroom",
    "Roll-in shower",
    "In-room accessibility",
    "Service animals allowed",
    "Stair-free path to entrance",
    "Sign language-capable staff"
];

export const travellerExperience: {
    title: string;
    info: string;
    id: number;
}[] = [
    {
        id: 1,
        title: "Business-friendly",
        info: "See properties with amenities to help you work comfortably, like WiFi and breakfast."
    },
    {
        id: 2,
        title: "Family-friendly",
        info: "See properties that include family essentials like in-room conveniences and activities for the kids."
    }
];
