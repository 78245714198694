import Styled from "@emotion/styled";
import { Button, Divider, FormControlLabel, Paper, Slider, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import {
    blackColor,
    borderGreyColor,
    disabledColor,
    menuLinkColor,
    sidebarMenuBoxShadowColor,
    SubDetailsBackgroundColor,
    successColor,
    tableCellBlackColor,
    trackerColor,
    whiteColor,
    mainHeadingColor
} from "../constants/ColorsConstants";
import { styled } from "@mui/material/styles";

type CustomDateRangeStyledProps = {
    selected: boolean;
};

export const CustomDateRangeStyled = Styled.div<CustomDateRangeStyledProps>`
    display: flex;
    align-items: center;
    border: 1px solid ${borderGreyColor};
    border-radius: 8px;
    background-color: transparent;
    margin-left: 10px;
    height: 40px;
    & .DateInput_input {
        padding: 5px;
        padding-bottom: 4px;
        font-size: 14px;
        background-color: transparent;
        color: ${(props) => (props.selected ? tableCellBlackColor : borderGreyColor)};
    }
    & .DateRangePickerInput {
        background-color: transparent;
        border: none;
        padding-right: ${(props) => (props.selected ? "30px" : "0px")};
    }
    & .DateInput {
        background-color: transparent;
        width: 80px;
    }
    & .DateRangePickerInput_arrow {
        margin-bottom: 4px;
        margin-right: 4px;
    }
    & .DateRangePickerInput_clearDates {
        padding-top: 7px;
        padding-right: 3px;
        &:hover {
            background-color: transparent;
        }
        &:focus {
            background-color: transparent;
        }
    }
    & .DateRangePicker_picker {
        left: -200px !important;
    }
    & .CalendarDay__default {
        border: none;
        color: ${tableCellBlackColor};
        font-size: 12px;
        &:hover {
            border: none;
        }
        &:focus {
            border: none;
        }
    }
    & .CalendarDay__selected {
        background: ${menuLinkColor};
        box-shadow: 0px 4px 18px ${sidebarMenuBoxShadowColor};
        border-radius: 8px;
        &:hover {
            background: ${menuLinkColor};
        }
        &:focus {
            background: ${menuLinkColor};
        }
    }
    & .CalendarDay__selected_span {
        background: #e7f3ff;

        &:hover {
            background: #e7f3ff;
        }
        &:focus {
            background: #e7f3ff;
        }
    }
    & .DayPicker_weekHeader {
        color: ${tableCellBlackColor};
        font-size: 10px !important;
    }
`;

export const CustomDatePickerStyled = Styled.div`
    display: flex;
    align-items: center;
    border: 1px solid ${borderGreyColor};
    border-radius: 8px;
    background-color: transparent;
    box-shadow: none;
    padding: 1.8px;
    padding-left: 0px;
    justify-content: space-evenly;
    & .DateInput_input {
        padding: 5px;
        padding-bottom: 4px;
        font-size: 12px;
        background-color: transparent;
        color: ${tableCellBlackColor};
    }
    & .DateRangePickerInput {
        background-color: transparent;
        border: none;
    }
    & .DateInput {
        background: transparent;
        width: 100%;
    }
    & .SingleDatePickerInput__withBorder {
        box-shadow: none;
        border: none;
    }
    & .DateRangePickerInput_arrow {
        margin-bottom: 4px;
        margin-right: 4px;
    }
    & .DateRangePickerInput_clearDates {
        padding-top: 7px;
        padding-right: 3px;
        &:hover {
            background-color: transparent;
        }
        &:focus {
            background-color: transparent;
        }
    }
    & .DateRangePicker_picker {
        left: -200px !important;
    }
    & .CalendarDay__default {
        border: none;
        color: ${tableCellBlackColor};
        font-size: 12px;
        &:hover {
            border: none;
        }
        &:focus {
            border: none;
        }
    }
    & .CalendarDay__selected {
        background: #e7f3ff;
        box-shadow: 0px 4px 18px ${sidebarMenuBoxShadowColor};
        border-radius: 8px;
        &:hover {
            background: #e7f3ff;
        }
        &:focus {
            background: #e7f3ff;
        }
    }
    & .CalendarDay__selected_span {
        background: ${menuLinkColor};

        &:hover {
            background: ${menuLinkColor};
        }
        &:focus {
            background: ${menuLinkColor};
        }
    }
    & .DayPicker_weekHeader {
        color: ${tableCellBlackColor};
        font-size: 10px !important;
    }
`;

export const PrimaryButton = Styled(Button)`
    border-radius: 6px;
    align-items: flex-start;
    font-weight: 600;
    font-size: 12px;
    box-shadow: none;
    text-transform: capitalize;
    padding: 8px 16px;
    gap: 5px;
    &:hover {
        box-shadow: none;
    }
`;

export const PrimaryOptionsButton = Styled(PrimaryButton)`
    padding: 8px 4px;
`;

export const OptionTextColor = Styled(Typography)`
    color: ${blackColor};
    padding: 8px 8px;
    width: fit-content;
    background-color: transparent;
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.15px;
    color: #1A237E;
`;

export const InfoText = Styled(Typography)`
    color: ${blackColor};
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
    margin: 0px 0px;
    display: flex;
    align-items: center;
`;

export const InfoTitleText = Styled(InfoText)`
    font-weight: bold;
`;

export const InfoSubText = Styled.span`
    font-weight: 400;
`;

export const HeadingText = Styled(Typography)`
    color: ${blackColor};
    background-color: transparent;
    border-radius: 6px;
    font-weight: 500;
    font-size: 16px;
    display: flex;
    align-items: center;
    gap: 5px;
`;

export const HeadingTypography = styled(Typography)`
    color: ${mainHeadingColor};
`;

export const CaptionText = Styled(Typography)`
    font-style: normal;
    font-weight: 450;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: 0.15px;
    color: #9E9E9E;
`;

export const CaptionSubText = Styled.span`
    color: ${disabledColor};
    display: flex;
    gap: 10px;
    align-items: center;
`;

export const CardContainer = Styled(Paper)`
    box-shadow: 0 0 7px 0 hsl(0deg 0% 55% / 21%);
    border-radius: 5px;
    padding: 20px 25px;
    margin-top: 20px;
`;

export const FlightSummaryCard = Styled(CardContainer)`
    padding: 0px;
`;
export const PriceText = Styled(InfoText)`
    color: ${successColor};
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    text-align: right;
    letter-spacing: 0.15px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 1;
    margin: 0px 0px;
`;

export const StyledSlider = withStyles({
    root: {
        color: successColor,
        height: 8,
        marginTop: "10%"
    },
    thumb: {
        height: 20,
        width: 20,
        marginTop: "-4px",
        padding: 10,
        backgroundColor: whiteColor,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        border: "1px #b1afaf solid",
        "&:focus, &:hover, &$active": {
            boxShadow: "inherit"
        }
    },
    active: {},
    valueLabel: {
        left: "auto",
        top: -25,
        boxShadow: "none",
        "&": {
            border: "1px #b1afaf solid",
            boxShadow: "0px 1px 4px 0px #b1afaf",
            display: "flex",
            backgroundColor: "#E3E3E9",
            color: "#000",
            transform: "rotate(0deg)",
            borderRadius: "5px",
            height: 25,
            justifyContent: "center",
            alignItems: "center"
        },
        "& *": {
            display: "flex",
            backgroundColor: whiteColor,
            color: "#000",
            transform: "rotate(0deg)",
            borderRadius: "5px",
            height: 25,
            justifyContent: "center",
            alignItems: "center"
        }
    },
    track: {
        height: "12px",
        borderRadius: 4,
        left: "0% !important",
        width: "98% !important"
    },
    rail: {
        height: "12px",
        borderRadius: 4
    }
})(Slider);

type StyledSliderProps = {
    slider_width: number;
};

export const SliderWrapper = Styled(StyledSlider)`
    .MuiSlider-track {
        left: 0% !important;
        width: ${(props: StyledSliderProps) => `${props.slider_width}% !important`};
    }
`;

export const FilterDivider = Styled(Divider)`
    color: ${borderGreyColor};
    margin-bottom: 10px;
`;

export const VerticaltrackerContainer = Styled.div`
    display: flex;
    padding-top: 0.375rem;
    padding-right: 1.125rem;
    padding-bottom: 0.375rem;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    &::before {
        content: "";
        display: block;
        width: 10px;
        height: 13px;
        border: 2px solid #b2b2bf;
        border-radius: 10px;
        background: ${whiteColor};
    }
    &::after {
        content: "";
        display: block;
        width: 10px;
        height: 13px;
        border: 2px solid #b2b2bf;
        border-radius: 10px;
        background: ${whiteColor};
    }
`;
export const VerticalTracker = Styled.div`
    border-left: 1px ${trackerColor} solid;
    position: relative;
    height: 100%;
`;

export const SubDetailsContainer = Styled.div`
    padding: 10px;
    background-color: ${SubDetailsBackgroundColor};
`;

export const LinkTitle = Styled.span`
    color: ${menuLinkColor};
    margin: 0px 3px;
    cursor: pointer;
`;

export const FormControlLabelWrapper = Styled(FormControlLabel)`
    & .MuiTypography-root{
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 0px 0px;
    }
    
`;
