import {
    Place,
    Shield,
    CleanHands,
    CleaningServices,
    Sanitizer,
    LocalLaundryService,
    Spa,
    Pool,
    Wifi,
    LocalParking
} from "@mui/icons-material";

export const hotelTitle = "Conrad Las Vegas at Resorts World";

export const hotelRatingSummary = {
    ratingValue: 4.3,
    cleanliness: 4.7,
    description: "Excellent",
    reviewCount: "1,085"
};

export const hotelMapDetails = {
    src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3222.2569783751446!2d-115.16853718493796!3d36.1359557800907!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c8c40c4f536b0d%3A0x2e16fad366495333!2sW%20Resorts%20World%20Dr%2C%20Las%20Vegas%2C%20NV%2089109%2C%20USA!5e0!3m2!1sen!2sin!4v1637083688845!5m2!1sen!2sin",
    location: `111 Resorts World Avenue, Las Vegas, NV, 89109`
};

export const amenities = [
    {
        text: "Parking included",
        icon: <LocalParking />
    },
    {
        text: "Free Wifi",
        icon: <Wifi />
    },
    {
        text: "Pool",
        icon: <Pool />
    },
    {
        text: "Spa",
        icon: <Spa />
    },
    {
        text: "Laundry",
        icon: <LocalLaundryService />
    }
];

export const cleaningPracices = [
    {
        text: "Cleaned with disinfectant",
        icon: <Sanitizer />
    },
    {
        text: "Contactless check-in",
        icon: <CleaningServices />
    },
    {
        text: "Hand sanitizer provided",
        icon: <CleanHands />
    },
    {
        text: "Personal protective equipment",
        icon: <Shield />
    }
];

export const exploreAreaPoints = [
    {
        text: "Fashion Show Mall",
        subText: "14 min walk",
        icon: <Place />
    },
    {
        text: "Las Vegas Convention Center",
        subText: "14 min walk",
        icon: <Place />
    },
    {
        text: "The Venetian Casino",
        subText: "19 min walk",
        icon: <Place />
    },
    {
        text: "Las Vegas, NV (LAS-McCarran Intl.)",
        subText: "7 min walk",
        icon: <Place />
    }
];

export const carouselImages: string[] = [
    "/images/search/1.webp",
    "/images/search/2.webp",
    "/images/search/3.webp",
    "/images/search/4.webp"
];
