import Styled from "@emotion/styled";
import { Grid } from "@mui/material";

export const FlightDetailsContainer = Styled.div`
    padding: 0px 20px 30px 20px;
    background: #F6F7F8;
    border-radius: 5px;
`;

export const FlightConnectContainer = Styled(Grid)`
    margin: 20px 0px;
    background: #E6F7FA;
    padding: 20px;
    border-radius: 4px;
    color: #19B4D1;
`;
