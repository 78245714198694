import React, { FC } from "react";
import { SearchedResultContainer } from "./Style";
import { useRenderAirportOptions } from "./Hooks";
import { AirportListType } from "../../component/searchtabs/Type";
import { CircularProgress, Grid } from "@mui/material";

type SearchTextPopoverProps = {
    searchFieldHeight: number;
    handleAirportSelect: (airportSelect: string, airportSelectCode: string) => void;
    options?: AirportListType[];
    isLoading: boolean;
};
const SearchTextPopover: FC<SearchTextPopoverProps> = ({
    searchFieldHeight,
    handleAirportSelect,
    options,
    isLoading = false
}) => {
    const renderOptions = (optionsList: AirportListType, type: "sub" | "primary") =>
        useRenderAirportOptions(optionsList || [], handleAirportSelect, type);

    const renderView = () => {
        if (isLoading) {
            return (
                <Grid sx={{ p: "20px" }} container justifyContent={"center"} alignItems={"center"}>
                    <CircularProgress />
                </Grid>
            );
        }
        if (options && options.length === 0) {
            return (
                <Grid sx={{ p: "20px" }} container justifyContent={"center"} alignItems={"center"}>
                    No results found
                </Grid>
            );
        }
        return options?.map((item: AirportListType) => (
            <span key={item.id}>
                {renderOptions(item, "primary")}
                {item.airports &&
                    item.airports.length > 0 &&
                    item.airports.map((option) => renderOptions(option, "sub"))}
            </span>
        ));
    };

    return <SearchedResultContainer topheight={searchFieldHeight}>{renderView()}</SearchedResultContainer>;
};

export default SearchTextPopover;
