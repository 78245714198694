import { Grid } from "@mui/material";
import { FC } from "react";
import { HeadingText } from "../../../commoncomponents/CommonStyle";
import LoadingShimmerRows from "../../../commoncomponents/ShimmerRows";
import { ONE_WAY } from "../../../constants/SiteConstants";
import FlightCardDetail from "./FlightCardDetail";
import { AirportsDetailsType, FlightDetailInfoType, FlightSearchResultsType } from "./Type";
import { getAirportName } from "./Utils";

type FlightListProps = {
    flightsList: FlightSearchResultsType[];
    airlineList?: FlightDetailInfoType[];
    isLoading: boolean;
    airportList?: AirportsDetailsType[];
    title?: string;
    setSelectedFlight: (flight: FlightSearchResultsType) => void;
    searchType: string;
    minFlightCost: number;
};

const FlightList: FC<FlightListProps> = ({
    flightsList,
    airlineList,
    isLoading,
    airportList,
    title,
    setSelectedFlight,
    searchType,
    minFlightCost
}) => {
    if (flightsList.length === 0 && !isLoading) {
        return (
            <Grid container style={{ marginTop: 30 }} direction="column" justifyContent="center" alignItems="center">
                <HeadingText>No Flights Found</HeadingText>
            </Grid>
        );
    }

    const getTotalCost = (currentFlightAmount: number) => {
        if (searchType === ONE_WAY) {
            return currentFlightAmount;
        }
        return currentFlightAmount + minFlightCost;
    };
    return (
        <Grid>
            <div style={{ marginTop: 20 }}>{title}</div>
            <Grid container>
                <LoadingShimmerRows isLoading={isLoading} noOfCards={4}>
                    {flightsList.map((flight) => (
                        <Grid item xs={12} key={flight.id}>
                            <FlightCardDetail
                                addToSelectedFlight={() => setSelectedFlight(flight)}
                                flightDetails={flight.itineraries[0]}
                                totalCost={getTotalCost(flight.total)}
                                flightCode={flight.validatingCarrierCode}
                                flightName={getAirportName(flight.validatingCarrierCode, airlineList || [])}
                                airportList={airportList || []}
                                searchType={searchType}
                            />
                        </Grid>
                    ))}
                </LoadingShimmerRows>
            </Grid>
        </Grid>
    );
};

export default FlightList;
