import styled from "@emotion/styled";
import { Typography } from "@mui/material";

export const FilterTitle = styled(Typography)`
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 160%;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 0px;
`;

export const SubFilterTitle = styled(FilterTitle)`
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 157%;
    letter-spacing: 0.1px;
    color: rgba(0, 0, 0, 0.6);
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    margin: 0px 0px;
`;
type FilterContainerProps = {
    isMobile: number;
};
export const FilterContainer = styled.div<FilterContainerProps>`
    padding: 20px 25px;
    margin-top: ${(props) => (props.isMobile === 1 ? "0" : "30px")};
`;

export const FilterOptionText = styled(Typography)`
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
    margin: 0px 0px;
`;
