/* eslint-disable no-console */
import moment from "moment";
import { Client } from "../../../commoncomponents/Client";
import { getFlightDetailsApiUrl, revalidateFlightApiUrl } from "../../../constants/ApiConstants";
import { ADULTS, CHILDREN, INFANTS, ONE_WAY, RETURN } from "../../../constants/SiteConstants";
import { TimesOption } from "../../../constants/SiteOptions";
import { convertTimeToAmPm, getArrayOfItems } from "../../../util/SiteUtils";
import { FlightSearchType } from "../../searchtabs/Type";
import { flightSearchInitialValue, getTotalPassengerLink } from "../../searchtabs/Util";
import {
    AirportsDetailsType,
    FlightDetailInfoType,
    FlightSearchInfoType,
    FlightSearchResultsType,
    SegmentsType,
    TravellerPricingType
} from "./Type";

export const emptyFlightFilters = {
    airlines: [],
    departureTime: [],
    arrivalTime: [],
    stops: []
};

export const reverseTransformFlightSearch = (searchParams: URLSearchParams) => {
    const from = searchParams.get("fromCode") || "";
    const to = searchParams.get("toCode") || "";
    const departureDate = searchParams.get("departureDate") || "";
    const returnDate = searchParams.get("returnDate") || "";
    const flightType = searchParams.get("flight") || "";
    const passengerArray = [
        searchParams.get(ADULTS) || "0",
        searchParams.get(CHILDREN) || "0",
        searchParams.get(INFANTS) || "0"
    ];
    const searchType = searchParams.get("searchtype") || "";

    const transits = [
        {
            origin: from,
            destination: to,
            departureDate: departureDate
        }
    ];
    if (searchType !== ONE_WAY) {
        transits.push({
            origin: to,
            destination: from,
            departureDate: returnDate
        });
    }
    return {
        cabin: flightType,
        passengerCounts: {
            adults: parseInt(passengerArray[0]),
            children: parseInt(passengerArray[1]),
            infants: parseInt(passengerArray[2])
        },
        transits
    };
};

const transformTravellerDetails = (data: any): TravellerPricingType[] => {
    if (!data) {
        return [];
    }
    return data.map((item: any) => ({
        fareOptions: item.fareOption,
        travellerType: item.travelerType,
        travellerId: item.travellerId,
        price: item.price.total
    }));
};

const transformItenariesDetails = (data: any): TravellerPricingType[] => {
    if (!data) {
        return [];
    }
    return data.map((item: any) => ({
        duration: item.duration,
        id: item.id,
        layovers: item.layovers,
        segments: item.segments
    }));
};

const transformAirportsDetails = (data: any): AirportsDetailsType[] => {
    if (!data) {
        return [];
    }
    return data.map((item: any) => ({
        city: {
            country: {
                iata: item.city.country.iata,
                name: item.city.country.name
            },
            iata: item.city.iata,
            name: item.city.name
        },
        iata: item.iata,
        latitude: item.latitude,
        longitude: item.longitude,
        name: item.name,
        stateCode: item.state_code,
        timezoneOffset: item.timezone_offset
    }));
};

const transformFlightSearch = ({ data }: any): FlightSearchInfoType => ({
    filters: {
        airlines: data.airlines.map((item: any) => ({
            name: item.name,
            country: item.country,
            iata: item.iata,
            icao: item.icao,
            active: item.active
        })),
        filterBounds: {
            price: {
                max: data.filter_bounds.price.max,
                min: data.filter_bounds.price.min
            }
        },
        airports: transformAirportsDetails(data.airports)
    },
    results: {
        inbound: data.results.inbound.map((item: any) => ({
            id: item.uid,
            base: item.base,
            total: item.total,
            key: item.key,
            uid: item.uid,
            travellerPricing: transformTravellerDetails(item.travelerPricings),
            validatingCarrierCode: item.validatingCarrierCode,
            itineraries: transformItenariesDetails(item.itineraries)
        })),
        outbound: data.results.outbound.map((item: any) => ({
            id: item.uid,
            base: item.base,
            total: item.total,
            key: item.key,
            matchingInbounds: item.matching_inbounds,
            travellerPricing: transformTravellerDetails(item.travelerPricings),
            validatingCarrierCode: item.validatingCarrierCode,
            itineraries: transformItenariesDetails(item.itineraries)
        }))
    }
});

const transformResData = (data: any) => {
    return {
        family: data[0].family,
        itineraries: transformItenariesDetails(data[0].itineraries),
        oneWayPrice: {
            ...data[0].oneWayPrice
        }
    };
};

const transformRevalidateData = (data: any) => {
    if (!data) {
        return [];
    }
    return data.data.map(({ res_data }: any) => transformResData(res_data)) || [];
};

export const getPassengerArray = (searchParams: URLSearchParams) => {
    const adultCount = (searchParams.get(ADULTS) || 1) as number;
    const childCount = (searchParams.get(CHILDREN) || 0) as number;
    const infantCount = (searchParams.get(INFANTS) || 0) as number;
    return [
        ...getArrayOfItems(adultCount, ADULTS),
        ...getArrayOfItems(childCount, CHILDREN),
        ...getArrayOfItems(infantCount, INFANTS)
    ];
};

export const prepareFlightNavigationData = (values: FlightSearchType): string => {
    const totalAdult = values.passengers.passengerType.filter((item) => item === ADULTS).length;
    const totalChild = values.passengers.passengerType.filter((item) => item === CHILDREN).length;
    const totalInfant = values.passengers.passengerType.filter((item) => item === INFANTS).length;
    const from = values.from;
    const to = values.to;
    const fromCode = values.fromCode;
    const toCode = values.toCode;
    const departureDate = moment(values.departureDate).format("YYYY-MM-DD");
    const returnDate = moment(values.returnDate).format("YYYY-MM-DD");
    const flightType = values.passengers.flightType;
    const searchType = values.searchType;
    return `/flights/search?${ADULTS}=${totalAdult}&${getTotalPassengerLink(
        totalChild,
        totalInfant
    )}from=${from}&fromCode=${fromCode}&to=${to}&toCode=${toCode}&departureDate=${departureDate}&returnDate=${returnDate}&flight=${flightType}&searchtype=${searchType}`;
};

export const getAirportName = (airportCode: string, airlineList: FlightDetailInfoType[]) => {
    if (airlineList && airlineList.length > 0) {
        const airportDetails = airlineList.find((airport) => airport.iata === airportCode);
        return airportDetails?.name || "";
    }
    return "";
};

export const getFlightFilterListSection = (data: any): any =>
    Client.getInstance()
        .createData(getFlightDetailsApiUrl, data)
        .then((res) => Promise.resolve(transformFlightSearch(res)))
        .catch((err) => Promise.reject(err));

export const revalidateFlight = (keys: string[], singleTicket: boolean, forceRefresh = true) =>
    Client.getInstance()
        .createData(
            revalidateFlightApiUrl,
            {
                keys,
                single_ticket: singleTicket
            },
            forceRefresh
        )
        .then((res) => Promise.resolve(transformRevalidateData(res)))
        .catch((err) => Promise.reject(err));

export const getTotalTravelTime = (segments: SegmentsType[]) => {
    if (segments.length === 1) {
        return `${convertTimeToAmPm(segments[0].departureTime)} - ${convertTimeToAmPm(segments[0].arrivalTime)}`;
    }
    return `${convertTimeToAmPm(segments[0].departureTime)} - ${convertTimeToAmPm(
        segments[segments.length - 1].arrivalTime
    )}`;
};

export const getTotalTravelTimeInAmPm = (segments: SegmentsType[]) => {
    if (segments.length === 1) {
        return `${moment(segments[0].departureTime).format("LT")} - ${moment(segments[0].arrivalTime).format("LT")}`;
    }
    return `${moment(segments[0].departureTime).format("LT")} - ${moment(
        segments[segments.length - 1].arrivalTime
    ).format("LT")}`;
};

const filterMap: any = {
    airlines: {
        filterFn: (airlineCodes: any) => {
            if (airlineCodes && airlineCodes.length > 0) {
                return (item: FlightSearchResultsType) => airlineCodes.includes(item.validatingCarrierCode);
            }
            return () => true;
        }
    },
    departureTime: {
        filterFn: (departureTimeIds: string[]) => {
            if (!departureTimeIds || departureTimeIds.length === 0) {
                return () => true;
            }
            //TODO - specify type
            const timeRangeArray: any = departureTimeIds.map(
                (departureTimeId) => TimesOption.find(({ id }) => id === departureTimeId)?.timeRange
            );
            return (item: FlightSearchResultsType) => {
                const firstSegmentDepartureTime = item.itineraries[0].segments[0].departureTime;
                const departureHour = parseInt(firstSegmentDepartureTime.split("T")[1].split(":")[0]);
                return !!timeRangeArray.find(
                    (timeRange: any) => timeRange.startTime <= departureHour && timeRange.endTime > departureHour
                );
            };
        }
    },
    arrivalTime: {
        filterFn: (arrivalTimeIds: string[]) => {
            if (!arrivalTimeIds || arrivalTimeIds.length === 0) {
                return () => true;
            }
            //TODO - specify type
            const timeRangeArray: any = arrivalTimeIds.map(
                (arrivalTimeId) => TimesOption.find(({ id }) => id === arrivalTimeId)?.timeRange
            );
            return (item: FlightSearchResultsType) => {
                const segments = item.itineraries[0].segments;
                const lastSegmentArrivalTime = segments[segments.length - 1].arrivalTime;
                const arrivalHour = parseInt(lastSegmentArrivalTime.split("T")[1].split(":")[0]);
                return !!timeRangeArray.find(
                    (timeRange: any) => timeRange.startTime <= arrivalHour && timeRange.endTime > arrivalHour
                );
            };
        }
    },
    stops: {
        filterFn: (stops: string[]) => {
            if (!stops || stops.length === 0) {
                return () => true;
            }
            if (stops.includes("2")) {
                stops = [...stops, "3", "4", "5", "6", "7", "8", "9", "10"];
            }

            return (item: FlightSearchResultsType) =>
                stops.includes((item.itineraries[0].layovers?.length || 0).toString());
        }
    }
};

export const filterFlightSearchResults = (selectedFilters: any, results: FlightSearchResultsType[]) => {
    if (!results) {
        return [];
    }
    let filteredSearchResults = results;
    Object.keys(selectedFilters).map((selectedFilterKey: any) => {
        if (selectedFilters[selectedFilterKey].length === 0) {
            return true;
        }
        const filterFn = filterMap[selectedFilterKey].filterFn(selectedFilters[selectedFilterKey]);
        filteredSearchResults = filteredSearchResults.filter(filterFn);
    });
    return filteredSearchResults;
};

export const getFlightSearchParams = (): FlightSearchType => {
    const searchParams = new URLSearchParams(window.location.search);
    const searchParamsObj = {
        from: searchParams.get("from") || flightSearchInitialValue.from,
        fromCode: searchParams.get("fromCode") || flightSearchInitialValue.fromCode,
        to: searchParams.get("to") || flightSearchInitialValue.to,
        toCode: searchParams.get("toCode") || flightSearchInitialValue.toCode,
        departureDate: searchParams.get("departureDate")
            ? moment(new Date(searchParams.get("departureDate") || ""))
            : flightSearchInitialValue.departureDate,
        returnDate: searchParams.get("returnDate")
            ? moment(new Date(searchParams.get("returnDate") || ""))
            : flightSearchInitialValue.returnDate,
        passengers: {
            flightType: searchParams.get("flight") || flightSearchInitialValue.passengers.flightType,
            passengerType: getPassengerArray(searchParams) || flightSearchInitialValue.passengers.passengerType
        },
        searchType: searchParams.get("searchtype") || RETURN
    };
    return searchParamsObj;
};
