import { Client } from "../../commoncomponents/Client";
import { getSearchedAirportApiUrl } from "../../constants/ApiConstants";
import { AirportListType } from "./Type";

const transformList = ({ data }: any) => {
    const airportData: AirportListType[] = [];
    data.map((item: any) => {
        if (item.type === "city") {
            airportData.push({
                id: Math.random(),
                country: item.country,
                countryIata: item.country_iata,
                iata: item.iata,
                name: item.name,
                type: item.type,
                airports: []
            });
        }
        if (item.type === "airport") {
            const matchingCity = airportData.find(({ type, iata }: any) => type === "city" && iata === item.city_iata);
            const airportObject: AirportListType = {
                id: Math.random(),
                city: item.city,
                country: item.country,
                cityIata: item.city_iata,
                countryIata: item.country_iata,
                iata: item.iata,
                name: item.name,
                type: item.type
            };
            if (matchingCity && matchingCity.airports) {
                matchingCity.airports.push(airportObject);
                return true;
            }
            airportData.push(airportObject);
        }
    });
    return airportData;
};

export const getSearchedList = (text: string) => {
    return Client.getInstance()
        .getData(getSearchedAirportApiUrl(text))
        .then((res) => Promise.resolve(transformList(res)))
        .catch((err) => Promise.reject(err));
};
