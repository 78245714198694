import { Grid, Button } from "@mui/material";
import React, { FC } from "react";
import TbcCard from "../../../commoncomponents/layout/card/TbcCard";
import BookingPriceCard from "../bookingprice/BookingPriceCard";
import BookingFlightSummary from "../flightsummary/BookingFlightSummary";
import AddBaggageForm from "./AddBaggageForm";

const BaggageSelectionView: FC = () => {
    return (
        <Grid container>
            <Grid item xs={12} md={8} alignItems="stretch" order={{ xs: 2, md: 1 }}>
                <Grid item style={{ margin: 10 }}>
                    <AddBaggageForm />
                </Grid>
                <Grid item style={{ margin: 10 }}>
                    <Grid container justifyContent="center">
                        <Grid item style={{ margin: 10 }}>
                            <Button disableRipple variant={"outlined"} color={"primary"}>
                                Back
                            </Button>{" "}
                            <Button disableRipple variant={"contained"} color={"primary"}>
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={4} alignItems="stretch" order={{ xs: 1, md: 2 }}>
                <Grid item style={{ margin: 10 }}>
                    <BookingPriceCard passengerPrice={154.25} />
                </Grid>
                <Grid item style={{ margin: 10 }}>
                    <TbcCard>
                        <BookingFlightSummary itenariesCount={0} />
                        <br />
                        <hr />
                        <br />
                        <BookingFlightSummary itenariesCount={0} />
                    </TbcCard>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default BaggageSelectionView;
