import { Button, Grid, IconButton } from "@mui/material";
import React, { useState, FC } from "react";
import FilterListIcon from "@mui/icons-material/FilterList";
import CloseIcon from "@mui/icons-material/Close";
import FlightFilters from "../../filters/FlightFilters";
import { FlightSearchFiltersType, SelectedFilterType } from "../Type";
import { whiteColor } from "../../../../constants/ColorsConstants";
import { DrawerWrapper } from "./Style";

type MobileFilterSectionProps = {
    isLoading: boolean;
    filterList?: FlightSearchFiltersType;
    departurePlace: string;
    arrivalPlace: string;
    selectedFilters: SelectedFilterType;
    setSelectedFilters: (selectedFilter: SelectedFilterType) => void;
};

const MobileFilterSection: FC<MobileFilterSectionProps> = ({
    isLoading,
    filterList,
    arrivalPlace,
    departurePlace,
    setSelectedFilters,
    selectedFilters
}) => {
    const [openDrawer, setOpenDrawer] = useState(false);

    return (
        <Grid container justifyContent={"center"} style={{ background: whiteColor }}>
            <Button startIcon={<FilterListIcon />} onClick={() => setOpenDrawer(true)}>
                Filter
            </Button>
            <DrawerWrapper anchor={"right"} open={openDrawer} onClose={() => setOpenDrawer(false)}>
                <Grid container justifyContent={"end"}>
                    <IconButton onClick={() => setOpenDrawer(false)}>
                        <CloseIcon />
                    </IconButton>
                </Grid>
                <FlightFilters
                    isLoading={isLoading}
                    filterList={filterList}
                    arrivalPlace={arrivalPlace}
                    departurePlace={departurePlace}
                    selectedFilters={selectedFilters}
                    setSelectedFilters={(filters: SelectedFilterType) => {
                        setSelectedFilters(filters);
                    }}
                />
            </DrawerWrapper>
        </Grid>
    );
};

export default MobileFilterSection;
