import { Grid, Typography } from "@mui/material";
import React, { FC, useMemo } from "react";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import CircleIcon from "@mui/icons-material/Circle";
import RoomIcon from "@mui/icons-material/Room";
import { disabledColor, iconDisabledColor, iconPrimaryColor } from "../constants/ColorsConstants";
import { convertMinsToHrsMins } from "../util/SiteUtils";

type FlightTrackerProps = {
    totalTime?: number;
    totalStops: number;
    departureTime?: string;
    departureAirport?: string;
    reachTime?: string;
    reachAirport?: string;
};

const FlightTracker: FC<FlightTrackerProps> = ({
    totalTime,
    totalStops,
    departureTime,
    reachTime,
    departureAirport,
    reachAirport
}) => {
    const renderComponentByNumberOfCount = useMemo(() => {
        const renderComponent = [];
        for (let i = 0; i < totalStops; i++) {
            renderComponent.push(
                <FlightTakeoffIcon key={`${Math.random()}`} style={{ color: iconDisabledColor, fontSize: 20 }} />
            );
        }
        return renderComponent;
    }, [totalStops]);

    return (
        <Grid>
            <Grid item xs={12} style={{ paddingLeft: 30, paddingRight: 30, position: "relative", top: 20 }}>
                <Grid container justifyContent={"center"}>
                    {renderComponentByNumberOfCount}
                </Grid>
            </Grid>
            <Grid container alignItems={"center"} justifyContent={"space-between"}>
                <Grid item xs={2}>
                    {departureTime ? (
                        <>
                            <Typography variant={"body1"} style={{ fontWeight: 600 }}>
                                {departureTime}
                            </Typography>
                            <Typography variant={"caption"} style={{ color: disabledColor }}>
                                {departureAirport}
                            </Typography>
                        </>
                    ) : (
                        <CircleIcon style={{ color: iconPrimaryColor, fontSize: 15 }} />
                    )}
                </Grid>
                <Grid item xs={7} style={{ borderBottom: `1px ${disabledColor} dashed` }} />
                <Grid item xs={2}>
                    {reachTime ? (
                        <>
                            <Typography variant={"body1"} style={{ fontWeight: 600 }}>
                                {reachTime}
                            </Typography>
                            <Typography variant={"caption"} style={{ color: disabledColor }}>
                                {reachAirport}
                            </Typography>
                        </>
                    ) : (
                        <RoomIcon style={{ color: iconPrimaryColor, fontSize: 20 }} />
                    )}
                </Grid>
            </Grid>
            {totalTime && (
                <Grid item xs={12} style={{ paddingLeft: 30, paddingRight: 30 }}>
                    <Typography>
                        {convertMinsToHrsMins(totalTime)} - {totalStops === 0 ? "Non Stop" : `${totalStops} Stops`}
                    </Typography>
                </Grid>
            )}
        </Grid>
    );
};

export default FlightTracker;
