import { Link, Grid, Typography, List, ListItemText } from "@mui/material";
import React, { FC } from "react";
import TbcCard from "../../../../commoncomponents/layout/card/TbcCard";
import PropertyDetailItem from "../PropertyDetailItem";
import { HeadingTypography } from "../Styled";
import Map from "../overview/Map";
import { area, areaInformation, AboutProperty, CleaningSafetyPractices } from "./LocationData";

const LocationGrid: FC = () => (
    <TbcCard>
        <Grid container spacing={4}>
            <Grid item container xs={12}>
                <Grid item xs={12} md={6}>
                    <HeadingTypography variant="h4" gutterBottom>
                        About this area
                    </HeadingTypography>
                    <HeadingTypography sx={{ fontWeight: "bold" }} variant="h6" gutterBottom>
                        {area.title}
                    </HeadingTypography>
                    <Typography variant="body2" gutterBottom>
                        {area.text}
                    </Typography>
                    <Link sx={{ display: "block", my: 3 }} href={area.locationUrl}>
                        {area.locationUrlName}
                    </Link>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Map mapSrc={area.mapSrc} mapWidth="100%" height="317" />
                </Grid>
            </Grid>

            <Grid item container xs={12}>
                {areaInformation.map((info) => (
                    <Grid key={info.id} item xs={12} md={4}>
                        <PropertyDetailItem primaryText={info.title}>{info.icon}</PropertyDetailItem>
                        <List sx={{ mb: 3 }}>
                            {info.details.map((detail) => (
                                <ListItemText key={detail} sx={{ ml: 5 }}>
                                    {detail}
                                </ListItemText>
                            ))}
                        </List>
                    </Grid>
                ))}
            </Grid>

            <Grid item container xs={12}>
                <Grid item xs={12}>
                    <Typography variant="h5" gutterBottom>
                        About this property
                    </Typography>
                </Grid>
                <Grid item xs={12} sx={{ ml: { md: 5 } }}>
                    <HeadingTypography variant="h6" gutterBottom>
                        {AboutProperty.title}
                    </HeadingTypography>
                    <Typography variant="body2" gutterBottom>
                        {AboutProperty.text}
                    </Typography>
                </Grid>
            </Grid>

            <Grid item container xs={12}>
                <Grid item xs={12}>
                    <Typography variant="h5" gutterBottom>
                        Cleaning and safety practices
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    {CleaningSafetyPractices.map((info) => (
                        <Grid key={info.id} item xs={12}>
                            <PropertyDetailItem primaryText={info.title}>{info.icon}</PropertyDetailItem>
                            <List sx={{ mb: 3 }}>
                                {info.details.map((detail) => (
                                    <ListItemText key={detail} sx={{ ml: 5 }}>
                                        {detail}
                                    </ListItemText>
                                ))}
                            </List>
                        </Grid>
                    ))}

                    <Typography variant="subtitle1" gutterBottom>
                        This information is provided by our partners.
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    </TbcCard>
);

export default LocationGrid;
