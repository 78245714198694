import Styled from "@emotion/styled";
import { Grid } from "@mui/material";
import { disabledColor } from "../../constants/ColorsConstants";

export const IconButtons = Styled(Grid)`
    padding: 10px;
    cursor: pointer;
    border: 1px solid ${disabledColor};
    border-radius: 5px;
    margin: 10px;
    display: flex;
`;
