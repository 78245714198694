import styled from "@emotion/styled";
import { AppBar, Tab, Toolbar, Typography } from "@mui/material";
import { menuLinkBgColor, menuLinkColor, whiteColor, menuLinkColorInactive } from "../../constants/ColorsConstants";

export type ThemeProps = {
    margintop?: string;
};

export const CustomAppBar = styled(AppBar)<ThemeProps>`
    background-color: ${whiteColor};
    box-shadow: none;
    border-bottom: 1px #a7a4a4 solid;
    background-image: none;
    margin-top: ${(props) => props.margintop};
`;

export const CustomToolbar = styled(Toolbar)`
    padding: 0 20px;
`;

type MenuLinksProps = {
    selected?: boolean;
};

export const MenuLinks = styled(Typography)<MenuLinksProps>`
    color: ${(props) => (props.selected ? menuLinkColor : menuLinkColorInactive)};
    padding: 5px 8px;
    background-color: ${(props) => (props.selected ? menuLinkBgColor : "transparent")};
    border-radius: 6px;
    font-weight: bold;
    font-size: 13px;
    display: flex;
    align-items: center;
    gap: 5px;
    flex-grow: 1;
    cursor: pointer;
    :hover {
        color: ${menuLinkColor};
    }
`;

export const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

export const HeaderMenuContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 15px;

    @media (min-width: 800px) {
        width: 100%;
    }
`;

export const MobileMenuContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 6px;
`;

export const LogoImage = styled.img`
    height: 60px;
    border-radius: 5px;
    cursor: pointer;
`;

export const MenuButton = styled(Tab)`
    font-size: 13px;
    font-weight: bold;
    text-transform: none;
    color: ${whiteColor};
`;

export const CryptoIconsWrapper = styled.div`
    font-size: 13px;
    font-weight: bold;
    text-transform: none;
    color: ${whiteColor};
`;


export const CryptoIcon = styled.img`
    height: 30px;
    margin-left: 5px;
`;





