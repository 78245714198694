export const RETURN = "return";
export const ONE_WAY = "one_way";
export const ADULTS = "adults";
export const CHILDREN = "children";
export const INFANTS = "infants";
export const ADULT_TYPE = "ADT";
export const CHILD_TYPE = "CHD";
export const INFANT_TYPE = "INF";
export const debounceTime = 600;
export const getDomainImage = (domain: string) =>
    `https://s2.googleusercontent.com/s2/favicons?domain=${domain}&sz=256`;

export const initalShowMoreSize = 8;
