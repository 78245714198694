import { Typography, FormGroup, FormControlLabel, Checkbox, Collapse, Button } from "@mui/material";
import { Box } from "@mui/system";
import React, { FC } from "react";
import { useBooleanState } from "../../../commoncomponents/CommonHooks";
import { initalShowMoreSize } from "../../../constants/SiteConstants";

type CheckBoxListProps = {
    heading: string;
    data: string[];
};
const CheckBoxList: FC<CheckBoxListProps> = ({ heading, data }) => {
    const showAllFilters = useBooleanState(false);
    const handleClick = () => {
        showAllFilters.toggle();
    };

    return (
        <Box my={2}>
            <Typography>{heading}</Typography>
            <FormGroup>
                {data.map(
                    (filter, index) =>
                        index <= 7 && (
                            <FormControlLabel
                                value={filter.toLowerCase().replace(/ /g, "-")}
                                key={filter}
                                control={<Checkbox />}
                                label={filter}
                            />
                        )
                )}
            </FormGroup>
            {data.length > initalShowMoreSize && (
                <>
                    <Collapse in={showAllFilters.value} timeout="auto" unmountOnExit>
                        <FormGroup>
                            {data.map(
                                (filter, index) =>
                                    index >= initalShowMoreSize && (
                                        <FormControlLabel
                                            value={filter.toLowerCase().replace(/ /g, "-")}
                                            key={filter}
                                            control={<Checkbox />}
                                            label={filter}
                                        />
                                    )
                            )}
                        </FormGroup>
                    </Collapse>
                    <Button variant="text" onClick={handleClick}>
                        {showAllFilters.value ? "See less" : "See more"}
                    </Button>
                </>
            )}
        </Box>
    );
};

export default CheckBoxList;
