import { Divider, Grid } from "@mui/material";
import React, { FC, useState, useMemo } from "react";
import { FlightSummaryPrimaryText, FlightSummarySecondaryText, FlightSummaryTertiayText } from "./Styled";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import FlightTracker from "../../../commoncomponents/FlightTracker";
import BookingFlightAllDescription from "./BookingFlightAllDescription";
import { AllPassengerInfoType } from "../passengerinfo/Type";
import { convertMinsToHrsMins, get24HourTime, getFlightLogo } from "../../../util/SiteUtils";
import moment from "moment";

type BookingFlightSummaryProps = {
    flightDetails?: AllPassengerInfoType;
    title?: string;
    itenariesCount: number;
};
const BookingFlightSummary: FC<BookingFlightSummaryProps> = ({ flightDetails, title, itenariesCount }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    if (!flightDetails) {
        return <></>;
    }

    const stops = flightDetails.itineraries[itenariesCount].layovers?.length || 0;
    const renderFlightDescription = useMemo(() => {
        if (isExpanded) {
            return <BookingFlightAllDescription itenariesCount={itenariesCount} flightDetails={flightDetails} />;
        }
        return (
            <Grid container justifyContent={"space-between"} alignItems={"end"}>
                <Grid item xs={1}>
                    <img
                        src={getFlightLogo(flightDetails?.family.validatingCarrier || "")}
                        alt="flight-icon"
                        height="30"
                        width="30"
                    />
                </Grid>
                <Grid item xs={8}>
                    <FlightTracker
                        totalStops={stops}
                        departureAirport={flightDetails?.itineraries[itenariesCount].segments[0].origin}
                        departureTime={get24HourTime(
                            flightDetails?.itineraries[itenariesCount].segments[0].departureTime || ""
                        )}
                        reachAirport={
                            flightDetails?.itineraries[itenariesCount].segments[
                                flightDetails?.itineraries[itenariesCount].segments.length - 1
                            ].destination
                        }
                        reachTime={get24HourTime(
                            flightDetails?.itineraries[itenariesCount].segments[
                                flightDetails?.itineraries[itenariesCount].segments.length - 1
                            ].arrivalTime || ""
                        )}
                    />
                </Grid>
                <Grid item xs={2}>
                    <FlightSummaryTertiayText>
                        {convertMinsToHrsMins(flightDetails?.itineraries[itenariesCount].duration || 0)}
                    </FlightSummaryTertiayText>
                    <br />
                    <FlightSummaryTertiayText style={{ fontSize: 12 }}>
                        {stops === 0 ? "Non Stop" : `${stops} Stop`}
                    </FlightSummaryTertiayText>
                </Grid>
            </Grid>
        );
    }, [isExpanded]);

    const renderHeaderSection = useMemo(() => {
        if (!isExpanded) {
            return (
                <Grid
                    onClick={() => setIsExpanded((prev) => !prev)}
                    style={{ cursor: "pointer" }}
                    container
                    justifyContent={"space-between"}
                    alignItems={"center"}
                >
                    <Grid item>
                        <FlightSummaryPrimaryText>
                            {title} -{" "}
                            {moment(flightDetails?.itineraries[itenariesCount].segments[0].departureTime).format(
                                "MMM Do YYYY"
                            )}
                        </FlightSummaryPrimaryText>
                    </Grid>
                    <Grid item>
                        <KeyboardArrowDownIcon />
                    </Grid>
                </Grid>
            );
        }
        return (
            <Grid
                onClick={() => setIsExpanded((prev) => !prev)}
                style={{ cursor: "pointer" }}
                container
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Grid item>
                    <FlightSummaryPrimaryText>{title}</FlightSummaryPrimaryText>
                </Grid>
                <Grid item>
                    <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                        <FlightSummarySecondaryText>
                            {convertMinsToHrsMins(flightDetails?.itineraries[itenariesCount].duration || 0)}
                        </FlightSummarySecondaryText>
                        <KeyboardArrowUpIcon />
                    </div>
                </Grid>
            </Grid>
        );
    }, [isExpanded]);

    return (
        <Grid>
            {renderHeaderSection}
            <Divider style={{ margin: "10px 0px" }} />
            {renderFlightDescription}
        </Grid>
    );
};

export default BookingFlightSummary;
