import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import React, { FC } from "react";

type ErrorDialogProps = {
    handleClose: () => void;
    errorDetails: string[] | string;
    closeButtonText?: string;
};

const ErrorDialog: FC<ErrorDialogProps> = ({ handleClose, errorDetails, closeButtonText }) => {
    return (
        <Dialog open={true} onClose={handleClose}>
            <DialogTitle style={{ justifyContent: "center" }}>Error</DialogTitle>
            <DialogContent>
                {Array.isArray(errorDetails) ? (
                    errorDetails.map((error, index) => (
                        <>
                            <Typography key={index}>{error}</Typography>
                            <br />
                        </>
                    ))
                ) : (
                    <Typography>{errorDetails}</Typography>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>{closeButtonText ? closeButtonText : "Close"}</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ErrorDialog;
