import styled from "@emotion/styled";
// import { AppBar, Tab, Toolbar, Typography } from "@mui/material";
import { footerBgColor } from "../../constants/ColorsConstants";

export const FooterContainer = styled.div`
    background-color: ${footerBgColor};
    padding: 40px 20px;
    color: #fff;
    text-align: center;
`;

export const FooterLink = styled.span`
    cursor: pointer;
    margin-right: 20px;

    @media (max-width: 800px) {
        display: block;
        margin-bottom: 10px;
    }
`;


