import Styled from "@emotion/styled";
import { List, Paper } from "@mui/material";

type SearchedResultContainerProps = {
    topheight: number;
};
export const SearchedResultContainer = Styled(Paper)<SearchedResultContainerProps>`
    position: absolute;
    top: ${(props) => 40 + props.topheight}px;
    width: 300px;
    z-index: 9999;
    padding: 10px;
    max-height: 500px;
    overflow: auto;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    @media (max-width: 900px) {
        width: 250px;
    }
`;

type OptionsListContainerProps = {
    variant: "primary" | "sub";
};
export const OptionsListContainer = Styled(List)<OptionsListContainerProps>`
    padding: 0px;
    margin-left: ${(props) => (props.variant === "primary" ? 0 : 20)}px;
`;
