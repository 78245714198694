import Styled from "@emotion/styled";

export const NumberHeading = Styled.li`
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: 700;
`;

export const SectionText = Styled.ol`
    font-size: 16px;
    margin-bottom: 15px;
    font-weight: normal;
`;

export const PointText = Styled.li`
    margin-bottom: 10px;
`;
