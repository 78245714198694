import {
    Alert,
    AlertTitle,
    Grid,
    Link,
    Icon,
    Stack,
    Typography,
    TextField,
    FormControl,
    Select,
    MenuItem,
    FormGroup,
    Checkbox,
    FormControlLabel,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Divider,
    Button
} from "@mui/material";
import React, { FC } from "react";
import TbcCard from "../../../commoncomponents/layout/card/TbcCard";
import {
    covid19Notice,
    cancellation,
    room,
    countryCallingCodes,
    hotelInfo,
    PriceDetails,
    ImportantInfo,
    protectInfo,
    agreements
} from "./ReservationData";
import { HeadingTypography } from "../../../commoncomponents/CommonStyle";
import { Box } from "@mui/system";
import { Person, Check, ArrowForwardIos, ExpandMore, Lock } from "@mui/icons-material";
import { IconWrapper, FixedImg, MoneySavedButton, SubmitButton } from "./Styled";
import { darkGreen } from "../../../constants/ColorsConstants";

const ReservationView: FC = () => (
    <TbcCard>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <HeadingTypography variant="h5" sx={{ fontWeight: "bold" }}>
                    Secure booking — only takes 2 minutes!
                </HeadingTypography>
                {covid19Notice.state && (
                    <Alert severity="warning" sx={{ width: "90%", my: 2 }}>
                        <AlertTitle>{covid19Notice.title}</AlertTitle>
                        <Link href={covid19Notice.url.src} color="inherit">
                            {covid19Notice.url.title}
                        </Link>
                    </Alert>
                )}
                <Stack direction="row">
                    <Icon sx={{ height: "100%", width: "48px" }}>
                        <img src={cancellation.img} alt="" />
                    </Icon>
                    <Box ml={2}>
                        <HeadingTypography sx={{ fontWeight: "bold" }} variant="subtitle2">
                            {cancellation.title}
                        </HeadingTypography>
                        <HeadingTypography variant="subtitle2">{cancellation.text}</HeadingTypography>
                    </Box>
                </Stack>
            </Grid>
            <Grid container item xs={12} spacing={5} sx={{ flexDirection: { xs: "column-reverse", md: "row" }, my: 2 }}>
                <Grid container item md={8}>
                    <Grid item xs={12}>
                        <Stack sx={{ flexDirection: { xs: "column", sm: "row" } }}>
                            <Person sx={{ mr: 2 }} />
                            <HeadingTypography sx={{ fontWeight: "bold" }}>{`${room.title}: `}</HeadingTypography>
                            {room.features.map((feature) => (
                                <Typography ml={1} key={feature}>
                                    {feature}
                                </Typography>
                            ))}
                        </Stack>

                        <Stack direction="row" spacing={2} sx={{ ml: { xs: 0, md: 5 } }}>
                            {room.includes.map((element) => (
                                <IconWrapper key={element} color={darkGreen}>
                                    <Check fontSize="small" />
                                    <span>{element}</span>
                                </IconWrapper>
                            ))}
                        </Stack>
                        <Box my={3} sx={{ width: "400px", maxWidth: "100%" }}>
                            <Typography sx={{ fontWeight: "bold" }} variant="subtitle2">
                                Contact name *
                            </Typography>

                            <TextField
                                fullWidth
                                required
                                variant="outlined"
                                placeholder="First name and surname"
                                size="small"
                                margin="dense"
                            />
                            <Typography sx={{ fontWeight: "bold" }} variant="subtitle2">
                                Mobile phone number *
                            </Typography>
                            <FormControl fullWidth size="small" sx={{ flexDirection: "row", alignItems: "center" }}>
                                <Select
                                    size="small"
                                    value={"+55"}
                                    displayEmpty
                                    inputProps={{ "aria-label": "Without label" }}
                                    sx={{ maxHeight: "40px", width: "35%" }}>
                                    {countryCallingCodes.map((item) => (
                                        <MenuItem
                                            key={item.name}
                                            value={item.code}>{`${item.name} ${item.code}`}</MenuItem>
                                    ))}
                                </Select>
                                <TextField
                                    required
                                    variant="outlined"
                                    placeholder="So the property can reach you"
                                    size="small"
                                    margin="dense"
                                    sx={{ maxHeight: "40px", width: "65%" }}
                                />
                            </FormControl>
                            <FormGroup>
                                <FormControlLabel
                                    control={<Checkbox defaultChecked={room.textAlerts.default} />}
                                    label={room.textAlerts.text}
                                    sx={{ "& .MuiFormControlLabel-label": { fontSize: "0.9rem" } }}
                                />
                            </FormGroup>

                            <Accordion sx={{ my: 2 }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header">
                                    <Typography variant="subtitle2" color="primary">
                                        {room.specialRequests.title}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography variant="subtitle2">{room.specialRequests.text}</Typography>
                                </AccordionDetails>
                            </Accordion>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <HeadingTypography my={2} variant="h6" sx={{ fontWeight: "bold" }}>
                            Manage your booking
                        </HeadingTypography>
                        <Divider />
                        <Box my={2} sx={{ width: { xs: "100%", sm: "400px" } }}>
                            <Typography my={2} variant="h6">
                                Confirmation email
                            </Typography>
                            <Typography my={2} variant="subtitle2">
                                Please enter the email address where you would like to receive your confirmation.
                            </Typography>
                            <TextField size="small" fullWidth label="Email address" variant="outlined" required />

                            <FormGroup>
                                <FormControlLabel
                                    control={<Checkbox defaultChecked />}
                                    label="Please send me emails with travel deals, special offers and other information."
                                    sx={{ "& .MuiFormControlLabel-label": { fontSize: "0.9rem" } }}
                                />
                            </FormGroup>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <HeadingTypography my={2} variant="h6" sx={{ fontWeight: "bold" }}>
                            {ImportantInfo.title}
                        </HeadingTypography>
                        <ul>
                            {ImportantInfo.informations.map((info, index) =>
                                Array.isArray(info) ? (
                                    <ul key={index}>
                                        {info.map((ele) => (
                                            <li key={ele}>{ele}</li>
                                        ))}
                                    </ul>
                                ) : (
                                    <li key={info}>{info}</li>
                                )
                            )}
                        </ul>
                        <Typography variant="body2">
                            {agreements.text}
                            <a href="#">{agreements.links.privacyStatement.title}</a> and{" "}
                            <a href="#">{agreements.links.governmentTravelAdvice.title}</a> and have reviewed and accept
                            the <a href="#">{agreements.links.rulesAndRestrictions.title}</a> and{" "}
                            <a href="#">{agreements.links.termsOfUse.title}</a>
                        </Typography>
                        <IconWrapper my={2} color={darkGreen}>
                            <Check fontSize="small" sx={{ mr: 2 }} />
                            <span>
                                Change of plans? No problem. You can <strong>cancel for free</strong> before{" "}
                                {cancellation.date}.
                            </span>
                        </IconWrapper>
                        <SubmitButton variant="contained" endIcon={<ArrowForwardIos />}>
                            Complete booking
                        </SubmitButton>
                        <Box my={2}>
                            <IconWrapper mb={1}>
                                <Lock fontSize="small" />
                                <Typography ml={1}>{protectInfo.text}</Typography>
                            </IconWrapper>
                            <Typography sx={{ ml: { xs: 0, md: 3 } }}>{protectInfo.details}</Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container item md={4}>
                    <Box>
                        <FixedImg src={hotelInfo.img} alt="hotel-img" />
                        <Typography>
                            <strong>{`${hotelInfo.rating.ratingValue}/5`}</strong>
                            {` ${hotelInfo.rating.description} (${hotelInfo.rating.reviewCount} reviews)`}
                        </Typography>
                        <Typography>
                            Guests rated this property {hotelInfo.rating.cleanliness}/5 for cleanliness
                        </Typography>
                        <Stack sx={{ flexDirection: { xs: "column", sm: "row" }, my: 2 }}>
                            <Typography sx={{ fontWeight: "bold" }}>{`${hotelInfo.room.title}: `}</Typography>
                            {hotelInfo.room.details.map((detail) => (
                                <Typography ml={1} key={detail}>
                                    {detail}
                                </Typography>
                            ))}
                        </Stack>

                        {hotelInfo.info.map((info) => (
                            <Typography key={info.title}>
                                <strong>{info.title}</strong>
                                {info.date}
                            </Typography>
                        ))}
                        <Typography>{hotelInfo.stays}</Typography>
                    </Box>
                    <Box mt={15} sx={{ width: "100%" }}>
                        <HeadingTypography variant="h6" sx={{ fontWeight: "bold" }}>
                            {PriceDetails.title}
                        </HeadingTypography>
                        {PriceDetails.cost.map((ele, index) => (
                            <Box key={ele.primaryText}>
                                <Stack direction="row" justifyContent="space-between" sx={{ mt: 2 }}>
                                    {ele.primaryText.startsWith("Taxes") ? (
                                        <>
                                            <Button variant="text" endIcon={ele.icon} sx={{ p: 0, color: "inherit" }}>
                                                {ele.primaryText}
                                            </Button>
                                            <Typography>{ele.price}</Typography>
                                        </>
                                    ) : (
                                        <>
                                            <Typography>{ele.primaryText}</Typography>
                                            <Typography>{ele.price}</Typography>
                                        </>
                                    )}
                                </Stack>
                                {ele.secondaryText && <Typography variant="body2">{ele.secondaryText}</Typography>}
                                {index === 0 && (
                                    <MoneySavedButton variant="text" endIcon={PriceDetails.saved.icon}>
                                        You Saved {PriceDetails.saved.text}
                                    </MoneySavedButton>
                                )}
                            </Box>
                        ))}
                        <Divider sx={{ my: 2 }} />
                        {PriceDetails.payInfo.map((ele, index) => (
                            <Stack key={ele.title} direction="row" justifyContent="space-between" sx={{ mt: 2 }}>
                                <Typography sx={index === 0 ? { fontWeight: "bold" } : {}}>{ele.title}</Typography>
                                <Typography sx={index === 0 ? { fontWeight: "bold" } : {}}>{ele.value}</Typography>
                            </Stack>
                        ))}
                        <Button variant="text" sx={{ fontSize: "13px", px: 0 }}>
                            {PriceDetails.code.title}
                        </Button>
                        <Typography mt={3} variant="body2">
                            {PriceDetails.note}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    </TbcCard>
);

export default ReservationView;
