import { PropertySearchItem } from "./Types";

export const propertySearchData: PropertySearchItem[] = [
    {
        id: 1,
        name: "AscottRaffles Place Singapore (SG Clean)",
        distanceInfo: "17.28 km from Changi (SIN)",
        images: ["/images/search/1.webp", "/images/search/2.webp", "/images/search/3.webp", "/images/search/4.webp"],

        description: [
            "Enjoy exclusive offer for Members",
            "SG Clean certified Free Wifi 5-min walk to Raffles Place",
            "MRT station. Near Marina Bay, an ideal local to explore singapore"
        ],
        rating: { value: "4.4", description: "Excellent", reviewsCount: "299" },
        cost: { value: "AU$588", details: "for 1 night", includes: "includes taxes & fees" },
        fullyRefundable: false,
        MemberPrice: false
    },
    {
        id: 2,
        name: "More information about Aerotel Singapore (Transit Hotel at Terminal 1), Opens in a new window",
        distanceInfo: "15 km from Changi (SIN)",
        images: ["/images/search/3.webp", "/images/search/4.webp", "/images/search/1.webp"],
        description: [
            "Enjoy exclusive offer for Members",
            "SG Clean certified Free Wifi 5-min walk to Raffles Place",
            "MRT station. Near Marina Bay, an ideal local to explore singapore"
        ],
        rating: { value: "4.4", description: "Excellent", reviewsCount: "299" },
        cost: { value: "AU$588", details: "for 1 night", includes: "includes taxes & fees" },
        fullyRefundable: true,
        MemberPrice: true
    }
];
