import Styled from "@emotion/styled";
import { Select, Typography } from "@mui/material";
import HomePageImage from "../../images/homeBackgroundImage.png";

export const HomePageFlightSearchContainer = Styled.div``;

export const HomePageFlightSearchHero = Styled.div`
    background-image: url(${HomePageImage});
    background-size: cover;
    background-position: center top;
    padding: 120px 0 130px;

    @media (max-width: 800px) {
        padding: 50px 0 60px;
    }
`;

export const HomePageTitle = Styled(Typography)`
    font-style: normal;
    font-weight: bold;
    font-size: 60px;
    line-height: 120%;
    letter-spacing: -0.5px;
    color: #FFFFFF;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 0px;
    text-align: center;
`;

export const HomePageSubText = Styled(Typography)`
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 175%;
    text-align: center;
    letter-spacing: 0.15px;
    color: #FFFFFF;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 10px 0px 30px;
`;

export const SimpleTextDropdown = Styled(Select)`
    color: #1A237E;
    svg {
        color: #1A237E;
    }
`;


export const FeaturesContainer = Styled.div`
    text-align: center;
    background-color: #fff;
    padding: 40px 20px;
`;

export const FeaturesTitle = Styled.h2`

`;

export const FeaturesDescription = Styled.p`

`;


export const ExploreContainer = Styled.div`
    text-align: center;
    background-color: #fff;
    padding: 40px 20px;
`;

export const ExploreTitle = Styled.h2`
    font-size: 36px;
`;


export const ExplainerContainer = Styled.div`
    // text-align: center;
    background-color: #fff;
    padding: 40px 40px;
    overflow: hidden;

    @media (max-width: 800px) {
        text-align: center;

        li {
            text-align: left;
        }

        img {
            width:150%;
            margin-left: -10%;
        }
    }
`;

export const ExplainerTitle = Styled.h2`
    font-size: 36px;
`;

export const ExplainerParagraph = Styled.p`
    font-size: 20px;
`;

export const ExplainerListItem = Styled.li`
    font-size: 20px;
    margin-bottom: 10px;
`;


