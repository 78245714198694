import React, { FC } from "react";
import { Grid, Button, Rating, Typography } from "@mui/material";
import { HeadingTypography, OverviewCard } from "./../Styled";
import PropertyDetailItem from "../PropertyDetailItem";

import {
    hotelTitle,
    hotelRatingSummary,
    hotelMapDetails,
    amenities,
    cleaningPracices,
    exploreAreaPoints
} from "../PropertyDetailsData";
import Map from "./Map";
import { KeyboardArrowRight } from "@mui/icons-material";

const PropertyDetailOverview: FC = () => {
    return (
        <OverviewCard>
            <Grid container>
                <Grid item md={8}>
                    <HeadingTypography variant="h6" gutterBottom>
                        {hotelTitle}
                    </HeadingTypography>
                    <Rating name="read-only" value={hotelRatingSummary.ratingValue} readOnly />
                    <HeadingTypography sx={{ mt: 2 }} variant="h6" gutterBottom>
                        {`${hotelRatingSummary.ratingValue}/5 ${hotelRatingSummary.description}`}
                    </HeadingTypography>
                    <Typography variant="subtitle1" component="div" gutterBottom>
                        Guests rated this property {`${hotelRatingSummary.cleanliness}/5`} for cleanliness.
                    </Typography>
                    <Button endIcon={<KeyboardArrowRight />}>{`${hotelRatingSummary.reviewCount}`} reviews </Button>
                    <Typography sx={{ mt: 2 }} variant="h6" component="h3" gutterBottom>
                        Popular amenities
                    </Typography>
                    <Grid container>
                        {amenities.map((amenity) => (
                            <Grid item xs={6} key={amenity.text}>
                                <PropertyDetailItem primaryText={amenity.text}>{amenity.icon}</PropertyDetailItem>
                            </Grid>
                        ))}
                    </Grid>
                    <Button endIcon={<KeyboardArrowRight />}>See all </Button>
                    <Typography sx={{ mt: 2 }} variant="h6" component="h3" gutterBottom>
                        Cleaning and safety practices
                    </Typography>
                    <Grid container>
                        {cleaningPracices.map((cleaningPractice) => (
                            <Grid item xs={6} key={cleaningPractice.text}>
                                <PropertyDetailItem primaryText={cleaningPractice.text}>
                                    {cleaningPractice.icon}
                                </PropertyDetailItem>
                            </Grid>
                        ))}
                    </Grid>
                    <Button endIcon={<KeyboardArrowRight />}>See all </Button>
                </Grid>

                <Grid item md={4}>
                    <Map mapSrc={hotelMapDetails.src} mapWidth="380" height="165" location={hotelMapDetails.location} />
                    <HeadingTypography variant="h6" gutterBottom>
                        Explore the area
                    </HeadingTypography>
                    {exploreAreaPoints.map((exploreAreaPoint) => (
                        <PropertyDetailItem
                            key={exploreAreaPoint.text}
                            primaryText={exploreAreaPoint.text}
                            secondaryText={exploreAreaPoint.subText}
                        >
                            {exploreAreaPoint.icon}
                        </PropertyDetailItem>
                    ))}
                </Grid>
            </Grid>
        </OverviewCard>
    );
};

export default PropertyDetailOverview;
